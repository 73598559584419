.landing-page{
  .landing-page__header{
    margin-bottom: 0;

    h1.landing-page__headline{
      margin-bottom: 40px;

      @media (max-width: 992px) {
        margin-bottom: 20px;
        font-size: rem(44px);
        line-height: rem(48px);
      }
    }

    p.landing-page__ingress{
      margin-bottom: 40px;
      font-size: rem(28px);
      line-height: rem(36px);
      letter-spacing: 0.5px;
      opacity: 0;

      @media (max-width: 992px) {
        margin-bottom: 20px;
        font-size: rem(18px);
        line-height: rem(28px);
      }
    }
  }

  &--media{
    .landing-page__header{
      position: relative;
      display: flex;
      align-items: center;
      margin-top: -187px;
      margin-bottom: 50px;

      @media (max-width: 992px) {
        margin-top: -132px;
        margin-bottom: 40px;
      }

      figure{
        position: relative;
        width: 100%;
        height: 757px;
        animation: filled-fade-in 1.5s;

        @media (max-width: 992px) {
          height: 404px;
        }

        &:after{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: black;
          opacity: 0.3;
        }

        img , video{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .headline-wrapper{
        position: absolute;
        width: 100%;

        .landing-page__headline{
          color: white;
          margin-bottom: 0;
        }
      }

      .arrow-down{
        position: absolute;
        width: 52.5px;
        height: 39px;
        bottom: 32px;
        left: calc(50% - 26.25px);
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTMiIGhlaWdodD0iMzkiIHZpZXdCb3g9IjAgMCA1MyAzOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuMjAxOTZlLTA3IC00LjkzNzM4ZS0wNUwyLjE4ODgyZS0wNyAxMi4zNEwyNi4yMzMyIDM5TDUyLjQ4NTQgMTIuMzRMNTIuNDg1NCAtNC45MjU4NGUtMDVMMjYuMjMzMiAyNi42NTk5TDMuMjAxOTZlLTA3IC00LjkzNzM4ZS0wNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;

        @media (max-width: 992px) {
          width: 26.5px;
          height: 19.7px;
          bottom: 29px;
          left: calc(50% - 13.25px);
        }
      }
    }
  }

  .landing-page__category{
    margin-bottom: 150px;

    @media (max-width: 992px) {
      margin-bottom: 40px;
    }

    .category-list{
      display: flex;
      justify-content: center;

      @media (max-width: 992px) {
        display: none;
      }

      li{
        margin-right: 20px;

        &:last-child{
          margin-right: 0;
        }

        button{
          background-color: transparent;
          border: 1.5px solid $GK-Green;
          color: $GK-Dark;

          &:hover{
            color: $GK-Green;
          }
        }
      }
    }

    .dropdown__wrapper{
      display: none;

      @media (max-width: 992px) {
        display: inline-block;
      }
    }
  }
}
