// CORE ELEMENTS
body {

  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
	}

  .page{
		overflow: hidden;
		min-height: 100vh;
		display: flex;
		flex-direction: column;

		&__content{
			flex-grow: 1;
		}

  }

}

img{
	max-width: 100%;
}

.hide-mobile{
	@media (max-width: 991px) {
		display: none;
	}
}

.hide-desktop{
	@media (min-width: 992px) {
		display: none;
	}
}

.separator{
	@media (max-width: 992px) {
		.container{
			padding: 20px !important;
		}
	}
	.line{
		width: 100%;
    height: 1.5px;
    //margin-bottom: 80px;
		background-color: #99CAB8;


	}

	&--mb-80{
		.line{
			margin-bottom: 80px;

			@media (max-width: 992px) {
				margin-bottom: 40px;
			}
		}
	}

	&--mb-100{
		.line{
			margin-bottom: 100px;

			@media (max-width: 992px) {
				margin-bottom: 40px;
			}
		}
	}

	&--mb-150{
		.line{
			margin-bottom: 150px;

			@media (max-width: 992px) {
				margin-bottom: 60px;
			}
		}
	}
}


.page404{
	h1{
		font-size: 144px;
		margin: 0 auto 64px auto;
		letter-spacing: 0.5px;
		line-height: 164px;
		color: $GK-Green;
	}
	h5{
		color: $GK-Dark;
		line-height: 40px;
		margin-bottom: 48px;
	}
}

.header-page{
	margin: 50px 0 40px 0;
	@media (max-width: 992px) {
		margin-bottom: 20px;
	}
	&--title{
		margin-bottom: 40px;
		@media (max-width: 992px) {
			font-size: rem(44px);
			line-height: rem(48px);
			margin-bottom: 20px;
		}
	}
	&--ingress{
		opacity: 0;
		p{
			font-family: 'freightsans_probook';
			font-size: rem(28px);
			line-height: rem(36px);
			letter-spacing: 0.5px;
			color: #202020;
			@media (max-width: 992px) {
				font-size: rem(18px);
				line-height: rem(28px);
			}
		}
	}
}

.filter{
	display: flex;
	margin-bottom: 100px;
	@media(max-width: 992px){
		display: none;
	}
	span{
		font-size: rem(21px);
		line-height: rem(32px);
		color: $GK-Dark;
		font-family: 'freightsans_prosemibold';
		margin-right: 25px;
	}
	a{
		color: $GK-Dark;
		font-size: rem(21px);
		line-height: rem(32px);
		margin-right: 40px;
		display: inline-block;
		&:hover{
			text-decoration: none;
		}
		&.active{
			border-bottom: 1.5px solid $GK-Green;
			padding-bottom: 2px;
		}
	}
}

.filterBtn{
	display: none;
	.btn{
		font-family: 'freightsans_promedium';
		font-size: rem(18px);
		line-height: rem(28px);
		letter-spacing: 0.5px;
		color: $GK-Dark;
		padding: 10px 24px 10px 54px;
		background: #E5F2ED;
		border-radius: 40px;
		position: relative;
		&:after{
			content: url('../images/icons/filter.svg');
			position: absolute;
			top: 50%;
			left: 25px;
			margin-top: -10px;
			width: 18.33px;
			height: 18.17px;
		}
	}
	@media (max-width: 992px) {
		display: block;
	}
}

.overlayOptions{
	background: #6D6D6D;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 9;
	opacity: 0;
	pointer-events: none;
	transition: all 300ms;
	@media (max-width: 992px) {
		&.active{
			opacity: 0.7;
			pointer-events: all;
			display: block;
		}
	}
}

.map{
	margin-bottom: 150px;
	@media(max-width: 992px){
		margin-bottom: 60px;
	}
	iframe{
		width: 100%;
		height: 654px;
		@media(max-width: 992px){
			height: 500px;
		}
	}
}

.officeInfo{
	//min-width: 280px;
	padding: 8px 40px 8px 8px;

	@media(max-width: 992px){
		width: 100%;
		min-width: 0;
	}

  h1{
    font-size: rem(21px);
		font-family: 'freightsans_prosemibold';
    line-height: rem(32px);
		letter-spacing: rem(0.5px);
  }
  h5{
    font-size: rem(18px);
		font-family: 'freightsans_prosemibold';
    line-height: rem(28px);
		letter-spacing: rem(0.5px);
		margin-top: 12px;
	}
	p{
		font-size: rem(18px);
		line-height: rem(28px);
		letter-spacing: rem(0.5px);
		font-family: 'freightsans_probook';
	}
	a{
		color: #08402B;
		font-size: rem(18px);
		line-height: rem(28px);
		letter-spacing: rem(0.5px);
		font-family: "freightsans_probook", sans-serif;
		border-bottom: 1.5px solid #007B4E;
		&:hover{
			text-decoration: none;
		}
	}
}

.ingress-text{
	p{
		font-family: 'freightsans_promedium';
		color: $GK-Dark;
		font-size: rem(38px);
		line-height: rem(48px);
		font-weight: 400;
		letter-spacing: 0.5px;
		text-align: center;
		max-width: 1014px;
		margin: 40px auto 100px auto;

		@media(max-width: 992px){
			font-size: rem(18px);
			line-height: rem(28px);
			margin-bottom: 50px;
		}
	}
}

hr.separator{
	margin: 60px 0;
}

.privat-pages{
	.breadcrumbs{
		margin-bottom: 80px;
	}
	.header-page{
		margin: 0;
		h1{
			font-size: rem(72px);
			line-height: rem(80px);
			@media(max-width: 992px){
				font-size: rem(44px);
				line-height: rem(48px);
			}
		}
		p{
			font-size: rem(21px);
			line-height: rem(32px);
			@media(max-width: 992px){
				font-size: rem(18px);
				line-height: rem(28px);
			}
		}
		&--ingress{
			a{
				position: relative;
				color: $GK-Green;
				padding-bottom: 2px;

				&::before{
					content: "";
					position: absolute;
					height: 1px;
					top: calc(100% + 2px);
					border-top: 1.5px solid $GK-Green;
					left: 1px;
					width: 0px;
					-webkit-transition: all 500ms ease-in-out;
					transition: all 500ms ease-in-out;
				}

				&:hover{
					color: $GK-Green;
					text-decoration: none;

					&::before{
						width: 100%;
					}
				}

				// Right arrow variation
				&.chevron{
					border-bottom: none;

					&::before{
						content: "";
						position: absolute;
						height: 1px;
						top: calc(100% + 2px);
						border-top: 1.5px solid $GK-Green;
						left: 1px;
						width: 0px;
						-webkit-transition: all 500ms ease-in-out;
						transition: all 500ms ease-in-out;
					}

					&:hover{
						&::before{
							width: calc(100% - 53px);
						}
					}

					&::after{
						content: '';
						background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzNCAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjkxNjggMjIuMjVMMTUuNTAwOSAyMi4yNUwyMS4wODM1IDE3LjAwMTlMMTUuNTAwOSAxMS43NUwxMi45MTY4IDExLjc1TDE4LjQ5OTUgMTcuMDAxOUwxMi45MTY4IDIyLjI1WiIgZmlsbD0iIzAwN0I0RSIvPgo8Y2lyY2xlIGN4PSIxNyIgY3k9IjE3IiByPSIxNi4yNSIgc3Ryb2tlPSIjMDA3QjRFIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K');
						display: inline-block;
						width: 34px;
						height: 34px;
						margin-bottom: -10px;
						margin-left: 10px;
						margin-right: 10px;
					}
				}
			}
		}
	}
	.text-media{
		@media(max-width: 992px){
			margin-bottom: 0;
		}
	}
	hr.separator{
		@media(max-width: 992px){
			display: none;
		}
	}
	.offices-tabs{
		margin-top: 60px;
		margin-bottom: 70px;
		.tabs{
			&:first-child{
    	  display: block;
			}
			.office-info{
				@media(max-width: 992px){
					.container{
						padding: 0 10px !important;
					}
				}
			}
			.slideshow{
				@media(max-width: 992px){
					margin: 10px 0;
				}
			}
		}
	}
	.related-content{
		padding-bottom: 70px;
	}
}

.offices-map{
	margin-bottom: 120px;

	@media(max-width: 992px){
		margin-bottom: 20px;
	}

	&__wrapper{
		display: flex;
		justify-content: space-between;

		@media(max-width: 992px){
			flex-direction: column;
		}

		&--accordion{
			flex-basis: 435px;
			width: 435px;
			margin-right: 170px;
			@media(max-width: 992px){
				flex-basis: 100%;
				margin-right: 0;
				width: 100%;
			}
		}
		&--map{
			flex-basis: calc(100% - (435px + 170px));
			width: calc(100% - (435px + 170px));
			@media(max-width: 992px){
				flex-basis: 100%;
				width: 100%;
				display: none;
			}
		}
	}
}

.campaign-text{
	margin-bottom:100px;

	.htmlArea{
		p{
			font-size: rem(21px);
			line-height: rem(32px);
			letter-spacing: 0.5px;
			margin-bottom: 40px;
			@media(max-width: 992px){
				font-size: rem(18px);
				line-height: rem(28px);
			}
		}

		ol{
			margin-bottom: 40px;
			padding-left: 20px;
			li{
				font-size: rem(21px);
				line-height: rem(32px);
				letter-spacing: 0.5px;
			}
		}

		ul{
			margin-bottom: 44px;
			list-style: none;

			li {
				position: relative;
				margin-bottom: 12px;
				padding-left: 22px;
				font-family: 'freightsans_probook';
				font-size: rem(21px);
				line-height: rem(32px);
				letter-spacing: 0.5px;

				&:last-child{
					margin-bottom: 0;
					color: $GK-Black;
				}

				strong{
					font-family: 'freightsans_promedium';
				}

				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 13px;
					width: 8px;
					height: 8px;
					background-color: $GK-Green;
				}
			}
		}

		h1, h2, h3, h4, h5, h6{
			margin-bottom: 40px;
			font-size: rem(24px);
      line-height: rem(32px);

		}
	}

	@media(max-width: 992px){
		margin-bottom: 60px;
	}
}
