

// Grid config
@import "./base/grid-config";

//import bootstrap
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/utilities";

//base application
@import "base/mixins";
@import "base/color";
@import "base/font-face";
@import "base/typography";
@import "base/global";
@import "base/image-aspect-ratio";
@import "base/buttons";
@import "base/animations";

//components
@import "components/header";
@import "components/footer";
@import "components/forms";
@import "components/dropdown";
@import "components/quote";
@import "components/color-box";
@import "components/accordion-in-text";
@import "components/article";
@import "components/search";
@import "components/articles";
@import "components/job-listings";
@import "components/text-media";
@import "components/services";
@import "components/subject-areas";
@import "components/breadcrumbs";
@import "components/landing-page";
@import "components/projects";
@import "components/split-image-macro";
@import "components/front-page";
@import "components/related-content";
@import "components/contact-card";
@import "components/our-projects.scss";
@import "components/image-carousel";
@import "components/kpi";
@import "components/quotes";
@import "components/contact-office";
@import "components/cookies";
@import "components/contact-form";
@import "components/list-articles.scss";
@import "components/offices-tabs.scss";
@import "components/personal-contacts.scss";
@import "components/read-more";
@import "components/office-info";
@import "components/contact-locator";
@import "components/privatBanner";
@import "components/office-locator";
@import "components/full-banner";
@import "components/slideshow";
@import "components/related-projects";
@import "components/campaign-highlight";
@import "components/comparison";
@import "components/banner-with-ingress";
@import "components/articles-slider";
@import "components/modal-video";
@import "components/links";
@import "components/intro";
@import "components/fact-box.scss";
@import "components/macro-icons";
@import "components/onboarding-login";
@import "components/cookiebot.scss";
@import "components/energy-calculator.scss";
@import "components/gobi-macro.scss";
@import "components/cookie-declaration.scss";
@import "components/newsletter.scss";
@import 'components/image-parts.scss';

// Rebrand

@import 'components/career/career-header.scss';
@import 'components/career/career-joblistings.scss';
@import 'components/career/career-related-articles.scss';
@import 'components/career/career-instagram.scss';
@import 'components/career/career-text-and-media.scss';
@import 'components/career/career-quote.scss';
@import 'components/career/career-cta.scss';
@import 'components/career/career-gobi.scss';
@import 'components/career/career-article-content.scss';

/* main.css */
.hidden {
    display: none !important;
}
