.app-cookie-line{
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 312px;
  display: flex;
  flex-direction: column;
  background-color: $GK-Dark;
  color: #FFFFFF;
  justify-content: center;
  z-index: 999;

  &.is-hidden{
    display: none;
  }

  @media(max-width: 992px){
    height: auto;
    padding: 24px 0;
  }

  h1, h2, h3, h4, h5, h6, p, ul, li, a{
    color: #FFFFFF;
  }

  .app-cookie-line-inner{
    @extend .container;

    @media(max-width: 992px){
      padding: 0px 24px !important;
    }
  }

  .app-cookie-line-title{
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
    letter-spacing: 0.5px;
    @media(max-width: 992px){
      font-size: 20px;
      line-height: 28px;
    }
  }

  .app-cookie-line-intro{
    font-size: 21px;
    line-height: 32px;
    letter-spacing: 0.5px;
    max-width: 917px;
    @media(max-width: 992px){
      font-size: 18px;
      line-height: 28px;
    }
  }

  .app-cookie-line-buttons{
    margin-top: 40px;

    @media(max-width: 992px){
      margin-top: 21px;
      display: inline-flex;
      flex-direction: column-reverse;
    }

    a{
      margin-right: 44px;

      @media(max-width: 992px){
        margin-right: 0;
        margin-bottom: 0px;
      }

      &.mod-read-more{
        color: #ffffff;
        text-decoration: none;
        display: inline-block;
        font-family: 'freightsans_probook';
        font-style: normal;
        font-size: 1.3125rem;
        line-height: 2rem;
        letter-spacing: 0.5px;
        border-bottom: 1.5px solid #007B4E;
        &:hover{
          text-decoration: none;
        }

        @media(max-width: 992px){
          margin-bottom: 30px;
        }
      }

      &.mod-accept{
        @extend .btn;
        @extend .btn--push;

        &:hover{
          color: #FFFFFF;
        }

        @media(max-width: 992px){
          align-self: flex-start;
        }
      }

    }
  }
}
