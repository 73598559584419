.links{

  h3{
    font-size: rem(44px);
    line-height: rem(48px);
    margin-bottom: 40px;
    @media(max-width: 992px){
      font-size: rem(28px);
      line-height: rem(36px);
    }
  }

  &__box{
    background-color: $GK-Off-White;
    padding: 28px 44px;
    margin-bottom: 48px;

    @media(max-width: 992px){
      padding: 20px;
      margin-bottom: 20px;
    }

    &.blue{
      background-color: $GK-Light-Blue;
    }
    &.green{
      background-color: $GK-Light-Green;
    }

    .pretitle{
      font-size: rem(18px);
      line-height: rem(28px);
      font-weight: 600;
      color: $GK-Dark;
      margin-bottom: 12px;
      display: block;
      letter-spacing: 0.5px;
      @media(max-width: 992px){
        margin-bottom: 8px;
        font-size: rem(16px);
      }
    }

    h4{
      font-size: rem(28px);
      line-height: rem(44px);
      font-weight: 600;
      color: $GK-Dark;
      margin-bottom: 12px;
      letter-spacing: 0.5px;
      @media(max-width: 992px){
        font-size: rem(21px);
        margin-bottom: 8px;
        line-height: rem(32px);
      }
    }

    p{
      color: $GK-Dark;
      letter-spacing: 0.5px;
      font-size: rem(18px);
      line-height: rem(28px);
      @media(max-width: 992px){
        font-size: rem(16px);
      }
    }
  }
}