// Image aspect ratio
.img {
  position: relative;
  width: 100%;
  overflow: hidden;

  img, > iframe, video {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transform: translate(-50%, -50%);
  }

  video {
    height: 100%;
    width: auto;
  }

  &.aspectRatio--1x1 {
    padding-top: calc((1 / 1) * 100%);
	}

  &.aspectRatio--4x3 {
    padding-top: calc((3 / 4) * 100%);
	}

  &.aspectRatio--16x9 {
    padding-top: calc((9 / 16) * 100%);
  }

  &.aspectRatio--1400x689 {
    padding-top: calc((689 / 1400) * 100%);
  }

  &.aspectRatio--436x291 {
    padding-top: calc((291 / 436) * 100%);
  }

  &.aspectRatio--676x388 {
    padding-top: calc((388 / 676) * 100%);
  }

  &.aspectRatio--1680x757 {
    padding-top: calc((757 / 1680) * 100%);
  }
  &.aspectRatio--797x563 {
    padding-top: calc((563 / 797) * 100%);
  }

  &.aspectRatio--555x369 {
    padding-top: calc((369 / 555) * 100%);
  }

  &.aspectRatio--1159x733 {
    padding-top: calc((733 / 1159) * 100%);
  }

  &.aspectRatio--335x431 {
    padding-top: calc((431 / 335) * 100%);
  }

  &.aspectRatio--1540x730 {
    padding-top: calc((730 / 1540) * 100%);
  }

  &.aspectRatio--1279x670 {
    padding-top: calc((670 / 1279) * 100%);
  }

  &.aspectRatio--724x446 {
    padding-top: calc((446 / 724) * 100%);
  }

  &.aspectRatio--360x432 {
    padding-top: calc((432 / 360) * 100%);
  }

  &.aspectRatio--289x168 {
    padding-top: calc((168 / 289) * 100%);
  }

  &.aspectRatio--797x500 {
    padding-top: calc((500 / 797) * 100%);
  }

  &.aspectRatio--797x563 {
    padding-top: calc((563 / 797) * 100%);
  }

  &.aspectRatio--555x369 {
    padding-top: calc((369 / 555) * 100%);
  }

  &.aspectRatio--1540x731 {
    padding-top: calc((731 / 1540) * 100%);
  }

  &.aspectRatio--315x276 {
    padding-top: calc((276 / 315) * 100%);
  }

  &.aspectRatio--1680x757 {
    padding-top: calc((757 / 1680) * 100%);
  }

  &.aspectRatio--434x240 {
    padding-top: calc((240 / 434) * 100%);
  }

  &.aspectRatio--320x248 {
    padding-top: calc((248 / 320) * 100%);
  }

  &.aspectRatio--864x724 {
    padding-top: calc((724 / 846) * 100%);
  }

  &.aspectRatio--1400x724 {
    padding-top: calc((724 / 1400) * 100%);
  }
  &.aspectRatio--700x828 {
    padding-top: calc((828 / 700) * 100%);
  }
}
