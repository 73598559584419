header.main-header{
  margin-bottom: 60px;
  z-index: 8;
  position: relative;
  @media (max-width: 992px) {
    margin-bottom: 10px;
  }
  .header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
    @media (max-width: 992px) {
      padding-top: 50px;
    }
    &__logo{
        display: inline-block;
        width: 81px;
        height: auto;
        @media (max-width: 992px) {
          width: 57px;
        }
    }
    &__buttons{
      text-align: right;
      &--menu, &--search{
        font-family: 'freightsans_promedium';
        font-size: rem(21px);
        line-height: rem(26px);
        letter-spacing: 0.5px;
        color: $GK-Dark;
        padding: 0 0 0 36px;
        position: relative;
        @media (max-width: 992px) {
          overflow: hidden;
          padding: 0 0 0 24px;
          width: 0;
        }
        &:hover{
          color: $GK-Dark;
        }
      }
      .btn{
        margin-left: 40px;
        &:first-child{
          margin-left: 0;
        }
        @media (max-width: 992px) {
          margin-left: 26px;
          &:first-child{
            margin-left: 0;
          }
        }
      }
      &--menu{
        &:before{
          content: "";
          @include background-image ("../images/icons/menu-icon.svg",24,24);
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -10px;
          @media (max-width: 992px) {
            margin-top: -12px;
          }
        }
      }
      &--search{
        &:before{
          content: "";
          @include background-image ("../images/icons/search-icon.svg",24,24);
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -10px;
        }
      }
      &--privat{
        background: $GK-Green;
        font-family: 'freightsans_promedium';
        font-size: rem(21px);
        line-height: rem(32px);
        letter-spacing: 0.5px;
        color: #FFFFFF;
        border-radius: 40px;
        padding: 12px 21px;
        &:hover{
          color: #FFFFFF;
        }
      }
    }
    &__options{
      a{
        color: $GK-Dark;
        text-decoration: none;
        /* border-bottom: 1.5px solid $GK-Green; */
        font-size: rem(21px);
        font-weight: 400;
        font-family: 'freightsans_promedium';
        margin-right: 24px;
        border-bottom: none;
        opacity: 0.6;
        transition: 0s;
        &:last-child{
          margin-right: 0;
        }
        &:hover {
          opacity: 1;
          text-decoration: underline;
          text-decoration-color: $GK-Green;
          text-underline-offset: 6px;
          text-decoration-thickness: 1px;

        }
        &.active, &.active:hover{
          opacity: 1;
          text-decoration: underline;
          text-underline-offset: 7px;
          text-decoration-thickness: 1px;

        }
      }
    }
    *{
      transition: all 200ms;
    }
  }
  .subHeader{
    text-align: center;
    border-top: 1px solid rgba(8, 64, 43, 0.1);
    padding-top: 16px;
    padding-bottom: 16px;
    a{
      display: inline-block;
      font-family: 'freightsans_promedium';
      font-size: rem(18px);
      line-height: rem(26px);
      letter-spacing: 0.5px;
      color: #08402B;
      margin: 0 20px;
    }
  }
  &.fixed{
    background: #ffffff;
    position: fixed;
    width: 100%;
    top: -200px;
    left: 0;
    z-index: 9;
    transition: all 300ms;
    &.show{
      top: 0px;
    }
  }

  &--white{
    .header{
      &__logo{
        filter: brightness(0) invert(1);
      }
      &__buttons{
        filter: brightness(0) invert(1);
      }
      &__options{
        a{
          color: #FFFFFF;
          &.active, &:hover{
            border-bottom: 1.5px solid #FFFFFF;
          }
        }
      }
    }
  }
}


.navigation{
  z-index: 1001;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  left: 0;
  -ms-overflow-style: none;
  display: none;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper{
    position: relative;
    background: #F7F6F4;
    min-height: 100vh;

    &:after{
      content: "";
      position: absolute;
      width: 60vw;
      height: 100%;
      background: #F7F6F4;
      top: 0;
      right: -50vw;
    }

    @media(max-width: 992px){
      &:before{
        content: "";
        position: absolute;
        width: 50vw;
        height: 100%;
        background: #F7F6F4;
        top: 0;
        left: -50vw;
      }
    }

    .content{
      padding: 150px 0 95px 10.3%;
      overflow: hidden;
      @media (max-width: 992px) {
        padding: 117px 0 110px 0;
      }
      .dropMenu{
        &.active{
          top: 0;
        }

        scrollbar-width: none;
        &::-webkit-scrollbar {
          width: none;
        }

        &__desktop{
          z-index: 0;

          display: block;
          @media(max-width: 992px){
            display: none;
          }

          &--items{
            float: left;
            width: calc(44.3% - 24px);
            padding-right: 40px;
            margin-right: 48px;

            .item{
              margin-bottom: 24px;
              a{

                text-decoration: none;
                font-family: 'freightsans_promedium';
                font-size: rem(44px);
                line-height: rem(48px);
                letter-spacing: 0.5px;
                color: $GK-Dark;
                position: relative;
                border-bottom: 2px solid transparent;
                // overflow-wrap: break-word;
                // word-wrap: break-word;
                span{
                  position: relative;
                  &:after{
                    opacity: 0;
                    @include background-image("./icons/arrow-item-menu.svg", 16.40, 22);
                    position: absolute;
                    right: -35px;
                    top: 50%;
                    margin-top: -7px;
                    transform: translateX(-30px);
                    pointer-events: none;
                  }
                }

                &.active,&:hover{
                  color: $GK-Green;
                  border-bottom: 1.5px solid $GK-Green;
                  span{
                    &:after{
                      opacity: 1;
                      transition: all 350ms;
                      transform: translateX(0);
                    }
                  }
                }

              }
            }
          }


          &--subItems{
            float: left;
            position: relative;
            width: calc(44.3% - 24px);
            margin-right: 48px;

            .subItems{
              width: 300px;
              pointer-events: none;
              opacity: 0;
              transform: translateX(-100px);
              position: absolute;
              top: 0;
              left: 20;
              padding-bottom: 162px;

              .item{
                margin-bottom: 26px;
                a{
                  text-decoration: none;
                  font-family: 'freightsans_promedium';
                  font-size: rem(24px);
                  line-height: rem(32px);
                  letter-spacing: 0.5px;
                  color: $GK-Dark;
                  position: relative;
                  border-bottom: 1.5px solid $GK-Dark;
                  // overflow-wrap: break-word;
                  // word-wrap: break-word;
                }
              }
              &.active{
                pointer-events: all;
                transition: all 350ms;
                opacity: 1;
                transform: translateX(0);
              }

            }

          }

        }

        &__mobile{

          padding: 0 0 0 0;
          position: relative;

          display: none;
          @media(max-width: 992px){
            display: block;
          }

          &--items{
            .item{
              margin-bottom: 20px;
              &:last-child{
                margin-bottom: 8px;
              }
              > a{

                text-decoration: none;
                font-family: 'freightsans_promedium';
                font-size: rem(28px);
                line-height: rem(36px);
                letter-spacing: 0.5px;
                color: $GK-Dark;
                position: relative;
                border-bottom: 1.5px solid transparent;

                span{
                  position: relative;
                }

                &:hover,
                &.active{
                  color: $GK-Green;
                  border-bottom: 1.5px solid $GK-Green;
                }

              }

              .subItems{
                transition: all 300ms;
                margin-top: 0;
                opacity: 0;
                height: 0;
                overflow: hidden;
                .subItem{
                  margin-bottom: 16px;
                  &:last-child{
                    margin-bottom: 20px;
                  }
                  a{

                    text-decoration: none;
                    font-family: 'freightsans_promedium';
                    font-size: rem(18px);
                    line-height: rem(28px);
                    letter-spacing: 0.5px;
                    color: $GK-Dark;
                    position: relative;
                    border-bottom: 1.5px solid transparent;

                  }
                }
                &.active{
                  height:auto;
                  opacity: 1;
                  margin-top: 22px;
                }
              }

              &.item--expandable{
                >a{
                  padding-right: 32px;
                  span{
                    &:after{
                      transition: all 150ms;
                      @include background-image("./icons/arrow-down-item-menu.svg", 15, 17);
                      position: absolute;
                      right: -57px;
                      top: 50%;
                      margin-top: -7.5px;
                      transform: translateX(-30px);
                      pointer-events: none;
                    }
                  }
                  &.active{
                    span{
                      &:after{
                        transform: rotate(180deg);
                        right: -29px;
                      }
                    }
                  }
                }
              }

            }
          }


        }

      }


      .secondaryMenu{
        clear: both;
        padding-top: 20px;
        padding-bottom: 44px;
        @media (max-width: 992px) {
          padding-bottom: 40px;
        }
        &__items{
          span{
            display: block;
            margin-bottom: 8px;
            &:last-child{
              margin-bottom: 0px;
            }
            a{
              text-decoration: none;
              font-family: 'freightsans_promedium';
              font-size: rem(24px);
              line-height: rem(32px);
              letter-spacing: 0.5px;
              color: $GK-Dark;
              position: relative;
              border-bottom: 1.5px solid transparent;
              @media (max-width: 992px) {
                font-family: 'freightsans_probook';
                font-size: rem(21px);
                line-height: rem(32px);
              }
              &:hover{
                color: $GK-Green;
                border-bottom: 1.5px solid $GK-Green;
              }
            }
          }
        }

      }

      .bottomInfo{
        border-top: 1.5px solid #CED9D5;
        padding-top: 44px;
        @media (max-width: 992px) {
          padding-top: 40px;
        }
        &__language{
          font-family: 'freightsans_probook';
          font-size: rem(21px);
          line-height: rem(32px);
          letter-spacing: 0.5px;
          color: $GK-Dark;
          margin-bottom: 14px;
          @media (max-width: 992px) {
            font-size: rem(18px);
            line-height: rem(28px);
            margin-bottom: 8px;
          }
          a{
            text-decoration: none;
            font-family: 'freightsans_probook';
            font-size: rem(21px);
            line-height: rem(32px);
            letter-spacing: 0.5px;
            color: $GK-Dark;
            @media (max-width: 992px) {
              font-size: rem(18px);
              line-height: rem(28px);
            }
            &.active{
              font-family: 'freightsans_promedium';
            }
          }
        }
        &__goTo{
          font-family: 'freightsans_probook';
          font-size: rem(21px);
          line-height: rem(32px);
          letter-spacing: 0.5px;
          color: $GK-Dark;
          @media (max-width: 992px) {
            font-size: rem(18px);
            line-height: rem(28px);
          }
          a{
            text-decoration: none;
            font-family: 'freightsans_promedium';
            font-size: rem(21px);
            line-height: rem(32px);
            letter-spacing: 0.5px;
            color: $GK-Dark;
            border-bottom: 1.5px solid $GK-Green;
            @media (max-width: 992px) {
              font-size: rem(18px);
              line-height: rem(28px);
            }
          }
        }
      }

    }

    &--buttons{
      position: absolute;
      right: 0;
      top: 40px;
      z-index: 1;

      @media (max-width: 992px) {
        text-align: right;
        top: 50px;
      }
      .btn-menu, .btn-search{
        font-family: 'freightsans_promedium';
        font-size: rem(21px);
        line-height: rem(26px);
        letter-spacing: 0.5px;
        color: $GK-Dark;
        padding: 0;
        position: relative;

        &:hover{
          color: $GK-Dark;
        }
      }
      .btn{
        margin-left: 40px;
        &:first-child{
          margin-left: 0;
        }
        @media (max-width: 992px) {
          margin-left: 26px;
          &:first-child{
            margin-left: 0;
          }
        }
      }
      .btn-menu{
        > span{
          @media (max-width: 992px) {
            display: none;
          }
        }
        .menuCloseIcon {
          display: inline-block;
          width: 18px;
          height: 18px;
          position: relative;
          margin: 0 10px -4px 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .5s ease-in-out;
          -moz-transition: .5s ease-in-out;
          -o-transition: .5s ease-in-out;
          transition: .5s ease-in-out;
          cursor: pointer;

          @media (max-width: 992px) {
            margin: 0px 3px -2px 0;
          }

          span {
            display: block;
            position: absolute;
            height: 2px;
            width: 20px;
            background: #08402B;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;


            &:nth-child(1) {
              top: 0px;
              -webkit-transform-origin: left center;
              -moz-transform-origin: left center;
              -o-transform-origin: left center;
              transform-origin: left center;
            }

            &:nth-child(2) {
              top: 7px;
              -webkit-transform-origin: left center;
              -moz-transform-origin: left center;
              -o-transform-origin: left center;
              transform-origin: left center;
            }

            &:nth-child(3) {
              top:14px;
              -webkit-transform-origin: left center;
              -moz-transform-origin: left center;
              -o-transform-origin: left center;
              transform-origin: left center;
            }

          }

          &.open{
            span{
              &:nth-child(1) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
                top: 0px;
                left: 3px;
              }

              &:nth-child(2) {
                width: 0%;
                opacity: 0;
              }

              &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
                top: 14px;
                left: 3px;
              }
            }
          }
        }

      }
      .btn-search{
        padding: 0 0 0 36px;
        @media (max-width: 992px) {
          overflow: hidden;
          padding: 0 0 0 24px;
          width: 0;
        }
        &:before{
          content: "";
          @include background-image ("../images/icons/search-icon.svg",24,24);
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -10px;
        }
      }
      .privat{
        background: $GK-Green;
        font-family: 'freightsans_promedium';
        font-size: rem(21px);
        line-height: rem(32px);
        letter-spacing: 0.5px;
        color: #FFFFFF;
        border-radius: 40px;
        padding: 12px 21px;
        &:hover{
          color: #FFFFFF;
        }
      }
    }

  }

  .overlay{
    background: rgba($color: #6D6D6D, $alpha: .7);
    // animation: backgroundFadeOut 400ms 200ms both;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 800ms;
    pointer-events: none;
  }

  &.active{
    .overlay{
        opacity: 1;
        pointer-events: all;
    }
  }

}

