.comparison{

  margin-bottom: 100px;

  &__box{
    background-color: $GK-Off-White;
    padding: 48px;
    width: 100%;

    @media(max-width: 992px){
      padding: 40px 30px;
    }

    &-blue{
      background-color: $GK-Light-Blue;
    }

    h4{
      font-size: rem(38px);
      line-height: rem(48px);
      letter-spacing: 0.5px;
      margin-bottom: 32px;
      @media(max-width: 992px){
        font-size: rem(28px);
        line-height: rem(36px);
      }
    }

    h5{
      font-size: rem(24px);
      line-height: rem(32px);
      letter-spacing: 0.5px;
      margin-bottom: 24px;
    }

    ul{
      list-style: none;
      padding-left: 30px;
      text-indent: -30px;
      li{
        margin-bottom: 12px;
        &:before{
          content: "";
          width: 8px;
          height: 8px;
          margin-right: 23px;
          background-color: green;
          display: inline-block;
        }
      }
    }
    p, ul{
      margin-bottom: 40px;
    }

    p, ul, li{
      font-size: rem(21px);
      line-height: rem(32px);
      letter-spacing: 0.5px;

      @media(max-width: 992px){
        font-size: rem(18px);
        line-height: rem(28px);
      }
    }

    .videoWrapper{
      @media (max-width: 992px) {
        margin-left: 0;
      }

      figure{
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        button{
          position: absolute;
          top: calc(50% - 69px);
          left: calc(50% - 69px);
          z-index: 1;
        }
      }

      video{
        display: none;
        width: 100%;
      }

      &--youtube{
        .iframe-wrapper{
          display: none;
          position: relative;
          padding-top: calc((9 / 16) * 100%);
          height: 0;

          iframe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .text-accordion__wrapper{
    .text-accordion{
      .text-accordion__toggler{
        font-size: rem(21px);
        font-weight: 400 !important;
        font-family: 'freightsans_promedium';
        letter-spacing: 0.5px;
      }
    }
  }

  .comparison__box{
    a{
      position: relative;
      color: $GK-Green;
      padding-bottom: 2px;

      @extend a.underline-animation;

      &:not(.btn){
        background: linear-gradient(currentColor 0 0)  0 100% /var(--d, 0) 1px  no-repeat;
        -webkit-transition: all 500ms ease-in-out;
        transition: all 500ms ease-in-out;
        &:hover {
          --d: 100%;
        }

        &:before{
          display: none!important;
        }
      }

      // Right arrow variation
      &.chevron{
        border-bottom: none;

        &::before{
          content: "";
          position: absolute;
          height: 1px;
          top: calc(100% + 2px);
          border-top: 1.5px solid $GK-Green;
          left: 1px;
          width: 0px;
          -webkit-transition: all 500ms ease-in-out;
          transition: all 500ms ease-in-out;
        }

        &:hover{
          &::before{
            width: calc(100% - 53px);
          }
        }

        &::after{
          content: '';
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzNCAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjkxNjggMjIuMjVMMTUuNTAwOSAyMi4yNUwyMS4wODM1IDE3LjAwMTlMMTUuNTAwOSAxMS43NUwxMi45MTY4IDExLjc1TDE4LjQ5OTUgMTcuMDAxOUwxMi45MTY4IDIyLjI1WiIgZmlsbD0iIzAwN0I0RSIvPgo8Y2lyY2xlIGN4PSIxNyIgY3k9IjE3IiByPSIxNi4yNSIgc3Ryb2tlPSIjMDA3QjRFIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K');
          display: inline-block;
          width: 34px;
          height: 34px;
          margin-bottom: -10px;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}
