.career-part {
    .career-cta {
        background-position: center;
        background-size: cover;
        padding: 230px 0;
        margin-bottom: 100px;

        @media (max-width: 992px) {
            margin-bottom: 60px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 500px;
        }

        &__wrapper {
            padding: 40px 120px;
            background-color: #fff;

            @media (max-width: 992px) {
                padding: 32px 24px;
            }
        }

        &__content {
            text-align: center;

            * {
                text-align: inherit;
            }
        }

        &__title {
            h2 {
                font-family: 'freightbig_probold', serif;
                font-size: rem(40px);
                line-height: rem(48px);
                margin-bottom: 20px;

                @media (max-width: 992px) {
                    font-size: rem(28px);
                    line-height: rem(32px);
                    margin-bottom: 12px;
                }
            }
        }

        &__ingress {
            p {
                font-size: rem(21px);
                line-height: rem(32px);

                @media (max-width: 992px) {
                    font-size: rem(16px);
                    line-height: rem(24px);
                }
            }
        }

        &__button {
            .button {
                font-family: 'freightsans_prosemibold', sans-serif;

                @media (max-width: 992px) {
                    font-family: 'freightsans_promedium', sans-serif;
                }

                display: inline-block;
                background-color: transparent;
                padding: 10px 36px; //2 px of difference to match the design using internal borders
                font-size: rem(18px);
                line-height: rem(28px);
                color: $GK-Dark;
                border: 2px solid $GK-Dark;
                border-radius: 40px;
                text-decoration: none;
                margin-top: 32px;

                @media (max-width: 992px) {
                    margin-top: 20px;
                    padding: 10px 24px;
                }

                &:hover {
                    text-decoration: none;
                    color: $GK-Dark;
                }
            }
        }
    }
}
