.search{
  z-index: 1001;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  left: 0;
  -ms-overflow-style: none;
  background: #F7F6F4;
  display: none;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &__buttons{
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 1;
    @media (max-width: 992px) {
      top: 50px;
      text-align: right;
    }
    .btn-menu, .btn-search{
      font-family: 'freightsans_promedium';
      font-size: rem(21px);
      line-height: rem(26px);
      letter-spacing: 0.5px;
      color: $GK-Dark;
      padding: 0;
      position: relative;

      &:hover{
        color: $GK-Dark;
      }
    }
    .btn{
      margin-left: 40px;
      &:first-child{
        margin-left: 0;
      }
      @media (max-width: 992px) {
        margin-left: 26px;
        &:first-child{
          margin-left: 0;
        }
      }
    }
    .btn-menu{
      > span{
        @media (max-width: 992px) {
          display: none;
        }
      }
      .menuCloseIcon {
        display: inline-block;
        width: 18px;
        height: 18px;
        position: relative;
        margin: 0 10px -4px 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;

        @media (max-width: 992px) {
          margin: 2px 3px -2px 0;
        }

        span {
          display: block;
          position: absolute;
          height: 2px;
          width: 18px;
          background: #08402B;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .25s ease-in-out;
          -moz-transition: .25s ease-in-out;
          -o-transition: .25s ease-in-out;
          transition: .25s ease-in-out;


          &:nth-child(1) {
            top: 0;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
          }

          &:nth-child(2) {
            top: 7px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
          }

          &:nth-child(3) {
            top:14px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
          }

        }

        &.open{
          span{
            &:nth-child(1) {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
              top: 0px;
              left: 3px;
            }

            &:nth-child(2) {
              width: 0%;
              opacity: 0;
            }

            &:nth-child(3) {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
              top: 14px;
              left: 3px;
            }
          }
        }
      }

    }
    .btn-search{
      padding: 0 0 0 36px;
      @media (max-width: 992px) {
        overflow: hidden;
        padding: 0 0 0 24px;
        width: 0;
      }

      &:before{
        content: "";
        @include background-image ("../images/icons/close-navigation.svg",16,16);
        position: absolute;
        left: 3px;
        top: 50%;
        margin-top: -7px;
        transition: all 200ms linear;
        transform: rotate(0);
      }

      &.open{
        &:before{
          transform: rotate(180deg);
        }
      }

    }
    .privat{
      background: $GK-Green;
      font-family: 'freightsans_promedium';
      font-size: rem(21px);
      line-height: rem(32px);
      letter-spacing: 0.5px;
      color: #FFFFFF;
      border-radius: 40px;
      padding: 12px 21px;
      &:hover{
        color: #FFFFFF;
      }
    }
  }

  &__inputField{
    margin-top: 228px;
    @media (max-width: 992px) {
      margin-top: 157px;
    }
    h3{
      margin-bottom: 60px;
      font-family: 'freightsans_promedium';
      @media (max-width: 992px) {
        margin-bottom:40px;
        font-size: rem(28px);
        line-height: rem(30px);
      }
    }

    input{
      width: 100%;
      font-family: 'freightsans_probook';
      color: $GK-Dark;
      font-size: rem(28px);
      line-height: rem(36px);
      letter-spacing: 0.5px;
      padding: 0 0 12px 0;
      background: transparent;
      border: none;
      border-bottom: 1.5px solid #CED9D5;
      outline: none;

      &::-webkit-input-placeholder  { color: $GK-Dark; }
      &:-moz-placeholder { color: $GK-Dark; }

      @media (max-width: 992px) {
        padding: 0 0 8px 0;
      }
    }
    button{
      margin-top: 40px;
      font-size: rem(28px);
      line-height: rem(36px);
    }
  }


  &__results{
    display: none;
    padding-bottom: 157px;
    @media (max-width: 992px) {
      padding-bottom: 100px;
    }

    &--info{
      margin-top: 12px;
      font-family: 'freightsans_probook';
      color: $GK-Dark;
      font-size: rem(28px);
      line-height: rem(36px);
      letter-spacing: 0.5px;

      @media (max-width: 992px) {
        margin-top: 8px;
        font-size: rem(21px);
        line-height: rem(32px);
      }

      span{
        font-family: 'freightsans_prosemibold';
        color: $GK-Green;
      }
    }

    &--list{
      margin-top: 80px;
      @media (max-width: 992px) {
        margin-top: 60px;
      }
      a{
        font-family: 'freightsans_probook';
        color: $GK-Dark;
        font-size: rem(28px);
        line-height: rem(36px);
        text-decoration: none;
        letter-spacing: 0.5px;
        &:last-child{
          .item{
            border-bottom: 1.5px solid #CED9D5;
          }
        }

        .item{
          width: 100%;
          padding: 24px 70px 24px 13px;
          border-top: 1.5px solid #CED9D5;
          position: relative;
          overflow: hidden;
          @media (max-width: 992px) {
            padding: 18px 36px 18px 0;
            display: flex;
            flex-direction: column-reverse;
          }
          &:hover{
            &::after{
              right: 0;
            }
          }
          &::after{
            content: "";
            @include background-image ("/icons/arrow-item-result.svg",16.33,21);
            position: absolute;
            right: 15px;
            top: 50%;
            margin-top: -10.5px;
            transition: all 350ms;
            @media (max-width: 992px) {
              @include background-image ("/icons/arrow-item-result.svg",11.67,15);
              top: 26.5px;
              margin-top: 0;
            }
          }

          &__col{
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            float: left;
            @media (max-width: 992px) {
              width: 100%;
              float: none;
            }
            &:first-child{
              width: 193px;
              padding-right:15px;
              @media (max-width: 992px) {
                width: 100%;
                font-size: rem(16px);
                line-height: rem(28px);
              }
            }
            &:last-child{
              width: calc(100% - 210px);
              font-family: 'freightsans_promedium';
              padding-left: 28px;
              @media (max-width: 992px) {
                width: 100%;
                font-size: rem(21px);
                line-height: rem(32px);
                padding-left: 0px;
              }
            }
          }
        }
      }
    }
  }



}
