.office-info{
  margin-bottom: 100px;

  @media (max-width: 992px) {
    margin-bottom: 60px;
  }

  .office-info__wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // min-height: 432px;

    @media (max-width: 992px) {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    .office-info__text{
      display: flex;
      flex-wrap: wrap;
      flex-basis: 46%;

      @media (max-width: 992px) {
        flex-direction: column;
        flex-wrap: nowrap;
      }

      .info-item{
        flex-basis: 50%;
        margin-bottom: 88px;
        padding-right: 6.5%;

        @media (max-width: 992px) {
          flex-basis: 100%;
          margin-bottom: 40px;
        }

        h5{
          margin-bottom: 8px;

          @media (max-width: 992px) {
            margin-bottom: 5px;
            font-size: rem(24px);
            line-height: rem(32px);
          }
        }

        .typography-h5{
          margin-bottom: 8px;

          @media (max-width: 992px) {
            margin-bottom: 5px;
            font-size: rem(24px);
            line-height: rem(32px);
          }
        }

        ul{
          list-style: disc;
        }

        ul, ol{
          margin-bottom: 8px;
          padding-left: 20px;
          li{
            font-size: rem(21px);
            line-height: rem(32px);
            letter-spacing: 0.5px;
          }
        }

        .no-empty-p{
          margin-bottom: 8px;

          @media (max-width: 992px) {
            margin-bottom: 5px;
            font-size: rem(24px);
            line-height: rem(32px);
          }
        }

        p{
          font-size: rem(21px);
          line-height: rem(32px);

          @media (max-width: 992px) {
            font-size: rem(18px);
            line-height: rem(28px);
          }

          a{
            @extend a.underline-animation;

            &:not(.btn){
              background: linear-gradient(currentColor 0 0)  0 100% /var(--d, 0) 1px  no-repeat;
              -webkit-transition: all 500ms ease-in-out;
              transition: all 500ms ease-in-out;
              &:hover {
                --d: 100%;
              }

              &:before{
                display: none!important;
              }
            }
          }

          a.office-info__press_room_link.office-info__press_contacts_title {
            border-bottom: 1.5px solid #08402B;
            color: #08402B;
            font-family: 'freightsans_prosemibold';
            background: transparent;
          }
        }

        a.office-info__invoice.office-info__invoice-title {
          border-bottom: 1.5px solid #08402B;
          color: #08402B;
          font-family: 'freightsans_prosemibold';
          background: transparent;
        }
      }
    }

    .office-info__map{
      flex: 1;

      // @media (max-width: 992px) {
      //   height: 360px;
      // }

      // @media (max-width: 768px) {
      //   height: 238px;
      // }

      // iframe{
      //   width: 100%;
      // }

      figure{
        width: 100%;
        height: 100%;
        overflow: hidden;

        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .office-info__image{
      flex: 1;
      margin-left: 30px;

      @media (max-width: 992px) {
        order: -1;
        margin-left: 0;
        margin-bottom: 20px;
      }

      figure{
        height: 100%;

        @media (max-width: 992px) {
          height: 360px;
        }

        @media (max-width: 768px) {
          height: 238px;
        }

        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
