.list-articles{

  @media (max-width: 992px) {
    padding-bottom: 100px;
  }

  .list-articles__header{
    h4{
      display: none;
      font-family: 'freightsans_promedium';
      margin-bottom: 2px;
    }

    h2{
      margin-bottom: 40px;
      @media(max-width: 992px){
        margin-bottom: 20px;
        font-size: rem(44px);
        line-height: rem(48px);
      }


    }

    .intro{
      p{
        font-size: 28px;
        line-height: 36px;
        font-family: 'freightsans_probook';
        max-width: 724px;
        letter-spacing: rem(0.5px);
        @media (max-width: 992px) {
          font-size: rem(18px);
          line-height: rem(28px);
        }
      }
      border-bottom: 1.5px solid $GK-Strek-Green;
      padding-bottom: 40px;
      margin-bottom: 80px;
      @media (max-width: 992px) {
        padding-bottom: 20px;
        margin-bottom: 40px;
      }
    }
  }

  .list-articles__content{

    @media (max-width: 992px) {
      margin-right: -14px;
      margin-left: -14px;
    }

    .list-articles__item{
      margin-bottom: 80px;

      @media (max-width: 992px) {
        margin-bottom: 40px;
        padding: 0 !important;
      }

      .imageItem{
        overflow: hidden;

        .img{
          transition: all 700ms ease;

          img{
            &+img{
              width: 89px;
              height: 89px !important;
              @media (max-width: 992px) {
                width: 60px;
                height: 60px !important;
              }
            }
          }
        }

        &:hover{
          .img{
            transform: scale(1.05);
          }
        }
      }

      .infoItem{
        margin-top: 18px;

        @media (max-width: 992px) {
          margin-top: 12px;
        }

        .dateItem{
          font-family: 'freightsans_promedium';
          font-size: rem(18px);
          line-height: rem(26px);
          min-height: rem(26px);
          letter-spacing: 0.5px;
          color: #7D7D7D;
          margin-bottom: 12px;

          @media (max-width: 992px) {
            font-size: rem(16px);
            line-height: rem(28px);
            margin-bottom: 4px;
          }
        }

        .titleItem{
          margin-bottom: 12px;
          @media (max-width: 992px) {
            font-size: rem(21px);
            line-height: rem(32px);
            margin-bottom: 4px;
          }

          a{
            text-decoration: none;
            display: inline-block;

            h6{
              @media (max-width: 992px) {
                font-size: rem(21px);
                line-height: rem(32px);
              }
            }
          }
        }

        .ingressItem{
          position: relative;
          height: 102px;
          @media (max-width: 992px) {
            height: 90px;
          }
          p{
            position: relative;
            display: inline-block;
            font-family: 'freightsans_probook';
            font-size: rem(21px);
            line-height: rem(32px);
            letter-spacing: 0.5px;
            color: #343434;
            height: 96px;
            overflow: hidden;
            @media (max-width: 992px) {
              font-size: rem(18px);
              line-height: rem(28px);
              height: 84px;
            }
          }

          div{
            display: none;
            font-family: 'freightsans_probook';
            font-size: rem(21px);
            line-height: rem(32px);
            letter-spacing: 0.5px;
            color: #343434;
            position: absolute;
            right: 0;
            bottom: 6px;
            padding-left: 10px;
            padding-right: 7px;
            background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 10%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 10%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
            @media (max-width: 992px) {
              font-size: rem(18px);
              line-height: rem(28px);
            }

            .link{
              text-decoration: none;
              font-family: 'freightsans_probook';
              font-size: rem(21px);
              line-height: rem(32px);
              letter-spacing: 0.5px;
              color: #7D7D7D;
              @media (max-width: 992px) {
                font-size: rem(18px);
                line-height: rem(28px);
              }
            }
          }
        }
      }
    }

    .slick-slide {
      outline: none
    }
  }

  .btn-more-wrapper{
    display: flex;
    justify-content: center;
  }



  &--articles{
    padding-top: 80px;

    .list-articles__header{
      h2{
        @media(max-width: 992px){
          font-size: rem(44px);
          line-height: rem(48px);
        }
      }
      p{
        @media(max-width: 992px){
          font-size: rem(18px);
          line-height: rem(28px);
        }
      }
    }

    .list-articles__content{
      .list-articles__item{

        .infoItem{
          .dateItem{
            display: none;
          }
        }
      }
    }
  }
}
