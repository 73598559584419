.contact-card{
  .contact-card-part{
    margin-bottom: 105px;

    @media (max-width: 991px) {
      margin-bottom: 40px;
    }
  }

  .contact-card__list{
    display: flex;

    .card-wrapper{
      .contact-card__card{
        background-color: #F7F6F4;

        .hoverable-card {
          &:hover{
            figure{
              img{
                transform: scale(1.05);
              }
            }

            .text-content__desktop{
              h3{
                color: $GK-Green;
                transition: 0.1s;
                border-color: $GK-Green;
              }
            }
          }
        }

        > a, &__content{
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
          padding: 58px 42px;
          text-decoration: none;
          text-align: center;

          a{
            display: inline-flex;
            color: $GK-Black;
            margin-bottom: 5px;
          }

          @media (max-width: 992px) {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 15px 22px;
            text-align: left;
          }

          figure{
            width: 168px;
            height: 168px;
            margin-bottom: 28px;
            border-radius: 100%;
            overflow: hidden;

            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);

            @media (max-width: 992px) {
              width: 49px;
              min-width: 49px;
              height: 49px;
              margin-bottom: 0;
              margin-right: 21px;
            }

            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: all 700ms ease;
            }
          }


          .text-content__desktop{
            @media (max-width: 992px) {
              display: none;
            }

            h3{
              position: relative;
              display: inline;
              border-bottom: 1.5px solid $GK-Dark;
              padding-bottom: 2px;

              &::after{
                content: '';
                display: block;
                margin-bottom: 14px;
              }
            }

            .role{
              margin-bottom: 18px;
            }
          }

          .text-content__mobile{
            display: none;

            @media (max-width: 992px) {
              display: block;
            }

            p{
              color: $GK-Dark;

              &:last-child{
                font-family: 'freightsans_prosemibold';
              }
            }
          }
        }
      }
    }
  }

  &--column{
    position: absolute;
    left: 24px;

    @media (max-width: 992px) {
      position: relative;
      left: 0;
      width: 100%;
      padding: 0 10px;
    }

    header.contact-card__header{
      margin-bottom: 12px;

      @media (max-width: 992px) {
        display: none;
      }

      h3{
        font-family: 'freightsans_promedium';
        letter-spacing: 0.5px;
        font-size: rem(21px);
        line-height: rem(32px);
      }
    }

    .contact-card__list{
      flex-direction: column;

      .card-wrapper{
        .contact-card__card{
          width: 314px;
          margin-bottom: 32px;

          @media (max-width: 992px) {
            width: 100%;
          }
        }
      }
    }
  }

  &--row{
    @media (max-width: 992px) {
      width: 100%;
    }

    header.contact-card__header{
      margin-bottom: 40px;

      @media (max-width: 992px) {
        margin-bottom: 20px;
      }

      h3{
        @media (max-width: 992px) {
          font-size: rem(28px);
          line-height: rem(32px);
        }
      }
    }

    .contact-card__list{
      .card-wrapper{
        margin-bottom: 45px;

        @media (max-width: 992px) {
          margin-bottom: 32px;
        }

        .contact-card__card{
          width: 100%;
          height: 100%;
          margin-bottom: 0;
        }
      }
    }
  }

  // variation that keeps desktop design on mobile
  &--full-mobile{
    .contact-card__list{
      .card-wrapper{
        @media (max-width: 992px) {
          margin-bottom: 20px;
        }

        .contact-card__card{
          > a, &__content{
            @media (max-width: 992px) {
              flex-direction: column;
              justify-content: inherit;
              padding: 58px 42px;
              text-align: center;
            }

            a{
              display: inline-flex;
              color: $GK-Black;
              margin-bottom: 5px;
            }

            figure{
              @media (max-width: 992px) {
                width: 168px;
                height: 168px;
                margin-bottom: 28px;
                margin-right: 0px;
              }
            }

            .text-content__desktop{
              @media (max-width: 992px) {
                display: block;
              }
            }

            .text-content__mobile{
              @media (max-width: 992px) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.contact-card__modal{
  .modal-dialog{
    max-width: 918px;

    @media (max-width: 992px) {
      max-width: 100%;
      height: 100%;
      margin: 0;
    }

    .modal-content{
      border: none;
      border-radius: 0;
      background-color: $GK-Off-White;

      @media (max-width: 992px) {
        height: 100%;
      }

      button.close{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
        width: 64px;
        margin-left: auto;
        background: none;
        border: none;
        outline: none;

        @media (max-width: 992px) {
          height: 75px;
        }

        span{
          font-size: 50px;
          color: $GK-Dark;
        }
      }

      .modal-body{
        display: flex;
        flex-direction: row;
        padding: 0px 73px 70px 45px;
        background-color: $GK-Off-White;

        @media (max-width: 992px) {
          flex-direction: column;
          align-items: center;
          padding: 0 30px 100px;
        }

        .contact-info{
          width: 40%;
          padding-right: 93px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          @media (max-width: 992px) {
            width: 100%;
            padding: 0 0 40px;
            margin-bottom: 40px;
            border-bottom: 1.5px solid $GK-Strek-Green;
          }

          h3.name{
            display: none;
            margin-bottom: 14px;

            @media (max-width: 992px) {
              display: block;
            }
          }

          figure{
            width: 168px;
            height: 168px;
            margin-bottom: 28px;

            img{
              width: 100%;
              height: 100%;
              border-radius: 100%;
              object-fit: cover;
            }
          }
          .role{
            margin-bottom: 18px;
          }

          a{
            color: $GK-Black;
            margin-bottom: 5px;

            &:last-child{
              margin-bottom: 0;
            }
          }
        }

        .contact-bio{
          width: 60%;

          @media (max-width: 992px) {
            width: 100%;
          }

          h3.name{
            margin-bottom: 18px;
            @media (max-width: 992px) {
              display: none;
            }
          }

          p{
            margin-bottom: 30px;

            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 993px) {
  .contact-card__card .hoverable-card-no-content {
    pointer-events: none;
  }
  .contact-card__card .hoverable-card-no-content .underline-animation {
    pointer-events: all;
  }
}
