.gobi-box {
    display: flex;
    align-items: center;
    padding: 31px 95px 31px 34px;
    margin-bottom: 60px;

    @media (max-width: 992px) {
        display: none;
    }

    &.gk-color-box {
        padding: 31px 34px;
    }

    .gobi-container {
        margin-right: 28px;
    }

    &__content {
        .title {
            font-family: 'freightsans_prosemibold', sans-serif;
            color: $GK-Dark;
            font-size: rem(21px);
            line-height: rem(32px);
            margin-bottom: 12px;
        }

        .text {
            font-size: rem(21px);
            line-height: rem(32px);
            margin-bottom: 0px;
        }
    }

    &--mobile {
        display: none;
        padding: 28px 30px;
        text-align: center;
        overflow: hidden;
        margin-bottom: 40px;

        @media (max-width: 992px) {
            display: block;
        }

        @media (max-width: 576px) {
            margin-right: -30px;
            margin-left: -30px;
        }

        .title {
            font-family: 'freightsans_prosemibold', sans-serif;
            color: $GK-Dark;
            font-size: rem(18px);
            line-height: rem(28px);
            margin-bottom: 10px; //24px on figma, but 10px here because the gobi element has 14px margin
        }

        .text {
            font-size: rem(16px);
            line-height: rem(18px);
            margin-top: 10px; //24px on figma, but 10px here because the gobi element has 14px margin
        }

        .gobi-container {
            margin: 0;
        }
    }
}

.gobi-carousel {
    position: relative;
    text-align: center;
    padding: 45px 34px 31px 34px;
    margin-bottom: 60px;

    @media (max-width: 992px) {
        padding: 28px 0;
        // overflow: hidden;
        margin-bottom: 40px;
    }

    @media (max-width: 576px) {
        margin-right: -30px;
        margin-left: -30px;
    }

    .title {
        font-family: 'freightsans_prosemibold', sans-serif;
        color: $GK-Dark;
        font-size: rem(21px);
        line-height: rem(32px);
        margin-bottom: 12px;
    }

    .carousel-wrapper {
        position: relative;
        display: block;
        gap: calc(40px - 28px);
        align-items: center;
        justify-content: center;
        overflow: hidden;

        @media (max-width: 992px) {
            overflow: visible;
        }

        .gobi-stories{
            &.slick-slide{
                padding: 0 12px;
            }
        }
    }

    #article-gobi-previous {
        z-index: 1;
        position: absolute;
        top: 50%;
        left: -19px;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' transform='rotate(-180 20 20)' fill='%23F16F64'/%3E%3Cpath d='M24.3333 14L21.3802 14L15 19.9978L21.3802 26L24.3333 26L17.9532 19.9978L24.3333 14Z' fill='white'/%3E%3C/svg%3E");
        border: none;

        @media (max-width: 992px) {
            // display: none;
        }

        @media (max-width: 768px) {
            left: 30px !important;
        }

        &.slick-disabled{
            cursor: not-allowed;
            opacity: .5;
        }
    }

    #article-gobi-next {
        z-index: 1;
        position: absolute;
        top: 50%;
        right: -19px;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        rotate: 180deg;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' transform='rotate(-180 20 20)' fill='%23F16F64'/%3E%3Cpath d='M24.3333 14L21.3802 14L15 19.9978L21.3802 26L24.3333 26L17.9532 19.9978L24.3333 14Z' fill='white'/%3E%3C/svg%3E");
        border: none;

        @media (max-width: 992px) {
            // display: none;
        }

        @media (max-width: 768px) {
            right: 30px !important;
        }

        &.slick-disabled{
            cursor: not-allowed;
            opacity: .5;
        }
    }
}

.gobi-box, .gobi-carousel {
    &--green {
        background-color: $GK-Light-Green;
    }

    &--blue {
        background-color: $GK-Light-Blue;
    }

    &--gray {
        background-color: $GK-Off-White;
    }

    &--lun {
        background-color: #FEE3DC;
    }

    &--varme {
        background-color: $GK-Red;
        color: #fff;
    }

    &--white {
        background-color: white;
    }

    &--green, &--blue, &--gray, &--white {
        #article-gobi-previous, #article-gobi-next {
            background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' transform='rotate(-180 20 20)' fill='%23007B4E'/%3E%3Cpath d='M24.3333 14L21.3802 14L15 19.9978L21.3802 26L24.3333 26L17.9532 19.9978L24.3333 14Z' fill='white'/%3E%3C/svg%3E");
        }
    }
}
