.s-hidden {
  visibility:hidden;
  padding-right:10px;
}

.dropdown__wrapper{
  display: inline-block;
  width: 100%;

  .dropdown__label{
    margin-bottom: 8px;
    font-family: 'freightsans_promedium';
    font-size: rem(16px);
    line-height: rem(28px);
    letter-spacing: 0.5px;
    color: $GK-Dark;
  }

  .dropdown {
    position:relative;
    display:inline-block;
    width: 100%;
    height: 58px;
    cursor:pointer;

    @media (max-width: 992px) {
      height: 50px;
    }

    .styledSelect {
      position:absolute;
      top:0;
      right:0;
      left:0;
      background-color:$GK-Light-Green;
      padding: 12px 44px 12px 20px;
      border-bottom: 1.5px solid $GK-Green;
      color: $GK-Dark;
      font-family: 'freightsans_promedium';
      font-size: rem(21px);
      line-height: rem(32px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (max-width: 992px) {
        padding: 11px 44px 13px 20px;
        font-size: rem(18px);
        line-height: rem(26px);
      }

      &::after{
        content:"";
        position:absolute;
        top: 18px;
        right: 12px;
        width:24px;
        height:24px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik0zIDVMMyA5LjQyOTc3TDExLjk5NjggMTlMMjEgOS40Mjk3N0wyMSA1TDExLjk5NjggMTQuNTcwMkwzIDVaIiBmaWxsPSIjMDA3QjRFIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==');
        background-repeat: no-repeat;
        transform: rotate(0);
        transition: 0.25s 0.1s;
        @media (max-width: 992px) {
          top: 15px;
        }
      }

      &:active , &.active{
        //background-color:#eee;

        &::after{
          transform: rotate(180deg);
        }
      }
    }

    .options {
      overflow: auto;
      max-height: 300px;
      display:none;
      position:absolute;
      top: 56px;
      right:0;
      left:0;
      z-index:999;
      margin:0;
      padding:0;
      list-style:none;
      background-color:$GK-Light-Green;

      @media (max-width: 992px) {
        top: 50px;
      }

      li{
        display: flex;
        align-items: center;
        margin:0;
        padding: 12px 20px;
        border-bottom: 1.5px solid #CCE5DC;
        color: $GK-Dark;
        font-family: 'freightsans_probook';
        font-size: rem(21px);
        line-height: rem(32px);
        letter-spacing: 0.5px;

        @media (max-width: 992px) {
          font-size: rem(18px);
          line-height: rem(26px);
        }

        &:last-child{
          border-bottom: none;
        }

        &::before{
          content: '';
          display: inline-block;
          border: 1px solid $GK-Green;
          border-radius: 100%;
          margin-right: 12px;
          transition: 0.05s;
          padding: 9px;
          @media (max-width: 992px) {
            font-size: rem(18px);
            line-height: rem(26px);
            margin-top: 2px;
          }
        }

        &:hover{
          &::before{
            background-color: $GK-Green;
          }
        }
      }
    }
  }
}

