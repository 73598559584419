.related-content{
  padding-bottom: 150px;

  @media (max-width: 992px) {
    padding-bottom: 100px;
    .container{
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }

  .related-content__header{
    h4{
      display: none;
      font-family: 'freightsans_promedium';
      margin-bottom: 2px;
    }

    h2{
      margin-bottom: 40px;

      @media (max-width: 992px) {
        margin-bottom: 20px;
        font-size: rem(28px);
        line-height: rem(32px);
      }
    }
  }

  .related-content__content{

    @media (max-width: 992px) {
      margin-right: -14px;
      margin-left: -14px;
    }

    .related-content__item{
      margin-bottom: 72px;

      @media (max-width: 992px) {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 48px;

        &:last-child{
          margin-right: 0;
        }
      }

      .imageItem{
        overflow: hidden;

        .img{
          transition: all 700ms ease;
        }

        &:hover{
          .img{
            transform: scale(1.05);
          }
        }
      }
      .tags{
        margin-bottom: 12px;
        display: flex;
        flex-wrap: wrap;

        @media(max-width: 992px){
          display: none;
        }

        .tag{
          background-color: #EAF2F7;
          padding: 9.5px 12px;
          margin-bottom: 2px;
          font-size: rem(18px);
          letter-spacing: 0.5px;
          border-radius: 4px;
          margin-right: 12px;
          line-height: rem(18px);
        }
      }

      .infoItem{
        margin-top: 18px;

        @media (max-width: 992px) {
          margin-top: 12px;
        }

        .dateItem{
          font-family: 'freightsans_promedium';
          font-size: rem(18px);
          line-height: rem(26px);
          min-height: rem(26px);
          letter-spacing: 0.5px;
          color: #7D7D7D;
          margin-bottom: 12px;

          @media (max-width: 992px) {
            font-size: rem(16px);
            line-height: rem(28px);
            min-height: rem(28px);
            margin-bottom: 4px;
          }
        }

        .titleItem{
          margin-bottom: 12px;
          @media (max-width: 992px) {
            font-size: rem(21px);
            line-height: rem(32px);
            margin-bottom: 4px;
          }

          a{
            text-decoration: none;
            display: inline-block;

            h6{
              @media (max-width: 992px) {
                font-size: rem(16px);
                line-height: rem(28px);
              }
            }
          }
        }

        .ingressItem{
          position: relative;
          height: 102px;
          @media (max-width: 992px) {
            height: 90px;
          }
          p{
            position: relative;
            display: inline-block;
            font-family: 'freightsans_probook';
            font-size: rem(21px);
            line-height: rem(32px);
            letter-spacing: 0.5px;
            color: #343434;
            height: 96px;
            overflow: hidden;
            @media (max-width: 992px) {
              font-size: rem(16px);
              line-height: rem(28px);
              height: 84px;
            }
          }

          div{
            display: none;
            font-family: 'freightsans_probook';
            font-size: rem(21px);
            line-height: rem(32px);
            letter-spacing: 0.5px;
            color: #343434;
            position: absolute;
            right: 0;
            bottom: 6px;
            padding-left: 10px;
            padding-right: 7px;
            background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 10%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 10%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
            @media (max-width: 992px) {
              font-size: rem(18px);
              line-height: rem(28px);
            }

            .link{
              text-decoration: none;
              font-family: 'freightsans_probook';
              font-size: rem(16px);
              line-height: rem(24px);
              letter-spacing: 0.5px;
              color: #7D7D7D;
            }
          }
        }
      }
    }

    .slick-list{
      @media (max-width: 992px) {
        padding: 0 10% 0 0;
      }

      @media (max-width: 576px) {
        margin: 0 -30px 0 0;
        padding: 0 30% 0 0;
      }

      .slick-track{
        display: flex;

        .slick-slide {
          outline: none;

          .imageItem{
            .img{
              img{
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }

  .btn-more-wrapper{
    display: flex;
    justify-content: center;
  }

  &--projects{
    .related-content__content{
      .related-content__item{
        .infoItem{
          .dateItem{
            display: none;
          }
        }
      }
    }
  }

  &--latest-news{
    @media(max-width: 992px){
      .container{
        //padding: 0 15px !important;
      }
    }

    .related-content__header{
      h4{
        display: block;
      }
    }
  }

  &--employees{
    .related-content__content{
      .related-content__item{
        .infoItem{
          .dateItem{
            display: none;
          }
        }
      }
    }
  }
}
