.text-media{
  margin-bottom: 150px;

  @media (max-width: 992px) {
    margin-bottom: 60px;
  }

  @media (max-width: 576px) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .container{
    .row{
      .content-grid{
        position: relative;
        display: flex;
        flex-direction: row;

        @media (max-width: 992px) {
          flex-direction: column-reverse;
        }

        .text-content{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 67%;
          padding: 72px 20.5% 72px 8.5%;
          background-color: $GK-Light-Green;

          z-index: 0; //GK-371 -- fixing a bug of stacking order only present in windows11

          @media (max-width: 1200px) {
            padding: 60px 14% 60px 6.5%;
          }

          @media (max-width: 992px) {
            width: inherit;
            padding: 130px 30px 40px;
          }

          h3{
            margin-bottom: 2px;
            font-family: 'freightsans_promedium';

            @media (max-width: 992px) {
              margin-bottom: 0;
              font-size: rem(16px);
              line-height: rem(28px);
            }
          }

          h2{
            margin-bottom: 24px;

            @media (max-width: 992px) {
              margin-bottom: 12px;
              font-size: rem(28px);
              line-height: rem(32px);
            }
          }

          p{
            margin-bottom: 40px;

            @media (max-width: 992px) {
              margin-bottom: 32px;
              font-size: rem(18px);
              line-height: rem(28px);
            }
          }

          button.btn{

          }
        }

        .media-content{
          display: flex;
          align-items: center;
          width: 48%;
          margin: 72px 0;

          z-index: 1; //GK-371 -- fixing a bug of stacking order only present in windows11

          @media (max-width: 1200px) {
            width: 60%;
            margin: 60px 0;
          }

          @media (max-width: 992px) {
            margin: 0 0 -110px;
            width: 100%;
            padding: 0 30px;
          }

          figure{
            margin-left: -22%;
            overflow: hidden;

            @media (max-width: 992px) {
              margin: 0;
            }

            img{
              transition: all 700ms ease;
            }

            img , video{
              width: 100%;
            }

            &:hover{
              img{
                transform: scale(1.05);
              }
            }
          }
        }

      }
    }
  }

  &--light-blue{
    .container{
      .row{
        .content-grid{
          .text-content{
            background-color: $GK-Light-Blue;
          }
        }
      }
    }
  }

  &--off-white{
    .container{
      .row{
        .content-grid{
          .text-content{
            background-color: $GK-Off-White;
          }
        }
      }
    }
  }

  &--light-gray{
    .container{
      .row{
        .content-grid{
          .text-content{
            background-color: $GK-Light-Gray;
          }
        }
      }
    }
  }

  &--img-left{
    .container{
      .row{
        .content-grid{
          flex-direction: row-reverse;

          @media (max-width: 992px) {
            flex-direction: column-reverse;
          }

          .text-content{
            padding: 72px 8.5% 72px 20.5%;

            @media (max-width: 1200px) {
              padding: 60px 6.5% 60px 14%;
            }

            @media (max-width: 992px) {
              width: inherit;
              padding: 130px 30px 40px;
            }
          }

          .media-content{
            figure{
              margin-left: 0;
              margin-right: -22%;

              @media (max-width: 992px) {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  &--youtube{
    &.text-media--img-left{
      .media-content{
        .iframe-wrapper{
          margin-left: 0;
          margin-right: -22%;

          @media (max-width: 992px) {
            margin: 0;
          }
        }
      }
    }

    .media-content{
      figure{
        position: relative;

        @media (max-width: 992px) {
          margin: 0;
        }

        &:hover{
          img{
            transform: none;
          }
        }

        .btn--video{
          position: absolute;
          top: calc(50% - 69px);
          left: calc(50% - 69px);
          z-index: 1;
        }
      }

      .iframe-wrapper{
        display: none;
        position: relative;
        width: calc(100% + 22%);
        height: 100%;
        margin-left: -22%;

        @media (max-width: 992px) {
          padding-top: calc((9 / 16) * 100%);
          margin: 0;
        }

        iframe{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          @media (max-width: 992px) {
          }
        }
      }
    }
  }
}

.career-layout {
    .privatBanner.text-media-macro.text-media-banner {
        &--lun {
            background-color: #FEE3DC;
        }

        &--varme {
            background-color: #EE5246;

            .privatBanner__wrapper {
                &--text {
                    p {
                        color: #fff;
                    }

                    a {
                        background-color: #fff;;
                        color: #EE5246;

                        &::after {
                            filter: brightness(0) saturate(100%) invert(67%) sepia(58%) saturate(6669%) hue-rotate(332deg) brightness(94%) contrast(99%);
                        }
                    }
                }
            }
        }

        &--white {
            background-color: #FFF;
        }
    }
}
