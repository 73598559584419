.split-image{
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;

  @media (max-width: 992px) {
    margin-bottom: 40px;
  }

  .image-wrapper{
    width: calc(50% - 27px);

    @media (max-width: 992px) {
      width: calc(50% - 10px);
    }

    figure{
      overflow: hidden;

      &:hover{
        img{
          transform: scale(1.05) translate(-50%, -50%);
        }
      }

      img{
        height: 100%;
        object-fit: cover;
        transition: all 700ms ease;
      }
    }
  }
}
