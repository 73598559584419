.articles{

  &__header{
    margin: 50px 0 40px 0;
    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
    &--title{
      margin-bottom: 40px;
      @media (max-width: 992px) {
        font-size: rem(44px);
        line-height: rem(48px);
        margin-bottom: 20px;
      }
    }
    &--ingress{
      opacity: 0;
      p{
        font-family: 'freightsans_probook';
        font-size: rem(28px);
        line-height: rem(36px);
        letter-spacing: 0.5px;
        color: #202020;
        @media (max-width: 992px) {
          font-size: rem(18px);
          line-height: rem(28px);
        }
      }
    }
  }

  &__separator{
    width: 100%;
    height: 1.5px;
    margin-bottom: 28px;
    background-color: #99CAB8;
    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
  }

  &__filter{
    margin-bottom: 80px;
    @media (max-width: 992px) {
      margin-bottom: 40px;
    }
  }

  &__listArticles{
    &--item{
      margin-bottom: 80px;
      @media (max-width: 992px) {
        margin-bottom: 40px;
      }
      .imageItem{
        overflow: hidden;
        .img{
          transition: all 700ms ease;
        }
        &:hover{
          .img{
            transform: scale(1.05);
          }
        }
      }
      .infoItem{
        margin-top: 18px;
        @media (max-width: 992px) {
          margin-top: 12px;
        }
        .tagItem{
          &__tag{
            background: $GK-Light-Blue;
            border-radius: 4px;
            padding: 8px 12px;
            display: inline-block;
            margin-bottom: 12px;
            text-decoration: none;
            font-family: 'freightsans_promedium';
            font-size: rem(18px);
            line-height: rem(21px);
            letter-spacing: 0.5px;
            color: #343434;
            margin-right: 12px;
            @media (max-width: 992px) {
              padding: 4px 10px;
              font-size: rem(16px);
              line-height: rem(19px);
              margin-bottom: 4px;
            }
          }
        }
        .dateItem{
          font-family: 'freightsans_promedium';
          font-size: rem(18px);
          line-height: rem(26px);
          letter-spacing: 0.5px;
          color: #7D7D7D;
          margin-bottom: 12px;
          @media (max-width: 992px) {
            font-size: rem(16px);
            line-height: rem(28px);
            margin-bottom: 4px;
          }
        }
        .titleItem{
          margin-bottom: 12px;
          @media (max-width: 992px) {
            font-size: rem(21px);
            line-height: rem(32px);
            margin-bottom: 4px;
          }
          a{
            text-decoration: none;
            display: inline-block;
          }
        }
        .ingressItem{
          position: relative;
          height: 102px;
          @media (max-width: 992px) {
            height: 90px;
          }
          p{
            display: inline-block;
            font-family: 'freightsans_probook';
            font-size: rem(21px);
            line-height: rem(32px);
            letter-spacing: 0.5px;
            color: #343434;
            position: relative;
            height: 96px;
            overflow: hidden;
            @media (max-width: 992px) {
              font-size: rem(18px);
              line-height: rem(28px);
              height: 84px;
            }
          }
          div{
            display: none;
            font-family: 'freightsans_probook';
            font-size: rem(21px);
            line-height: rem(32px);
            letter-spacing: 0.5px;
            color: #343434;
            position: absolute;
            right: 0;
            bottom: 6px;
            padding-left: 10px;
            padding-right: 7px;
            background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 10%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 10%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
            @media (max-width: 992px) {
              font-size: rem(18px);
              line-height: rem(28px);
            }
            .link{
              text-decoration: none;
              font-family: 'freightsans_probook';
              font-size: rem(21px);
              line-height: rem(32px);
              letter-spacing: 0.5px;
              color: #7D7D7D;
              @media (max-width: 992px) {
                font-size: rem(18px);
                line-height: rem(28px);
              }
            }
          }
        }
      }
    }
  }

  &__pagination{
    margin-top: 70px;
    margin-bottom: 150px;

    @media (max-width: 992px) {
      margin-top: 30px;
      margin-bottom: 100px;
    }

    &--pages{
      text-align: center;
      &.desktop{
        display: block;
        @media (max-width: 992px) {
          display: none;
        }
      }
      &.mobile{
        display: none;
        @media (max-width: 992px) {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
      }
      a{
        display: inline-block;
        text-decoration: none;
        font-family: 'freightsans_promedium';
        font-size: rem(28px);
        line-height: rem(36px);
        letter-spacing: 0.5px;
        color: #909090;
        margin-left: 24px;

        @media (max-width: 992px) {
          font-size: rem(21px);
          line-height: rem(32px);
        }

        &:first-child{
          margin: 0;
        }

        &.active {
          font-family: 'freightsans_prosemibold';
          color: $GK-Green
        }

        &.next,
        &.prev{
          border-radius: 50%;
          height: 38px;
          width: 38px;
          @media (max-width: 992px) {
            height: 32px;
            width: 32px;
            min-width: 32px;
          }
          &.disabled{
            opacity: .5;
            pointer-events: none;
          }
        }

        &.next{
          background: url('../images/icons/arrow-next.svg') no-repeat center center;
          background-color: $GK-Green;
          background-size: 10.5px;
          @media (max-width: 992px) {
            background-size: 8.84px;
          }
        }

        &.prev{
          background: url('../images/icons/arrow-prev.svg') no-repeat center center;
          background-color: $GK-Green;
          background-size: 10.5px;
          @media (max-width: 992px) {
            background-size: 8.84px;
          }
        }

      }
    }
  }

}
