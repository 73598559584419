.banner-with-ingress{
  position: relative;
  margin-top: -186px;
  // z-index: -1;
  height: 757px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 150px;

  @media(min-width: 992px) AND (max-width: 1500px){
    height: 100%;
  }

  @media(max-width: 992px){
    height: 500px;
    margin-top: -135px;
    margin-bottom: 60px;
  }

  figure{
    position: absolute;
    width: 100%;
    height: 100%;
    img{
      object-fit: cover;
      width: 100%;
      height: 757px;
      @media(max-width: 992px){
        height: 500px;
      }
    }
  }

  video{
    position: absolute;
    width: 100%;
    object-fit: cover;
    height: auto;
    @media(max-width: 1500px){
      // width: auto;
      height: 100%;
    }
  }

  &__overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    &.green{
      background-color: rgba(8,64,43, 0.8);
    }
    &.brown{
      background-color: rgba(63, 43, 38, 0.8);
    }
  }

  &__content{
    position: relative;
    padding: 0 30px;
    //top: 298px;

    @media(min-width: 992px) AND (max-width: 1500px){
      padding: 15% 0;
    }

    h1{
      margin: 0 auto 32px auto;
      max-width: 755px;
      color: #FFFFFF;
      font-weight: 600;
      font-size: rem(72px);
      line-height: rem(80px);
      letter-spacing: 0.5px;
      text-align: center;
      @media(max-width: 992px){
        font-size: rem(38px);
        line-height: rem(36px);
        margin-bottom: 28px;
      }
    }
    p{
      font-size: rem(21px);
      text-align: center;
      line-height: rem(32px);
      color: #FFFFFF;
      max-width: 530px;
      margin: 0 auto;
      @media(max-width: 992px){
        font-size: rem(16px);
        line-height: rem(28px);
      }
    }
  }

  .arrow-down{
    position: absolute;
    width: 30px;
    height: 26px;
    bottom: 32px;
    left: calc(50% - 15px);
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTMiIGhlaWdodD0iMzkiIHZpZXdCb3g9IjAgMCA1MyAzOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuMjAxOTZlLTA3IC00LjkzNzM4ZS0wNUwyLjE4ODgyZS0wNyAxMi4zNEwyNi4yMzMyIDM5TDUyLjQ4NTQgMTIuMzRMNTIuNDg1NCAtNC45MjU4NGUtMDVMMjYuMjMzMiAyNi42NTk5TDMuMjAxOTZlLTA3IC00LjkzNzM4ZS0wNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;

    @media (max-width: 992px) {
      width: 26.5px;
      height: 19.7px;
      bottom: 29px;
      left: calc(50% - 13.25px);
    }
  }

}
