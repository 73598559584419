/* .tabs{
  .contact-form{
    background-color: $GK-Off-White;
    position: relative;

    &.full{

      &.last-form{
        margin-bottom: -80px;
        z-index: -10;
      }
    }
  }
} */
.container{
  .contact-form{
    &.full{
      .container{
        @media(max-width: 991px){
          padding: 0 14px !important;
        }
      }
    }
  }

}
.contact-form{
  background-color: $GK-Off-White;
  position: relative;

  &.full{

    &.last-form{
      &:before, &:after{
        box-shadow: 0 80px #F7F6F4;
      }
    }

    &:before, &:after{
      content: "";
      position: absolute;
      background-color: $GK-Off-White;
      width: 100%;
      height: 100%;
      max-height: 100%;
      z-index: -1;
      top: 0;
    }
    &:before{
      left: -50%;
    }
    &:after{
      right: -50%;
    }
  }

  .contact-form__wrapper{
    padding: 80px 0;

    @media (max-width: 992px) {
      padding: 40px 0;
    }

    .contact-form__header{
      margin-bottom: 108px;

      @media (max-width: 992px) {
        margin-bottom: 20px;
      }

      h3{
        text-align: center;

        @media (max-width: 992px) {
          font-size: rem(28px);
          line-height: rem(32px);
          text-align: left;
        }
      }

      .p1{
        text-align: center;
        margin-top: 24px;
        max-width: 724px;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 991px) {
          text-align: left;
        }
      }
    }

    .contact-form__form{
      .input-group{

        @media (max-width: 992px) {
          margin-bottom: 20px;
        }

        input[type="text"],input[type="number"],input[type="email"],textarea{
          background-color: transparent;
        }

        button[type="submit"]{
          @extend .btn--submit;
        }

        &--gdpr{
          margin-top: -30px;

          @media (max-width: 992px) {
            margin-top: 0;
          }

          label {
            p {
              display: inline;
              font-size: rem(18px);
              line-height: rem(28px);
              color: $GK-Gray;
            }

            a {
              position: relative;
              color: #007B4E;
              padding-bottom: 2px;
              @extend a.underline-animation;

              &:not(.btn){
                background: linear-gradient(currentColor 0 0)  0 100% /var(--d, 0) 1px  no-repeat;
                -webkit-transition: all 500ms ease-in-out;
                transition: all 500ms ease-in-out;
                &:hover {
                  --d: 100%;
                }

                &:before{
                  display: none!important;
                }
              }
            }
          }

          .gdpr-label {
            display: block;
            position: relative;
            padding-left: 30px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }

          /* Hide the browser's default checkbox */
          .gdpr-label input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          /* Create a custom checkbox */
          .checkmark {
            position: absolute;
            top: 5px;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #d7d7d7;
          }

          .gdpr-label:hover input ~ .checkmark {
            background-color: #ccc;
          }

          .gdpr-label input:checked ~ .checkmark {
            background-color: $GK-Green;
          }

          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }

          /* Show the checkmark when checked */
          .gdpr-label input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the checkmark/indicator */
          .gdpr-label .checkmark:after {
            left: 6px;
            top: 3px;
            width: 8px;
            height: 12px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }

    .contact-form__tankyou{
      display: none;
      padding: 238px 0;

      @media (max-width: 992px) {
        padding: 40px 0;
      }

      h2{
        text-align: center;
        margin-bottom: 48px;

        @media (max-width: 992px) {
          font-size: rem(44px);
          line-height: rem(48px);
        }
      }

      p{
        font-family: 'freightsans_promedium';
        margin-bottom: 72px;
        text-align: center;

        @media (max-width: 992px) {
          font-size: rem(24px);
          line-height: rem(32px);
        }
      }

      button{
        display: block;
        margin: 0 auto;
      }
    }
  }
}
