.breadcrumbs{
  display: flex;
  flex-direction: row;
  margin-bottom: 86px;

  @media (max-width: 992px) {
    display: none;
  }

  li{
    list-style: none;
    font-family: 'freightsans_promedium';
    font-size: rem(16px);
    line-height: rem(28px);

    a{
      display: flex;
      align-items: center;

      &:hover{
        &::before{
          width: calc(100% - 25px);
        }

        .tooltip{
          display: block;
        }
      }

      &::after{
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        margin: 0 4px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjY2NjMgMkg5LjcxMzE2TDMuMzMzMDEgNy45OTc4NEw5LjcxMzE2IDE0SDEyLjY2NjNMNi4yODYxOSA3Ljk5Nzg0TDEyLjY2NjMgMloiIGZpbGw9IiMwMDdCNEUiLz4KPC9zdmc+Cg==');
        background-repeat: no-repeat;
        transform: rotate(180deg);
      }

      span{
        &.truncate{
          width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .tooltip{
        display: none;
        position: absolute;
        width: 200px;
        top: -10px;
        left: calc(50% - 113px);

        span{
          position: absolute;
          bottom: 0;
          padding: 8px 10px;
          border-radius: 12px;
          background: $GK-Dark;
          color: #FFFFFF;
          font-family: 'freightsans_promedium';
          font-size: rem(16px);
          line-height: rem(22px);
          text-align: center;

          &::after{
            content: '';
            position: absolute;
            bottom: -8px;
            left: calc(50% - 10px);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 8px solid $GK-Dark;
          }
        }

      }
    }

    &:last-child{
      a{
        &::after{
          display: none;
        }

        &:hover{
          &::before{
            width: 100%;
          }
        }
      }
    }
  }
}

.page.karriersiden, .page.karriersiden-article {
    .breadcrumbs-wrapper {
        background-color: #FDD4CA;
        color: $GK-Dark;
        * {color: inherit;}

        .breadcrumbs {
            margin-bottom: 0px;

            li a::after {
                filter: brightness(0) invert(16%) sepia(23%) saturate(2624%) hue-rotate(119deg) brightness(98%) contrast(94%);
            }

            a.underline-animation::before {
                border-top: 1.5px solid $GK-Dark;
            }
        }
    }
}

.container >.row >.breadcrumbs-wrapper {
    width: 100%;
}
