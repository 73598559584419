.career-part {
    .career-quote {
        background-color: #FCB8A7;
        padding: 80px 0 20px 0;
        margin-bottom: 100px;

        @media (max-width: 992px) {
            padding-top: 20px;
            margin-bottom: 60px;
        }

        &__content {
            display: block;
            align-items: flex-start;
            flex-direction: row;
            position: relative;
            margin-bottom: 48px;

            @media (max-width: 992px) {
                margin-bottom: 40px;
                padding: 0 12px;
            }

            &--item {
                width: 100%;
            }

            .gk-quote {
                margin-left: 0;
                margin-bottom: 32px;
                color: $GK-Dark;

                &::before {
                    filter: brightness(0%) invert(43%) sepia(12%) saturate(5490%) hue-rotate(328deg) brightness(96%) contrast(94%);
                }
            }
        }

        &__item {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            min-width: 100%;
        }

        &__person {
            display: flex;
            align-items: center;
            font-size: rem(18px);
            line-height: rem(24px);
            margin-left: 66px;

            @media (max-width: 992px) {
                margin-left: 30px;
            }

            * {
                font-size: inherit;
                line-height: inherit;
            }

            img {
                display: block;
                width: 58px;
                height: 58px;
                border-radius: 50%;
                margin-right: 16px;
            }

            .name {
                font-family: 'freightsans_prosemibold', sans-serif;
            }

            .description {
                font-family: 'freightsans_probook', sans-serif;
            }
        }

        &__indicator {
            position: relative;
            display: flex;
            justify-content: center;

            .indicator-wrapper {
                display: flex;
                gap: 8px;

                li {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: rgba($color: #fff, $alpha: .4);
                    cursor: pointer;

                    &.slick-active {
                        background-color: $GK-Red;
                    }
                }
            }


        }
    }
}
