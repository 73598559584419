.offices-tabs{

  &--links{
    display: block;
    border-bottom: 1.5px solid $GK-Strek-Green;
    position: relative;
    margin: 16px 0 60px 0;
    @media(max-width: 992px){
      display: flex;
      // overflow-x: auto;
      margin-bottom: 20px;
    }
    a{
      color: $GK-Dark;
      padding-bottom: 12px;
      margin-right: 100px;
      top: -14px;
      font-size: rem(24px);
      line-height: rem(32px);
      position: relative;
      font-family: 'freightsans_promedium';
      &:hover{
        text-decoration: none;
      }
      &.active{
        font-family: 'freightsans_prosemibold';
        border-bottom: 4px solid $GK-Green;
      }

      @media(max-width: 992px){
        font-size: rem(16px);
        line-height: rem(28px);
        padding-bottom: 16px;
        margin-right: 28px;
        top: 1px;
        white-space: nowrap;
      }
    }
  }

  .tabs{
    display: none;

      .slideshow{
        .container{
          padding: 0 !important;
          @media(max-width: 992px){
            padding: 0 15px !important;
          }
        }
      }


  }

  .office-info{
    @media(max-width: 992px){
      margin-bottom: 40px;
    }
    &__wrapper{
      padding: 0;
      &:after{
        padding-bottom: 100px;
        border-bottom: 1.5px solid $GK-Strek-Green;
        width: 100%;
        margin: 0 auto;
        content: "";
        @media(max-width: 992px){
          padding-bottom: 40px;
        }
      }
    }
  }

  .map-info{
    margin-top: 40px;
    .contact-card{
      margin-bottom: 105px;
      @media(max-width: 992px){
        padding: 0 30px;
        margin-bottom: 60px;
      }
    }
    @media(max-width: 992px){
      .office-info{
        .container{
          padding: 0 15px !important;
        }
      }
    }


    // .map-info__wrapper{
    //   margin-bottom: 100px;
    //   &:after{
    //     padding-bottom: 100px;
    //     border-bottom: 1.5px solid $GK-Strek-Green;
    //     width: calc(100% - 48px);
    //     margin: 0 auto;
    //     content: "";
    //   }

    //   .map-info__wrapper--address{
    //     display: flex;
    //     flex-wrap: wrap;
    //     .map-info__wrapper--address-item{
    //       flex-basis: 50%;
    //       margin-bottom: 88px;
    //       h5{
    //         margin-bottom: 8px;
    //       }
    //       span{
    //         font-size: rem(21px);
    //         line-height: rem(32px);
    //       }
    //     }
    //   }
    // }
  }

  .map-accordion, .map-full{
    .filterBtn{
      display: none;
      margin-bottom: 50px;
      @media(max-width: 992px){
        display: block;
      }
    }
    .projects__filter--options{
      display: none;
      &.active{
        display: block;
      }
    }
    .text-accordion__wrapper{
      margin-bottom: 150px;
      @media(max-width: 992px){
        margin-bottom: 40px;
      }
    }
  }



}
