@function calcGrid($size, $value) {
  $result: map-get($container-max-widths, $size);
  $result2: $result - $value;
  @return $result2;
}

.office-locator{
  margin-bottom: 152px;

  @media(max-width: 1280px){
    margin-top: 115px;
    margin-bottom: 60px;
  }

  &__wrapper{
    position: relative;
    padding: 100px 0 91px 121px;
    display: flex;
    max-width: calcGrid("xl", 48px);

    @media(min-width: 1440px) and (max-width: 1550px){
      max-width: calcGrid("xl", 100px);
    }

    @media(max-width: 1280px){
      flex-direction: column-reverse;
      padding: 124px 60px;
    }

    &--content{
      max-width: 490px;
      @media(max-width: 1280px){
        max-width: 100%;
      }

      &:before{
        content: "";
        background-color: $GK-Light-Green;
        position: absolute;
        left: 0;
        top: 0;
        width: 965px;
        //max-width: 965px;
        height: 100%;
        display: block;
        z-index: -1;

        @media(min-width: 992px) and (max-width: 1280px){
          //top: 115px;
          height: calc(100% - 250px);
          top: 175px;
          width: 100%;
        }

        @media(max-width: 992px){
          width: 100%;
          height: calc(100% - 175px);
          top: 175px;
        }

        @media(max-width: 768px){
          height: 100%;
          top: 0;
        }
      }

      h4{
        margin-bottom: 12px;
        font-family: 'freightsans_promedium';
        font-size: rem(44px);
        line-height: rem(48px);
        letter-spacing: 0.5px;
        color: $GK-Dark;
        font-weight: 600;

        @media(max-width: 1280px){
          font-size: rem(28px);
          line-height: rem(36px);
        }
      }

      p{
        margin-bottom: 28px;
        font-family: 'freightsans_probook';
        font-size: rem(21px);
        line-height: rem(32px);
        letter-spacing: 0.5px;
        color: $GK-Black;

        @media(max-width: 1280px){
          font-size: rem(18px);
          line-height: rem(28px);
        }

      }

      .btn-container{
        @media(max-width: 1280px){
          text-align: center;
          margin: 10px auto 40px auto;
        }
      }
    }

    &--image{
      margin-left: 121px;

      figure{

        @media(max-width: 1280px){
          margin-top: -250px;
        }

        @media(max-width: 992px){
          margin-top: -115px;
        }

        img{
          max-width: 100%;
          width: 100%;
        }
      }

      @media(max-width: 1280px){
        margin-left: 0;
        margin-bottom: 20px;
      }
    }

  }

  .dropdown__wrapper{
    margin-bottom: 28px;

    .dropdown{
      border: 1px solid $GK-Green;

      .options{
        background-color: #FFFFFF;
        border: 1px solid $GK-Green;
      }

    }
    .styledSelect{
      background-color: #FFFFFF;
      height: 56px;
      @media(max-width: 992px){
        height: 50px;
      }
    }
    &+button{
      margin-top: 20px;
      height: 52px;
      font-size: rem(18px);
      line-height: rem(28px);
      @media(max-width: 1280px){
        width: auto;
        margin: 12px auto 40px auto;
      }
    }

    &.map-icon{
      .styledSelect{
        padding-left: 56px;
        &::before{
          background-image: url('../images/icons/map-check.svg');
          content: "";
          background-size: cover;
          width: 22px;
          height: 22px;
          display: inline-block;
          position: absolute;
          left: 20px;
          top: 17px;
          @media(max-width: 1280px){
            top: 12px;
          }
        }
      }
    }

  }

}
