.newsletter{
    margin: 80px 0;

    .htmlArea-article-content &{
        margin-left: -17%;

        @media (max-width: 991px) {
            margin-left: 0;
        }
    }

    &__wrapper{
        padding: 60px 14% 76px 14.5%;
        background-color: $GK-Light-Green;

        @media (max-width: 1199px) {
            padding-left: 32px;
            padding-right: 32px;
        }

        @media (max-width: 767px) {
            padding: 30px 20px;
        }
    }

    &__heading {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;

        @media (max-width: 767px) {
            gap: 16px;
        }

        h3{
            @media (max-width: 767px) {
                font-size: rem(36px) !important;
            }
        }

        * {
            margin-bottom: 0 !important;
        }
    }

    &__form{
        .input-group{
            margin-bottom: 0;

            .custom-checkbox{
                .label-text{
                    p{
                        font-family: 'freightsans_promedium';
                        font-size: rem(18px);
                        line-height: rem(28px);
                        letter-spacing: 0.5px;
                        color: #424242;
                    }

                    a {
                        // rule added because there cannot be classes on links in Enonic
                        @extend a.underline-animation;

                        &:not(.btn) {
                           background: linear-gradient(currentColor 0 0) 0 100% /var(--d, 0) 1px no-repeat;
                           -webkit-transition: all 500ms ease-in-out;
                           transition: all 500ms ease-in-out;

                           &:hover {
                              --d: 100%;
                           }

                           &:before {
                              display: none !important;
                           }
                        }
                     }

                    & > *{
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    &__input-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 52px;
        margin-bottom: 24px !important;

        @media (max-width: 1199px) {
            gap: 32px;
        }

        @media (max-width: 767px) {
            gap: 16px;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__input{

    }

    &__submit{
        margin: 0 !important;
    }

    &--part{
        @media (min-width: 992px) {
            margin: 64px 0 116px;
        }

        .newsletter__heading{
            @media (min-width: 1200px) {
                margin-bottom: 0;
            }

            @media (min-width: 768px) {
                gap: 20px;
            }

            p{
                font-size: rem(21px);
            }
        }

        .newsletter__wrapper{
            @media (min-width: 1200px) {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
                padding: 60px 5% 60px 5%;
                column-gap: 40px;
            }
        }

        .newsletter__form{
            @media (min-width: 992px) {
                width: 100%;
                max-width: 603px;
            }
        }

        .newsletter__input-wrapper{
            @media (min-width: 992px) {
                margin-bottom: 20px !important;
            }
        }
    }
}
