.career-layout .career-article {

    &__content {
        margin: 0 auto;
        // GK-345: Add spacing after content
        padding-bottom: 80px;

        // GK-345: Apply styling from GK-265 - BEGIN
        .typography-h5 {
            margin-bottom: 8px;

            @media (max-width: 992px) {
                margin-bottom: 5px;
                font-size: rem(24px);
                line-height: rem(32px);
            }
        }

        ul {
            list-style: none;

            li {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 8px;
                    height: 8px;
                    background-color: $GK-Green;
                    top: 14px;
                    left: -20px;

                    @media (max-width: 992px) {
                        top: 11px;
                    }
                }
            }
        }

        ul,
        ol {
            margin-bottom: 8px;
            padding-left: 20px;

            li {
                font-size: rem(21px);
                line-height: rem(32px);
                letter-spacing: 0.5px;
            }
        }

        .no-empty-p {
            margin-bottom: 30px;

            @media (max-width: 992px) {
                // margin-bottom: 5px;
                font-size: rem(24px);
                line-height: rem(32px);
            }
        }

        // GK-345: Apply styling from GK-265 - END

        p.ingress {
            font-size: rem(28px);
            line-height: rem(36px);
            margin-bottom: 44px;

            @media (max-width: 992px) {
                font-size: rem(18px);
                line-height: rem(28px);
                margin-bottom: 20px;
            }
        }

        .subtitle {
            font-size: rem(24px);
            line-height: rem(32px);
            font-family: 'freightsans_prosemibold', sans-serif;
            color: $GK-Dark;
            margin-bottom: 12px;

            @media (max-width: 992px) {
                font-size: rem(18px);
                line-height: rem(28px);
            }
        }

        p {
            font-size: rem(21px);
            line-height: rem(32px);

            @media (max-width: 992px) {
                font-size: rem(18px);
                line-height: rem(28px);
            }

            &+p {
                margin-top: 32px;

                @media (max-width: 992px) {
                    margin-top: 28px;
                }
            }

            &+.gobi-box--desktop,
            &+.gobi-box--mobile {
                margin-top: 60px;

                @media (max-width: 992px) {
                    margin-top: 40px;
                }
            }

            &+.gobi-carousel {
                margin-top: 60px;

                @media (max-width: 992px) {
                    margin-top: 40px;
                }
            }

            &+.subtitle {
                margin-top: 60px;

                @media (max-width: 992px) {
                    margin-top: 40px;
                }
            }

            &+.gk-color-box {
                margin-top: 60px;

                @media (max-width: 992px) {
                    margin-top: 40px;
                }
            }

            &+ul,
            &+ol {
                margin-top: 32px;

                @media (max-width: 992px) {
                    margin-top: 28px;
                }
            }
        }

        &>a,
        &>.no-empty-p a,
        &>.gk-color-box a {
            display: inline-block;
            margin-bottom: 32px;
            font-size: rem(21px);
            line-height: rem(32px);
            color: $GK-Green;

            @extend .underline-animation;

            &:not(.btn) {
                background: linear-gradient(currentColor 0 0) 0 100% /var(--d, 0) 1px no-repeat;
                -webkit-transition: all 500ms ease-in-out;
                transition: all 500ms ease-in-out;

                &:hover {
                    --d: 100%;
                }

                &:before {
                    display: none !important;
                }
            }

            @media (max-width: 992px) {
                margin-bottom: 28px;
                font-size: rem(18px);
                line-height: rem(28px);
            }
        }

        p,
        li,
        p.no-empty-p {
            a {
                margin: 0;
            }
        }

        .gobi-box {
            display: flex;
            align-items: center;
            padding: 31px 95px 31px 34px;
            margin-bottom: 60px;

            @media (max-width: 992px) {
                display: none;
            }

            .gobi-container {
                margin-right: 28px;
            }

            &__content {
                .title {
                    font-family: 'freightsans_prosemibold', sans-serif;
                    color: $GK-Dark;
                    font-size: rem(21px);
                    line-height: rem(32px);
                    margin-bottom: 12px;
                }

                .text {
                    font-size: rem(21px);
                    line-height: rem(32px);
                    margin-bottom: 0px;
                }
            }

            &--mobile {
                display: none;
                padding: 28px 30px;
                text-align: center;
                overflow: hidden;
                margin-bottom: 40px;

                @media (max-width: 992px) {
                    display: block;
                }

                @media (max-width: 576px) {
                    margin-right: -30px;
                    margin-left: -30px;
                }

                .title {
                    font-family: 'freightsans_prosemibold', sans-serif;
                    color: $GK-Dark;
                    font-size: rem(18px);
                    line-height: rem(28px);
                    margin-bottom: 10px; //24px on figma, but 10px here because the gobi element has 14px margin
                }

                .text {
                    font-size: rem(16px);
                    line-height: rem(18px);
                    margin-top: 10px; //24px on figma, but 10px here because the gobi element has 14px margin
                }

                .gobi-container {
                    margin: 0;
                }
            }
        }

        &.gobi-textful,
        &.gobi-collection-textful {
            .gobi-box {
                padding: 31px 34px;
                &.gobi-left {
                    flex-direction: row-reverse;

                    .gobi-container {
                        margin-right: -18px;
                        margin-left: 0px;
                    }

                    .gobi-box__content {
                        padding-left: 10px;
                        padding-right: 12px;
                    }
                }
                &.gobi-right {
                    flex-direction: row;

                    .gobi-container {
                        margin-right: 0px;
                        margin-left: -18px;
                    }

                    .gobi-box__content {
                        padding-right: 10px;
                        padding-left: 12px;
                    }
                }
            }
        }

        .gobi-carousel {
            position: relative;
            text-align: center;
            padding: 45px 34px 31px 34px;
            margin-bottom: 60px;

            @media (max-width: 992px) {
                padding: 28px 0;
                // overflow: hidden;
                margin-bottom: 40px;
            }

            @media (max-width: 576px) {
                margin-right: -30px;
                margin-left: -30px;
            }

            .title {
                font-family: 'freightsans_prosemibold', sans-serif;
                color: $GK-Dark;
                font-size: rem(21px);
                line-height: rem(32px);
                margin-bottom: 12px;
            }

            .carousel-wrapper {
                position: relative;
                display: block;
                gap: calc(40px - 28px);
                align-items: center;
                justify-content: center;
                overflow: hidden;

                @media (max-width: 992px) {
                    overflow: visible;
                }

            }

            #article-gobi-previous {
                z-index: 1;
                position: absolute;
                top: 50%;
                left: -19px;
                width: 40px;
                height: 40px;
                flex-shrink: 0;
                background-color: transparent;
                background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' transform='rotate(-180 20 20)' fill='%23F16F64'/%3E%3Cpath d='M24.3333 14L21.3802 14L15 19.9978L21.3802 26L24.3333 26L17.9532 19.9978L24.3333 14Z' fill='white'/%3E%3C/svg%3E");
                border: none;

                @media (max-width: 992px) {
                    display: none;
                }

                &.slick-disabled {
                    cursor: not-allowed;
                    opacity: .5;
                }
            }

            #article-gobi-next {
                z-index: 1;
                position: absolute;
                top: 50%;
                right: -19px;
                width: 40px;
                height: 40px;
                flex-shrink: 0;
                rotate: 180deg;
                background-color: transparent;
                background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' transform='rotate(-180 20 20)' fill='%23F16F64'/%3E%3Cpath d='M24.3333 14L21.3802 14L15 19.9978L21.3802 26L24.3333 26L17.9532 19.9978L24.3333 14Z' fill='white'/%3E%3C/svg%3E");
                border: none;

                @media (max-width: 992px) {
                    display: none;
                }

                &.slick-disabled {
                    cursor: not-allowed;
                    opacity: .5;
                }
            }
        }

        /* GK-385 - Fixing problem with text-media macro on career article */

        @media (min-width: 992px) {
            .text-media-macro {
                &.privatBanner {
                    margin-inline: -97px;

                    .privatBanner__wrapper {
                        .privatBanner__wrapper--text {
                            flex-direction: column;
                            align-items: flex-start;
                            margin: 0;
                            padding: 40px;

                            a.btn {
                                margin: 20px 0 0 0;
                            }
                        }
                    }
                }
            }

            .text-media-macro.privatBanner,
            .text-media {
                // width: calc((3/2)*100% + 24px);
                // margin-left: -50%;
                margin-top: 50px;
                margin-bottom: 50px;

                .container {
                    padding: 0 !important;
                }

                p {
                    min-width: 50%;
                }

                .privatBanner__wrapper .privatBanner__wrapper--text {
                    margin-left: 10px;

                    a {
                        margin: 0 10px;
                        min-width: max-content;
                    }
                }

                figure {
                    min-height: 100%;
                    align-self: stretch;

                    img {
                        min-height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        /* GK-385 END */

        /** GK-403 - Begin **/

        .imageWrapper {
            width: 100%;

            figure {
                overflow: hidden;
                margin-bottom: 60px;

                @media (max-width: 992px) {
                    margin-bottom: 40px;
                }

                &:hover {
                    img {
                        transform: scale(1.05);
                    }
                }

                img {
                    width: 100%;
                    transition: all 700ms ease;
                }

                figcaption {
                    margin-top: 18px;
                    font-size: rem(16px);
                    line-height: rem(28px);
                }

                &.editor-align-left {
                    margin-right: 30px;
                }

                &.editor-align-right {
                    margin-left: 30px;
                }

                &.editor-align-left,
                &.editor-align-right {
                    width: 50%;
                    margin-top: 10px;
                    margin-bottom: 40px;

                    @media (max-width: 992px) {
                        float: none !important;
                        width: 100% !important;
                        margin: 0;
                        margin-bottom: 30px;
                    }
                }

                &.editor-align-justify {
                    margin-left: -118px;

                    @media (max-width: 1448px) {
                        margin-left: -17%;
                    }

                    @media (max-width: 992px) {
                        margin-left: 0;
                    }

                    img {
                        width: 100%;
                    }

                    figcaption {
                        text-align: center;
                    }
                }

                &.editor-align-center {
                    margin-bottom: 40px !important;

                    @media (max-width: 992px) {
                        margin-bottom: 30px !important;
                    }

                    img {
                        margin: 0 auto;
                        display: block;
                    }

                    figcaption {
                        text-align: center;
                    }
                }
            }
        }

        .videoWrapper {
            margin-bottom: 80px;
            margin-left: -118px;

            @media (max-width: 1448px) {
                margin-left: -17%;
            }

            @media (max-width: 992px) {
                margin-bottom: 60px;
                margin-left: 0;
            }

            figure {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                button {
                    position: absolute;
                    top: calc(50% - 69px);
                    left: calc(50% - 69px);
                    z-index: 1;
                }
            }

            video {
                display: none;
                width: 100%;
            }

            &--youtube {
                .iframe-wrapper {
                    display: none;
                    position: relative;
                    padding-top: calc((9 / 16) * 100%);
                    height: 0;

                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        /** GK-403 - End **/
    }
}

.career-layout {
    .gk-color-box {
        padding: 45px 48px;
        background-color: #FEE3DC;
        margin-bottom: 80px;
        margin-top: 60px;

        @media (max-width: 992px) {
            margin-bottom: 60px;
            padding: 28px;
        }

        header {
            font-family: 'freightsans_prosemibold', sans-serif;
            color: $GK-Dark;
            margin-bottom: 12px;
            font-size: rem(21px);
            line-height: rem(32px);

            @media (max-width: 992px) {
                font-size: rem(18px);
                line-height: rem(28px);
            }
        }

        p {
            font-size: rem(21px);
            line-height: rem(32px);

            @media (max-width: 992px) {
                font-size: rem(16px);
                line-height: rem(28px);
            }

            &+p {
                margin-top: 32px;

                @media (max-width: 992px) {
                    margin-top: 28px;
                }
            }
        }

        ul {
            list-style: none;

            li {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 8px;
                    height: 8px;
                    background-color: $GK-Green;
                    top: 14px;
                    left: -20px;

                    @media (max-width: 992px) {
                        top: 11px;
                    }
                }
            }
        }
    }
}
