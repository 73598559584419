.gk-quote{
  position: relative;
  margin-left: -124px;
  padding: 52px 0 0 65px;
  color: $GK-Green;
  font-family: 'freightsans_promedium';
  font-size: rem(38px);
  line-height: rem(48px);
  letter-spacing: 0.5px;

  @media (max-width: 992px) {
    margin-top: 10px;
    margin-left: 0;
    padding: 26px 0 0 30px;
    font-size: rem(24px);
    line-height: rem(32px);
  }

  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 103px;
    height: 102px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAzIiBoZWlnaHQ9IjEwMiIgdmlld0JveD0iMCAwIDEwMyAxMDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMCkiPgo8cGF0aCBkPSJNMTAzLjE0MSAxOS40NDcyTDgzLjY5NzkgMC4wMDQ1NzYyMkwwLjM2MTE1IC0wLjY2Nzc5MUwxLjAwMzY5IDgyLjY5ODhMMjAuNDQ2MyAxMDIuMTQxTDE5LjgwMzggMTguNzc0OEwxMDMuMTQxIDE5LjQ0NzJaIiBmaWxsPSIjRTZGMkVEIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMTAzIiBoZWlnaHQ9IjEwMiIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: 992px) {
      width: 56px;
      height: 56px;
    }
  }
}
