/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 14, 2019 */

// Light
// @font-face {
//   font-family: 'freightsans_prolight';
//   src: url('../fonts/freigsanprolig-webfont.woff2') format('woff2'),
//        url('../fonts/freigsanprolig-webfont.woff') format('woff'),
//        url('../fonts/freigsanprolig-webfont.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// Light Italic
// @font-face {
//   font-family: 'freightsans_prolight_italic';
//   src: url('../fonts/freigsanproligit-webfont.woff2') format('woff2'),
//        url('../fonts/freigsanproligit-webfont.woff') format('woff'),
//        url('../fonts/freigsanproligit-webfont.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// Book
@font-face {
  font-family: 'freightsans_probook';
  src: url('../fonts/freigsanprobook-webfont.woff2') format('woff2'),
       url('../fonts/freigsanprobook-webfont.woff') format('woff'),
       url('../fonts/freigsanprobook-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// Book Italic
// @font-face {
//   font-family: 'freightsans_probook_italic';
//   src: url('../fonts/freigsanprobookit-webfont.woff2') format('woff2'),
//        url('../fonts/freigsanprobookit-webfont.woff') format('woff'),
//        url('../fonts/freigsanprobookit-webfont.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// Medium
@font-face {
  font-family: 'freightsans_promedium';
  src: url('../fonts/freigsanpromed-webfont.woff2') format('woff2'),
       url('../fonts/freigsanpromed-webfont.woff') format('woff'),
       url('../fonts/freigsanpromed-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// Medium Italic
// @font-face {
//   font-family: 'freightsans_promedium_italic';
//   src: url('../fonts/freigsanpromedit-webfont.woff2') format('woff2'),
//        url('../fonts/freigsanpromedit-webfont.woff') format('woff'),
//        url('../fonts/freigsanpromedit-webfont.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// Semibold
@font-face {
  font-family: 'freightsans_prosemibold';
  src: url('../fonts/freigsanprosem-webfont.woff2') format('woff2'),
       url('../fonts/freigsanprosem-webfont.woff') format('woff'),
       url('../fonts/freigsanprosem-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// Bold
@font-face {
  font-family: 'freightsans_probold';
  src: url('../fonts/freigsanprobold-webfont.woff2') format('woff2'),
       url('../fonts/freigsanprobold-webfont.woff') format('woff'),
       url('../fonts/freigsanprobold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// SERIF FONT

// Light

@font-face {
  font-family: 'freightbig_prolight';
  src: url('../fonts/serif/FreightBigProLight-Regular.woff2') format('woff2'),
  url('../fonts/serif/FreightBigProLight-Regular.woff') format('woff'),
  url('../fonts/serif/FreightBigProLight-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

//Book

@font-face {
  font-family: 'freightbig_probook';
  src: url('../fonts/serif/FreightBigProBook-Regular.woff2') format('woff2'),
  url('../fonts/serif/FreightBigProBook-Regular.woff') format('woff'),
  url('../fonts/serif/FreightBigProBook-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'freightbig_promedium';
  src: url('../fonts/serif/FreightBigProMedium-Regular.woff2') format('woff2'),
  url('../fonts/serif/FreightBigProMedium-Regular.woff') format('woff'),
  url('../fonts/serif/FreightBigProMedium-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'freightbig_prosemibold';
  src: url('../fonts/serif/FreightBigProSemibold-Regular.woff2') format('woff2'),
  url('../fonts/serif/FreightBigProSemibold-Regular.woff') format('woff'),
  url('../fonts/serif/FreightBigProSemibold-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'freightbig_probold';
  src: url('../fonts/serif/FreightBigProBold-Regular.woff2') format('woff2'),
  url('../fonts/serif/FreightBigProBold-Regular.woff') format('woff'),
  url('../fonts/serif/FreightBigProBold-Regular.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'freightbig_problack';
  src: url('../fonts/serif/FreightBigProBlack-Regular.woff2') format('woff2'),
  url('../fonts/serif/FreightBigProBlack-Regular.woff') format('woff'),
  url('../fonts/serif/FreightBigProBlack-Regular.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
