.macro-icons{
  margin-bottom: 40px;
  h5{
    font-size: rem(24px);
    line-height: rem(32px);
    letter-spacing: 0.5px;
    margin-bottom: 28px !important;
    display: block;
  }
  &__wrapper{
    &--item{
      display: flex;
      margin-bottom: 44px;

      @media(max-width: 768px){
        flex-direction: column;
        margin-bottom: 32px;
      }

      &-icon{
        background-color: rgba(247, 246, 244, 1);
        width: 169px;
        min-width: 169px;
        flex-basis: 169px;
        height: 169px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 40px;

        @media(max-width: 768px){
          margin-bottom: 12px;
          width: 112px;
          min-width: 112px;
          flex-basis: 112px;
          height: 112px;
        }

        img{
          height: 64px;
          width: auto;

          @media(max-width: 768px){
            height: 38px;
          }

        }
      }

      &-content{
        width: calc(100% - (40px + 169px));

        @media(max-width: 768px){
          width: 100%;
        }

        h6{
          font-size: rem(21px) !important;
          line-height: rem(32px) !important;
          letter-spacing: 0.5px;
          margin-bottom: 6px !important;
          display: block;
          @media(max-width: 768px){
            margin-bottom: 4px !important;
            font-size: rem(16px) !important;
            line-height: rem(28px) !important;
          }
        }
        p{
          font-size: rem(18px) !important;
          line-height: rem(28px) !important;
          letter-spacing: 0.5px;
          margin-bottom: 0 !important;
          @media(max-width: 768px){
            font-size: rem(16px) !important;
            line-height: rem(28px) !important;
          }
        }
      }
    }
  }
}
