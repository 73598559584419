.career-part {
    .instagram {
        position: relative;
        margin-bottom: 150px;
        padding-block: 140px 107px;

        background: linear-gradient(180deg, rgba(254, 227, 220, 1) 40%, rgba(255, 255, 255, 0) 40%);

        @media (max-width: 1200px) {
            background: linear-gradient(180deg, rgba(254, 227, 220, 1) 50%, rgba(255, 255, 255, 0) 50%);
            padding-top: 80px;
        }

        @media (max-width: 992px) {
            background: linear-gradient(180deg, rgba(254, 227, 220, 1) 62%, rgba(255, 255, 255, 0) 62%);
            padding-top: 40px;
            margin-bottom: 100px;
        }

        @media (max-width: 768px) {
            background: linear-gradient(180deg, rgba(254, 227, 220, 1) 67%, rgba(255, 255, 255, 0) 67%);
        }

        .container .row {
            flex-wrap: nowrap;

            @media (max-width: 992px) {
                flex-direction: column-reverse;
            }

            .col-lg-6 {
                @media (min-width: 1449px) {
                    margin-inline: -48px;
                }
                @media (max-width: 1448px) {
                    margin-inline: -32px;
                }

                @media (max-width: 576px) {
                    margin-inline: 0;
                }
            }
        }

        &__wrapper {
            position: relative;
            background: #fff;

            @media (min-width: 1449px) {
                padding: 40px 48px;
                width: calc(100% + (48px * 2));
            }

            @media (min-width: 1201px) {
                top: -40px;
            }

            @media (max-width: 1448px) {
                padding: 28px 32px;
                width: calc(100% + (32px * 2));
            }

            @media (max-width: 576px) {
                padding: 12px 16px;
                width: 100%;
            }
        }

        &__info {
            margin-bottom: 40px;
            color: $GK-Dark;

            @media (max-width: 992px) {
                margin-bottom: 12px;
            }

            .header {
                display: flex;
                margin-bottom: 28px;

                @media (max-width: 992px) {
                    margin-bottom: 16px;
                }

                &__icon {
                    margin-right: 20px;
                    figure {
                        width: 88px;
                        height: 88px;
                        object-fit: cover;
                        border-radius: 50%;
                        overflow: hidden;

                        img {
                            width: 100%;
                        }
                    }
                }

                &__info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 4px;
                    margin-bottom: 28px;

                    .name {
                        font-size: rem(21px);
                        line-height: rem(32px);
                        font-family: 'freightsans_prosemibold', sans-serif;

                        @media (max-width: 992px) {
                            font-size: rem(14px);
                            line-height: rem(20px);
                        }
                    }

                    .details {
                        display: flex;
                        font-size: rem(21px);
                        line-height: rem(32px);
                        font-family: 'freightsans_probook', sans-serif;

                        @media (max-width: 992px) {
                            font-size: rem(14px);
                            line-height: rem(20px);
                        }

                        span {
                            font-family: 'freightsans_prosemibold', sans-serif;

                        }

                        & > *:not(:last-child) {
                            margin-right: 20px;
                        }
                    }
                }

                &__content {
                    .title {
                        font-size: rem(21px);
                        line-height: rem(32px);
                        font-family: 'freightsans_prosemibold', sans-serif;

                        @media (max-width: 992px) {
                            font-size: rem(14px);
                            line-height: rem(20px);
                        }
                    }

                    .text {
                        font-size: rem(21px);
                        line-height: rem(32px);
                        font-family: 'freightsans_probook', sans-serif;

                        @media (max-width: 992px) {
                            font-size: rem(14px);
                            line-height: rem(20px);
                        }
                    }
                }
            }
        }

        &__grid {
            position: relative;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: 20px;
            width: 100%;

            @media (max-width: 992px) {
                gap: 9px;
            }

            img {
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &__item {
            position: relative;
            padding-top: calc((1 / 1) * 100%);
            overflow: hidden;
            background-color: #fff;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
                transform: translate(-50%, -50%);
                // max-width: 100% !important;
                // max-height: 100% !important;
            }
        }

        &__text {
            @media (min-width: 993px) {
                margin-left: 32px;
            }

            @media (max-width: 992px) {
                margin-bottom: 32px;
            }

            h2 {
                color: $GK-Dark;
                font-family: 'freightbig_prosemibold', serif;
                font-size: rem(44px);
                line-height: rem(48px);
                margin-bottom: 40px;

                @media (max-width: 992px) {
                    font-size: rem(32px);
                    line-height: rem(36px);
                    margin-bottom: 32px;
                }
            }

            .button {
                font-family: 'freightsans_prosemibold', sans-serif;

                @media (max-width: 992px) {
                    font-family: 'freightsans_promedium', sans-serif;
                }

                display: inline-block;
                background-color: transparent;
                padding: 10px 36px; //2 px of difference to match the design using internal borders
                font-size: rem(18px);
                line-height: rem(28px);
                color: $GK-Dark;
                border: 2px solid $GK-Dark;
                border-radius: 40px;
                text-decoration: none;

                @media (max-width: 992px) {
                    padding: 10px 24px;
                }
            }
        }
    }
}
