.ourProjects{

  margin-bottom: 150px;
  @media (max-width: 992px) {
    margin-bottom: 60px;
  }

  &__subTitle{
    font-family: 'freightsans_promedium';
    font-size: rem(18px);
    line-height: rem(28px);
    color: $GK-Dark;
    letter-spacing: 0.5px;
    margin-bottom: 2px;
    @media (max-width: 992px) {
      font-size: rem(16px);
      margin-bottom: 0;
    }
  }

  &__title{
    margin-bottom: 40px;
    @media (max-width: 992px) {
      font-size: rem(28px);
      line-height: rem(36px);
      margin-bottom: 20px;
    }
  }

  &__slider{
    width: 100%;
    max-width: calc(93% - 22px);
    @media (max-width: 992px) {
      max-width: 100%;
      z-index: 2;
    }
    &--item{
      .content{
        height: 670px;
        @media (max-width: 992px) {
          height: 315px;
        }
        @media (max-width: 768px) {
          height: 220px;
        }
        .img{
          height: 100%;
          width: auto;
          img{
            height: 100%;
            width:auto;
          }
        }
      }
    }
  }

  &__box{
    background: $GK-Light-Blue;
    padding: 56px 73px;
    width: 100%;
    max-width: 435px;
    float: right;
    margin-top: calc(-670px + 60px);
    z-index: 1;
    position: relative;

    @media (max-width: 992px) {
      margin-top: 0;
      float: none;
      max-width: 100%;
      padding: 20px 0 40px 0;
      &:after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
	      transform: translateX(-50%);
        height: calc(100% + 110px);
        width: 100vw;
        background: $GK-Light-Blue;
        z-index: -1;
        pointer-events: none;
      }
    }


    &--links{
      margin-bottom: 40px;
      @media (max-width: 992px) {
        overflow: hidden;
        margin-bottom: 32px;
      }
      .link{
        margin-bottom: 18px;
        position: relative;
        border-bottom: none;
        display: block;
        pointer-events: none;
        @media (max-width: 992px) {
          margin-bottom: 0;
          padding-bottom: 8px;
          width: 50%;
          float: left;
          overflow: hidden;
          &:nth-child(even){
            padding-left: 12px;
          }
          &:nth-child(odd){
            padding-right: 12px;
          }

          &:after {
            content: "";
            position: absolute;
            top: 0;
            background: -moz-linear-gradient(left,  rgba(234,242,247,0) 0%, rgba(234,242,247,1) 50%, rgba(234,242,247,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(234,242,247,0) 0%,rgba(234,242,247,1) 50%,rgba(234,242,247,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(234,242,247,0) 0%,rgba(234,242,247,1) 50%,rgba(234,242,247,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            right: 0;
            height: calc(100% + 6px);
            width: 30px;
            -webkit-transition: all 500ms ease-in-out;
            transition: all 500ms ease-in-out;
            pointer-events: none;
          }

        }
        &:last-child{
          margin: 0;
        }
        a{
          text-decoration: none;
          font-family: 'freightsans_promedium';
          font-size: 32px;
          line-height: 44px;
          letter-spacing: 0.5px;
          font-size: rem(32px);
          line-height: rem(44px);
          color: $GK-Dark;
          transition: all 400ms;
          position: relative;
          display: inline-block;
          pointer-events: all;

          @media (max-width: 992px) {
            font-size: rem(21px);
            line-height: rem(32px);

            &:before {
              content: "";
              position: absolute;
              height: 1px;
              top: calc(100% + 2px);
              border-top: 1.5px solid #007B4E;
              left: 1px;
              width: 0;
              -webkit-transition: all 500ms ease-in-out;
              transition: all 500ms ease-in-out;
            }
          }

          &:after{
            content: "";
            background: url("../images/icons/icon-arrow-crcle.svg") no-repeat;
            position: absolute;
            right: -60px;
            top: 50%;
            margin-top: -22px;
            width: 44px;
            height: 44px;
            opacity: 0;
            pointer-events: none;
            transition: all 400ms;
            @media (max-width: 992px) {
              display: none;
            }
          }
          &:hover{
            color: $GK-Green;
          }
        }
        &.active{
          a{
            color: $GK-Green;
            padding-left: 15px;
            @media (max-width: 992px) {
              padding-left: 0;
            }
            // &:hover{
            //   padding-left: 0;
            // }
            @media (max-width: 992px) {
              padding-left: 0;
              &:before{
                width: 100%;
              }
            }
            &:after{
              opacity: 1;
              pointer-events: all;
            }
          }
        }
      }
    }

    &--btn{
      @media (max-width: 992px) {
        .btn{
          padding: 10px 24px;
          font-size: rem(18px);
          line-height: rem(28px);
        }
        text-align: center;
      }
    }

  }



}
