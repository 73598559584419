.page.karriersiden .career-layout {
    .main-header:not(.fixed) {
        background-color: $GK-Red;
        margin-bottom: 0;
        padding-bottom: 76px;

        @media (max-width: 768px) {
            padding-bottom: 0px;
        }

        .header {
            padding-top: 42px;
            padding-bottom: 0px;

            &__logo {
                @media (max-width: 992px) {
                    width: 47px;
                }

                img {
                    filter: brightness(0) invert(1);
                }
            }


            &__buttons {

                &--menu,
                &--search {
                    color: #fff;

                    &::before {
                        filter: brightness(0) invert(1);
                    }
                }
            }

            &__options {
                a {
                    color: #fff;

                    &.active {
                        text-decoration-thickness: 2px;
                    }

                    &:hover {
                        text-decoration-color: #fff;
                        text-decoration-thickness: 2px;
                    }
                }
            }
        }
    }
}

.career-layout .header-content {
    position: relative;
    background-color: $GK-Red;
    color: #fff;

    * {
        color: inherit;
    }

    &__wrapper {
        padding-top: 80px;
        padding-bottom: 168px;

        @media (max-width: 768px) {
            padding-top: 60px;
            padding-bottom: 32px;
        }
    }

    &__title {
        h1 {
            font-size: rem(72px);
            line-height: rem(80px);
            margin-bottom: 28px;
            font-family: 'freightbig_probold';

            @media(max-width: 992px) {
                font-size: rem(44px);
                line-height: rem(48px);
                margin-bottom: 20px;
            }
        }
    }

    &__intro {
        font-size: rem(21px);
        line-height: rem(32px);
        font-family: 'freightsans_probook', sans-serif;

        @media(max-width: 992px) {
            font-size: rem(18px);
            line-height: rem(28px);
        }

        * {
            font-size: inherit;
            color: inherit;
            line-height: inherit;
        }
    }

    &__buttons {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        gap: 12px;

        @media (max-width: 1200px) {
            flex-direction: column;
        }

        @media (max-width: 992px) {
            margin-top: 32px;
        }

        a {
            color: $GK-Dark;
            font-size: rem(18px);
            line-height: rem(28px);
            font-family: 'freightsans_prosemibold', sans-serif;
            background-color: #fff;
            padding: 12px 38px;
            border: none;
            border-radius: 40px;
            white-space: nowrap;

            @media (max-width: 1200px) {
                width: 100%;
                text-align: center;
            }

            @media (max-width: 768px) {
                padding: 10px 0;
            }

            &:hover {
              text-decoration: none;
            }
        }
    }

    &__media {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0px;
        bottom: -100px;
        width: 50%;

        @media (max-width: 768px) {
            position: relative;
            top: 0;
            left: -24px;
            right: -24px;
            bottom: 0;
            width: calc(100vw + 24px);
        }

        img, iframe, video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }

        .downArrows {
            cursor: pointer;
            position: absolute;
            right: 68px;
            bottom: 52px;
            width: 88px;
            rotate: 180deg;

            @media (max-width: 768px) {
                bottom: 20px;
                right: 30px;
                width: 36px;
            }

            img {
                filter: brightness(0) invert(1);
                animation: arrowsAnim 3s linear infinite;

                &:nth-child(1) {
                    animation-delay: 0.4s;
                }

                &:nth-child(2) {
                    animation-delay: 0.2s;
                }

                &:nth-child(3) {
                    animation-delay: 0s;
                }
            }
        }

        &--video,
        &--youtube {
            .downArrows {
                img {
                    animation: none;
                }
            }
        }

        .btn {
            position: absolute;
        }
    }

    &--left {
        .header-content__media {
            right: 0;
        }
    }
}

.page.karriersiden-article .career-layout {
    .main-header:not(.fixed) {
        background-color: #FDD4CA;
        margin-bottom: 0;
        padding-bottom: 102px;

        @media (max-width: 992px) {
            padding-bottom: 50px;
        }

        .header {
            padding: 42px 0 0 0;
        }
    }
}

.page.karriersiden-subpage .career-layout, .page.karriersiden-joblistings .career-layout {
    .main-header:not(.fixed) {
        background-color: #FDD4CA;
        margin-bottom: 0;
        padding-bottom: 0;

        @media (max-width: 992px) {
            padding-bottom: 0;
        }

        .header {
            padding: 42px 0 0 0;
        }
    }
}

.career-layout .header-article {
    background-color: #FDD4CA;
    padding: 80px 0;
    margin-bottom: 80px;

    @media (max-width: 992px) {
        padding: 32px 0 40px 0;
        margin-bottom: 20px;
    }

    &__title {
        h1 {
            font-size: rem(60px);
            line-height: rem(72px);
            font-family: 'freightbig_probold', serif;
            text-align: left;

            @media (max-width: 992px) {
                font-size: rem(32px);
                line-height: rem(40px);
            }
        }
    }

    &__image {
        margin-top: 60px;

        @media (max-width: 992px) {
            margin-top: 28px;

            .img.aspectRatio--1400x724 {
                padding-top: calc((238 / 315) * 100%);
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &.featured-image {
            position: relative;
            margin-bottom: 34px;

            @media (max-width: 992px) {
                margin-bottom: 20px;
            }

            .figure--fade-left {
                animation-delay: 0.8s;

                img,
                video {
                    animation-delay: 1.1s;
                }
            }

            &--video {
                display: flex;

                video {
                    flex: 1 1 auto;
                    overflow: auto;
                }

                figure {
                    display: flex;

                    video {
                        opacity: 0;
                        width: 100%;
                        height: auto;
                    }
                }
            }

            &--youtube {
                figure {
                    .btn--video {
                        position: absolute;
                        top: calc(50% - 69px);
                        left: calc(50% - 69px);
                        z-index: 1;

                        @media (max-width: 992px) {
                            width: 118px;
                            height: 118px;
                            top: calc(50% - 56px);
                            left: calc(50% - 56px);
                        }

                    }
                }

                .iframe-wrapper {
                    display: none;
                    position: relative;
                    //padding-bottom: 56.25%; /* 16:9 */
                    padding-top: calc((689 / 1400) * 100%);
                    height: 0;

                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            figcaption {
                margin-top: 18px;
                font-size: rem(16px);
                line-height: rem(28px);

                &.text--fade-left {
                    opacity: 0;
                    animation-delay: 1.1s;
                }
            }
        }
    }
}

.career-layout .header-subpage, .career-layout .header-joblistings {
    background-color: #FDD4CA;
    padding: 156px 0 80px 0;

    @media (max-width: 992px) {
        padding: 60px 0 40px 0;
    }

    &__title {
        h1 {
            font-size: rem(88px);
            line-height: rem(94px);
            font-family: 'freightbig_probold', serif;

            @media (max-width: 992px) {
                font-size: rem(44px);
                line-height: rem(48px);
            }
        }
    }

    &__ingress {
        margin-top: 40px;

        @media (max-width: 992px) {
            margin-top: 20px;
        }

        p {
            font-family: 'freightsans_probook', sans-serif;
            font-size: rem(24px);
            line-height: rem(32px);

            @media (max-width: 992px) {
                font-size: rem(18px);
                line-height: rem(28px);
            }
        }
    }
}
