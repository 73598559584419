.related-projects{
  margin-bottom: 80px;

  h3{
    font-size: rem(44px);
    line-height: rem(48px);
    letter-spacing: 0.5px;
    margin-bottom: 40px;
    @media(max-width: 992px){
      margin-bottom: 20px;
      font-size: rem(28px);
      line-height: rem(36px);
    }
  }

  .related-content__item{
    margin-bottom: 80px;
    @media(max-width: 992px){
      margin-bottom: 40px;
      &:first-child{
        margin-bottom: 80px;
      }
    }
  }

  .mt-auto{
    margin-top: auto;
  }

  .imageItem{
    //overflow: visible !important;
    a{
      .img{
        overflow: hidden;
        margin-bottom: 24px;
        transition: all 700ms ease;
        img{
          //transition: transform 0.5s ease;
          //transform: none;
          &:hover{
            //transform: scale(1.1);
          }
        }

      }
    }
  }

  .infoItem{
    margin-top: 0 !important;
    .titleItem{
      a{
        &:hover{
          text-decoration: none;
        }
        h6{

        }
      }
    }
  }

  .tags{
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;

    @media(max-width: 992px){
      //display: none;
    }

    .tag{
      background-color: #EAF2F7;
      margin-bottom: 2px;
      padding: 9.5px 12px;
      font-size: rem(18px);
      letter-spacing: 0.5px;
      border-radius: 4px;
      margin-right: 12px;
      line-height: rem(20px);
      @media(max-width: 992px){
        font-size: rem(16px);
        line-height: rem(18px);
      }
    }
  }
}
