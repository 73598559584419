.quotes{
  margin-bottom: 100px;

  @media (max-width: 992px) {
    margin-bottom: 80px;

    margin-left: -30px;
    margin-right: -30px;
  }

  .quotes__header{
    margin-bottom: 40px;

    @media (max-width: 992px) {
      margin-bottom: 20px;
    }

    p{
      color: $GK-Dark;
      margin-bottom: 2px;

      @media (max-width: 992px) {
        margin-bottom: 0;
      }
    }

    h3{
      @media (max-width: 992px) {
        font-size: rem(28px);
        line-height: rem(36px);
      }
    }
  }

  .quotes__card{
    background-color: $GK-Off-White;
    margin-bottom: 50px;
    padding: 56px 44px;

    @media (max-width: 992px) {
      margin-bottom: 20px;
      padding: 30px 20px;
    }

    &:hover{
      .author{
        figure{
          img{
            transform: scale(1.05);
          }
        }
      }
    }

    .quotation-marks{
      width: 33px;
      height: 23px;
      margin-bottom: 28px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAzMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMxLjAzMiAxNy4yNTYyQzI2LjY0IDE1LjMxMjIgMjUuMjcyIDE0LjAxNjIgMjUuMjcyIDEyLjE0NDJDMjUuMjcyIDkuNjk2MiAyOC4wMDggNi45NjAyIDMyLjQ3MiAzLjc5MjJMMjguOCAwLjQwODIwM0MyMi45NjggMy44NjQyIDE3LjM1MiA4LjQ3MjIgMTcuMzUyIDE0LjgwODJDMTcuMzUyIDE5LjEyODIgMjAuNzM2IDIyLjY1NjIgMjQuNzY4IDIyLjY1NjJDMjguMjk2IDIyLjY1NjIgMzAuNjcyIDIwLjM1MjIgMzEuMDMyIDE3LjI1NjJaTTEzLjY4IDE3LjI1NjJDOS4yODggMTUuMzEyMiA3LjkyIDE0LjAxNjIgNy45MiAxMi4xNDQyQzcuOTIgOS42OTYyIDEwLjY1NiA2Ljk2MDIgMTUuMTIgMy43OTIyTDExLjQ0OCAwLjQwODIwM0M1LjYxNiAzLjg2NDIgMCA4LjQ3MjIgMCAxNC44MDgyQzAgMTkuMTI4MiAzLjM4NCAyMi42NTYyIDcuNDE2IDIyLjY1NjJDMTAuOTQ0IDIyLjY1NjIgMTMuMzIgMjAuMzUyMiAxMy42OCAxNy4yNTYyWiIgZmlsbD0iIzA4NDAyQiIvPgo8L3N2Zz4K');
      background-repeat: no-repeat;
      background-size: contain;

      @media (max-width: 992px) {
        width: 25px;
        height: 18px;
        margin-bottom: 18px;
      }
    }

    .quote-text{
      padding-bottom: 32px;
      margin-bottom: 28px;
      border-bottom: 1.5px solid $GK-Strek-Green;

      @media (max-width: 992px) {
        padding-bottom: 20px;
        margin-bottom: 20px;
      }

      p{
        margin-bottom: 20px;

        @media (max-width: 992px) {
          font-size: rem(16px);
          line-height: rem(28px);
        }

        &:last-child{
          margin-bottom: 0;
        }
      }
    }

    .author{
      display: flex;
      align-items: center;

      figure{
        width: 58px;
        min-width: 58px;
        height: 58px;
        margin-right: 30px;
        border-radius: 100%;
        overflow: hidden;

        @media (max-width: 1200px) {
          margin-right: 18px;
        }

        @media (max-width: 992px) {
          width: 50px;
          min-width: 50px;
          height: 50px;
        }

        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 700ms ease;
        }
      }

      .info{
        p{
          color: $GK-Dark;

          @media (max-width: 992px) {
            font-size: rem(18px);
            line-height: rem(28px);
          }
        }

        .name{
          font-family: 'freightsans_prosemibold';
        }
      }
    }
  }
}
