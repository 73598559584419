.image-carousel{
  position: relative;
  margin-bottom: 60px;

  @media(max-width: 992px){
    margin-bottom: 40px;
  }

  .image-carousel__arrows{
    position: absolute;
    display: flex;
    justify-content: space-between;
    //top: 36%;
    left: -36.5px;
    width: calc(100% + 73px);
    z-index: 1;
    pointer-events: none;

    @media(max-width: 1480px){
      left: 0px;
      width: 100%;
      padding: 0 20px;
    }

    @media(max-width: 1200px){
      //top: 26%;
      left: -36.5px;
      width: calc(100% + 73px);
      padding: 0;
    }

    @media(max-width: 992px){
      display: none;
    }

    button{
      width: 73px;
      height: 73px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuMzMzMzMgMjhMMTIuMjM5NyAyOEwyNSAxNi4wMDQzTDEyLjIzOTcgMy45OTk5OUw2LjMzMzM0IDMuOTk5OTlMMTkuMDkzNiAxNi4wMDQzTDYuMzMzMzMgMjhaIiBmaWxsPSIjRTZGMkVEIi8+Cjwvc3ZnPgo=');
      background-repeat: no-repeat;
      background-position: center center;
      background-color: $GK-Green;
      border: none;
      outline: none;
      border-radius: 100%;
      pointer-events: all;

      &.prev{
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    }
  }

  .slick-list{
    overflow: hidden;

    @media(max-width: 992px){
      padding:0 20% 0 0;
    }

    @media(max-width: 576px){
      margin-right: -30px;
    }

    .slick-track{
      display: flex;

      .image-carousel__slide{
        display: inline-block;
        outline: none;

        @media(max-width: 992px){
          margin-right: 20px;
        }

        figure{
          overflow: hidden;

          &:hover{
            img{
              transform: scale(1.05) translate(-50%, -50%);
            }
          }

          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 700ms ease;
          }

        }

        figcaption{
          margin-top: 18px;
          text-align: center;

          @media(max-width: 992px){
            display: none;
          }
        }
      }
    }
  }
}
