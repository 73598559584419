.intro{
  margin-bottom: 72px;

  .container{
    .row{
      padding-top: 120px;
      padding-bottom: 120px;
      border-bottom: 1.5px solid #99CAB8;
      margin: 0;

      .col-lg-4{
        padding-left: 0;
      }
      .col-lg-7{
        padding-right: 0;
      }

      @media(max-width: 992px){
        padding: 40px 0 20px 0;
        .col-12{
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }

  .title{
    font-size: rem(44px);
    line-height: rem(48px);
    letter-spacing: 0.5px;
    color: $GK-Dark;
    margin-bottom: 12px;
    @media(max-width: 992px){
      font-size: rem(28px);
      line-height: rem(36px);
    }
  }

  .htmlArea{
    p, ul, ol, li{
      font-size: rem(28px);
      line-height: rem(40px);
      letter-spacing: 0.5px;
      @media(max-width: 992px){
        font-size: rem(18px);
        line-height: rem(28px);
      }
    }
  }
}
