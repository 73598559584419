.kpi{
  margin-bottom: 150px;

  @media (max-width: 992px) {
    margin-bottom: 60px;
  }

  .kpi__wrapper{
    padding-top: 80px;
    background-color: $GK-Light-Green;

    @media (max-width: 992px) {
      padding-top: 40px;
    }

    .kpi__header{
      margin-bottom: 80px;

      @media (max-width: 992px) {
        margin-bottom: 40px;
      }

      h3{
        text-align: center;

        @media (max-width: 992px) {
          font-size: rem(28px);
          line-height: rem(36px);
        }
      }
    }

    ul.kpi__list{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 -25px;

      li{
        margin: 0 25px 80px;
        flex: 1;

        @media (max-width: 992px) {
          margin: 0 25px 40px;
        }

        .value{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 194px;
          height: 194px;
          margin-bottom: 18px;
          border-radius: 100%;
          border: 1.2px solid $GK-Green;
          color: $GK-Dark;
          font-size: rem(36px);
          line-height: rem(48px);
          margin-right: auto;
          margin-left: auto;

          @media (max-width: 992px) {
            width: 116px;
            height: 116px;
            font-size: rem(28px);
            line-height: rem(36px);
          }
        }

        p{
          color: $GK-Dark;
          text-align: center;

          @media (max-width: 992px) {
            font-size: rem(18px);
            line-height: rem(28px);
          }
        }
      }
    }
  }
}

.career-part {
    .kpi-career {
      background-color: $GK-Red;
      padding: 80px 0;
      margin-bottom: 100px;

      @media (max-width: 992px) {
        margin-bottom: 60px;
        padding: 40px 0;
      }

      &__title {
        margin-bottom: 60px;
        color: #fff;
        font-family: 'freightbig_probold', serif;
        text-align: center;
        font-size: rem(60px);
        line-height: rem(72px);
        font-weight: 700;

        @media (max-width: 992px) {
          font-size: rem(32px);
          line-height: rem(36px);
          margin-bottom: 32px;
        }

        * {
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
          color: inherit;
          font-weight: inherit;
        }
      }

      &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 80px;

        @media (max-width: 992px) {
          flex-direction: column;
          gap: 20px;
        }
      }

      &__item {
        width: 220px;
        height: 220px;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media (max-width: 992px) {
          width: 160px;
          height: 160px;
        }

        .value {
          font-family: 'freightbig_problack', serif;
          color: $GK-Dark;
          font-size: rem(52px);
          line-height: rem(60px);
          margin-bottom: 8px;
          font-weight: 900;

          @media (max-width: 992px) {
            font-size: rem(40px);
            line-height: rem(44px);
          }
        }

        .description {
          color: $GK-Dark;
          font-family: 'freightsans_promedium', sans-serif;
          font-size: rem(18px);
          line-height: rem(20px);

          @media (max-width: 992px) {
            font-size: rem(16px);
          }
        }
      }
    }
}

