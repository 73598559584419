.career-part {
    .text-and-media {
        margin-bottom: 100px;

        @media (max-width: 992px) {
            margin-bottom: 60px;
        }

        @media (max-width: 576px) {
            margin-left: -30px;
            margin-right: -30px;
        }

        &__content {
            display: flex;
            align-items: center;
            position: relative;

            @media (max-width: 992px) {
                flex-direction: column-reverse;
            }
        }

        &__text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            background-color: #FEE3DC;
            width: 60.5%;
            padding: 120px 20.8% 120px 8.7%;

            @media (max-width: 1200px) {
                padding: 72px 14% 72px 6.5%;
            }

            @media (max-width: 992px) {
                width: 100%;
                padding: 130px 30px 40px;
            }


            h2 {
                font-family: 'freightbig_probold', serif;
                font-size: rem(44px);
                line-height: rem(48px);
                margin-bottom: 24px;

                @media (max-width: 992px) {
                    font-size: rem(32px);
                    line-height: rem(36px);
                    margin-bottom: 12px;
                }
            }

            h3 { // https://seeds.atlassian.net/browse/GK-416
                font-size: rem(18px);
                line-height: rem(28px);
                margin-bottom: 2px;
                font-family: 'freightbig_probold', serif;

                @media (max-width: 992px) {
                    margin-bottom: 0;
                    font-size: rem(16px);
                  }
            }

            p {
                font-size: rem(21px);
                line-height: rem(32px);

                @media (max-width: 992px) {
                    font-size: rem(18px);
                    line-height: rem(28px);
                }
            }

            .button {
                font-family: 'freightsans_prosemibold', sans-serif;

                @media (max-width: 992px) {
                    font-family: 'freightsans_promedium', sans-serif;
                }

                display: inline-block;
                background-color: transparent;
                padding: 10px 36px; //2 px of difference to match the design using internal borders
                font-size: rem(18px);
                line-height: rem(28px);
                color: $GK-Dark;
                border: 2px solid $GK-Dark;
                border-radius: 40px;
                text-decoration: none;
                text-align: center;

                margin-top: 40px;

                @media (max-width: 992px) {
                    padding: 10px 24px;
                    margin-top: 32px;
                }
            }
        }

        &__media {
            position: absolute;
            right: 0;
            padding: 0 24px;

            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 992px) {
                position: relative;
                width: 100%;
                padding: 0 30px;
                margin: 0;
                margin-bottom: calc(-130px + 20px);
            }

            figure {

                @media (max-width: 992px) {
                    margin: 0;
                }
            }

            .btn.btn--video {
                position: absolute;
                flex-shrink: 0;
                z-index: 1;
            }
        }

        &--lun {
            .text-and-media__text {
                background-color: #FEE3DC;
            }
        }

        &--jord {
            .text-and-media__text {
                background-color: #F0EDEB;
            }
        }

        &.text-and-media--media-right {
            .text-and-media__content {
                @media (min-width: 993px) {
                    flex-direction: row-reverse;
                }
            }

            .text-and-media__content {
                .text-and-media__media {
                    @media (min-width: 993px) {
                        right: auto;
                        left: 0;
                    }
                }
            }

            .text-and-media__text {
                @media (min-width: 1201px) {
                    padding: 120px 8.7% 120px 20.8%;
                }

                @media (min-width: 993px) and (max-width: 1200px) {
                    padding: 72px 6.5% 72px 14%;
                }
            }
        }
    }

    .text-and-media-small {
        margin-bottom: 100px;

        @media (max-width:992px) {
            margin-bottom: 60px;
        }

        &__wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;

            @media (max-width: 992px) {
                flex-direction: column;
            }
        }

        &__image {
            width: 33.3333%;

            @media (max-width: 992px) {
                width: 100%;
                margin-bottom: 20px;
            }
        }

        &__content {
            display: flex;
            padding: 24px 64px;
            width: 66.6666%;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 1200px) {
                padding: 18px 48px;
            }

            @media (max-width: 992px) {
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
                padding: 0 0 40px 0;
            }
        }

        &__text {
            width: 66.6666%;

            h2 {
                font-family: 'freightbig_promedium', serif;
                font-size: rem(32px);
                line-height: rem(40px);
            }

            @media (max-width: 992px) {
                width: 100%;
                margin-bottom: 32px;
                font-size: rem(28px);
                line-height: rem(36px);
            }
        }

        &__button {
            .btn.btn--push.btn--chevron {
                white-space: nowrap;

                background-color: transparent;
                color: $GK-Dark;
                border: 2px solid $GK-Dark;

                &::after {
                    filter: brightness(0%) invert(19%) sepia(44%) saturate(673%) hue-rotate(106deg) brightness(97%) contrast(101%);
                    ;
                }
            }
        }

        &--lun {
            .text-and-media-small__wrapper {
                background-color: #FEE3DC;
            }

            .container {
                @media (max-width: 992px) {
                    background: linear-gradient(0deg, rgba(254, 227, 220, 1) 80%, rgba(254, 227, 220, 0) 80%);
                }
            }
        }

        &--jord {
            .text-and-media-small__wrapper {
                background-color: #F0EDEB;
            }

            .container {
                @media (max-width: 992px) {
                    background: linear-gradient(0deg, rgba(240, 237, 235, 1) 80%, rgba(240, 237, 235, 0) 80%);
                }
            }
        }
    }
}

.header-subpage + .career-part > .text-and-media {
    margin-top: 80px;

    @media (max-width: 992px) {
        margin-top: 40px;
    }
}

.careerSubpageRegion {
    .career-part {
        &:first-child {
            .text-and-media {
                margin-top: 80px;

                @media (max-width: 992px) {
                    margin-top: 40px;
                }
            }
        }
    }
}

.career-article__content {
    .career-part {
        .text-and-media, .text-and-media-small {
            margin-inline: -45%;
        }
    }
}
