.read-more{
  margin-bottom: 44px;

  @media (max-width: 992px) {
    margin-bottom: 40px;
  }
  .read-more__wrapper{
    p{

    }

    .read-more__item{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 40px;

      @media (max-width: 1200px) {
        flex-direction: column;
        flex-wrap: nowrap;
      }

      &:last-child{
        margin-bottom: 0;
      }

      .figure-wrapper{
        flex-basis: 40%;

        @media (max-width: 1200px) {
          flex-basis: auto;
          margin-bottom: 12px;
        }

        figure{
          position: relative;
          overflow: hidden;

          &:hover{
            img{
              transform: scale(1.05) translate(-50%, -50%);
            }
          }

          img{
            height: 100%;
            object-fit: cover;
            transition: all 700ms ease;
          }
        }
      }

      .text-content{
        flex-basis: calc(60% - 44px);
        margin-left: 44px;

        @media (max-width: 1200px) {
          flex-basis: auto;
          margin-left: inherit;
        }

        h6{
          margin-bottom: 32px;

          @media (max-width: 1200px) {
            margin-bottom: 17px;
            font-size: rem(18px);
            line-height: rem(28px);
          }
        }

        a{
          @media (max-width: 1200px) {
            display: flex;
            font-size: rem(18px);
            line-height: rem(28px);

            &::after{
              width: 28px !important;
              height: 28px !important;
              background-size: contain;
            }
          }
        }
      }
    }
  }
}
