.contact-locator{
  background-color: $GK-Off-White;
  padding: 110px 0;
  margin-bottom: 80px;

  @media(max-width: 992px){
    padding: 49px 0;
  }

  h2{
    font-size: rem(32px);
    color: $GK-Dark;
    margin-bottom: 44px;
    line-height: rem(40px);
    font-family: 'freightsans_prosemibold';

    @media(max-width: 992px){
      margin-bottom: 20px;
    }
  }

  .locatorForm{
    display: flex;

    @media(max-width: 992px){
      flex-direction: column-reverse;
    }

    &__form{
      flex-basis: 603px;
      padding-right: 168px;

      @media(max-width: 992px){
        flex-basis: 100%;
        padding-right: 0;
      }

      .form-group{
        margin-bottom: 32px;
        position: relative;

        @media(max-width: 992px){
          margin-bottom: 20px;
        }

        label{
          display: block;
          margin-bottom: 8px;
          color: $GK-Dark;
          font-size: rem(18px);
          line-height: rem(28px);

          @media(max-width: 992px){
            margin-bottom: 4px;
          }
        }
        input, select, .styledSelect{
          width: 100%;
          border: 1px solid $GK-Strek-Green;
          height: 56px;
          padding: 0 20px;
          font-size: rem(21px);
          background-color: transparent;

          @media(max-width: 992px){
            font-size: rem(18px);
          }

          &::placeholder{
            font-size: rem(21px);
            color: $GK-Gray;
          }

          &.active{
            color: $GK-Dark;
          }

          &:focus-visible{
            border-color: $GK-Dark;
            outline-color: $GK-Green;
          }
        }

        .styledSelect, select{
          -moz-appearance: none;
          -webkit-appearance: none;
          background-image: url("../images/icons/arrow-down-item-menu.svg");
          background-repeat: no-repeat;
          background-size: 15px 17px;
          background-position: calc(100% - 21px) center;
          color: $GK-Dark;
          &:after{
            display: none;
          }
        }

        .styledSelect{
          background-color: transparent;
          padding: 12px 44px 12px 20px;
          font-family: 'freightsans_probook', sans-serif;
        }

        .options{
          background-color: #FFFFFF;
          //border: 1.5px solid #99cab8;
          border: 1.5px solid $GK-Strek-Green;
          top: 55px;
        }

      }

      input.input-search-city{
        padding-left: 56px;
        background-image: url("../images/icons/map-check.svg");
        background-size: 22px 22px;
        background-repeat: no-repeat;
        background-position: 20px center;
        background-color: transparent;

        &.active{
          background-image: url("../images/icons/map-check-active.svg");
        }

      }

      //.input-search-city__box,
      .autocomplete-items{
        //display: none;
        position: absolute;
        width: 100%;
        background-color: #FFFFFF;
        border: 1px solid $GK-Green;
        z-index: 1;
        div{
          padding: 10px 20px;
          color: $GK-Dark;
          font-size: rem(21px);
          cursor: pointer;

          &:hover{
            background-color: $GK-Green;
            color: #FFFFFF;
            text-decoration: none;
          }


        }
      }

      .btn-container{
        margin-top: 48px;

        @media(max-width: 992px){
          margin-top: 40px;
          text-align: center;
        }
      }
    }

    &__image{
      flex-basis: calc(100% - 603px);

      @media(max-width: 992px){
        flex-basis: 100%;
        margin-bottom: 20px;
      }

      figure{
        margin: 0;
        img{
          max-width: 100%;
        }
      }
    }

  }

  .articles__pagination--pages{
    margin-top: 48px;
  }

}

.locatorForm__results{
  flex-basis: calc(100% - 603px);
  display: none;

  @media(max-width: 992px){
    flex-basis: 100%;
    //display: block;
    width: 100%;
  }

  h6{
    color: $GK-Dark;
    font-size: rem(18px);
    line-height: rem(28px);
    margin-bottom: 12px;
  }

  &--wrapper{
    &-item{
      border: 1.5px solid $GK-Strek-Green;
      padding: 34px 40px;
      display: flex;
      margin-bottom: 20px;

      @media(max-width: 992px){
        flex-direction: column;
        padding: 24px 28px;
      }

      div{
        flex-basis: 30%;
        margin-right: 40px;

        @media(max-width: 992px){
          margin-bottom: 12px;
          margin-right: 0;
          &:nth-child(2){
            margin-bottom: 24px;
            .title{
              display: none;
            }
          }
        }

        span{
          font-size: rem(18px);
          line-height: rem(28px);
          color: $GK-Black;
          &.title{
            font-size: rem(16px);
            color: $GK-Gray;
            line-height: rem(28px);
            margin-bottom: 4px;
            display: block;
          }
          &.name{
            font-size: rem(18px);
            color: $GK-Green;
            line-height: rem(28px);
            display: block;
            font-family: 'freightsans_probold';
          }
          a{
            margin-top: 12px;
            color: $GK-Green;
          }
        }
      }

    }
  }
}

.contact-locator__modal{
  .modal-dialog{
    max-width: 918px;

    @media (max-width: 992px) {
      max-width: 100%;
      height: 100%;
      margin: 0;
    }

    .modal-content{
      border: none;
      border-radius: 0;
      background-color: $GK-Off-White;

      @media (max-width: 992px) {
        height: 100%;
      }

      button.close{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
        width: 64px;
        margin-left: auto;
        background: none;
        border: none;
        outline: none;

        @media (max-width: 992px) {
          min-height: 75px;
          height: auto;
        }

        span{
          font-size: 50px;
          color: $GK-Dark;
        }
      }

      .modal-body{
        display: flex;
        flex-direction: row;
        padding: 0px 73px 70px 45px;
        background-color: $GK-Off-White;
        overflow-x: auto;

        @media (max-width: 992px) {
          flex-direction: column;
          align-items: center;
          padding: 0 30px 100px;
        }

        .contact-info{
          width: 40%;
          padding-right: 93px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          @media (max-width: 992px) {
            width: 100%;
            padding: 0 0 40px;
            margin-bottom: 40px;
            border-bottom: 1.5px solid $GK-Strek-Green;
          }

          h3.name{
            display: none;
            margin-bottom: 14px;

            @media (max-width: 992px) {
              display: block;
            }
          }

          figure{
            width: 168px;
            height: 168px;
            margin-bottom: 28px;

            img{
              width: 100%;
              height: 100%;
              border-radius: 100%;
              object-fit: cover;
            }
          }
          .role{
            margin-bottom: 18px;
          }

          a{
            color: $GK-Black;
            margin-bottom: 5px;

            &:last-child{
              margin-bottom: 0;
            }
          }
        }

        .contact-bio{
          width: 60%;

          @media (max-width: 992px) {
            width: 100%;
          }

          h3.name{
            margin-bottom: 18px;
            @media (max-width: 992px) {
              display: none;
            }
          }

          p{
            margin-bottom: 30px;

            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .locatorForm__results{
    display: block;
  }
}
