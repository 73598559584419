.modal-video{
  width: 100%;
  background-color: #222222;

  @media(max-width: 1400px){
    padding: 80px 30px 0 30px;
  }

  @media(max-width: 992px){
    padding: 80px 0 0 0;
    overflow: auto;
  }

  .modal-dialog{
    max-width: 1400px;
    height: calc(100vh - 3.5rem);
    display: flex;
    align-items: center;

    @media(max-width: 1400px){
      height: auto;
    }

    @media(max-width: 1400px){
      max-width: 100%;
      padding: 30px;
    }

    @media(max-width: 1400px){
      height: auto;
    }

    .modal-content{
      //padding: 150px 140px;
      background-color: transparent;
      border: 0;
      position: relative;
      .close{
        position: absolute;
        top: -60px;
        right: -30px;
        width: 30px;
        height: 30px;
        background-color: transparent;
        border: 0;
        box-shadow: none;

        @media(max-width: 992px){
          right: 0;
          img{
            width: 18px;
            height: 18px;
          }
        }

        span{
          color: #FFFFFF;
        }
      }

      .modal-body{
        display: flex;
        padding: 0;

        @media(max-width: 992px){
          flex-direction: column;
        }

        &__image{
          max-width: 917px;
          margin-right: 48px;
          width: 100%;

          @media(max-width: 1400px){
            max-width: 100%;
          }

          @media(max-width: 992px){
            display: none;
          }

          video{
            width: 100%;
          }
        }
        &__content{
          width: calc(100% - 917px);
          display: flex;
          flex-direction: column;
          justify-content: center;

          @media(max-width: 1400px){
            width: 50%;
          }
          @media(max-width: 992px){
            width: 100%;
          }

          .modal-social{
            display: none;
            margin-top: 0;
            @media(max-width: 992px){
              display: block;
              margin-bottom: 20px;
              a, span{
                font-size: rem(18px);
                line-height: rem(28px);
              }
            }
          }

          h2{
            color: #FFFFFF;
            font-size: rem(38px);
            line-height: rem(48px);
            margin-bottom: 18px;
            letter-spacing: 0.5px;
            @media(max-width: 992px){
              font-size: rem(32px);
              line-height: rem(40px);
            }
          }
          .date{
            letter-spacing: 0.5px;
            margin-bottom: 28px;
            color: #FFFFFF;
            opacity: 0.5;
            font-size: rem(18px);
            line-height: rem(28px);
            @media(max-width: 992px){
              margin-bottom: 20px;
              font-size: rem(16px);
              line-height: rem(28px);
              margin-bottom: 2px;
            }
          }
          p{
            letter-spacing: 0.5px;
            color: #FFFFFF;
            font-size: rem(21px);
            line-height: rem(32px);
            @media(max-width: 992px){
              font-size: rem(18px);
            line-height: rem(28px);
            }
          }
          .video-mobile{
            display: none;
            margin-bottom: 20px;
            @media(max-width: 992px){
              display: block;
            }
          }
        }
      }

      .modal-social{
        margin-top: 20px;

        @media(max-width: 992px){
          display: none;
        }

        a, span{
          color: #FFFFFF;
          font-size: rem(18px);
          line-height: rem(28px);
          letter-spacing: 0.5px;
        }

        a{
          text-decoration: underline;
          padding: 5px 0;
          &:after{
            content: "";
            height: 25px;
            border-right: 1px solid #FFFFFF;
            display: inline-block;
            margin: 0 10px;
            top: 5px;
            position: relative;
          }

          &:last-child{
            &:after{
              display: none;
            }
          }
        }
      }
    }
  }
  .iframe-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: block;
    padding-top: calc((390 / 740) * 100%);
    > iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      min-height: 100%;
      width: 100%;
    }

    .iframe-no-fullscreen{
      transform: translate(-50%, -50%);
      object-fit: cover;
    }

  }

  .iframe-wrapper.iframe-wrapper-mobile{
    @media (min-width: 992px) {
      padding-top: 0;
    }
  }
}

//body class
.modal-open{

}
