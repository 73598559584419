.projects{

  &__header{
    margin: 50px 0 40px 0;
    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
    &--title{
      margin-bottom: 40px;
      @media (max-width: 992px) {
        font-size: rem(44px);
        line-height: rem(48px);
        margin-bottom: 20px;
      }
    }
    &--ingress{
      opacity: 0;
      p{
        font-family: 'freightsans_probook';
        font-size: rem(28px);
        line-height: rem(36px);
        letter-spacing: 0.5px;
        color: #202020;
        @media (max-width: 992px) {
          font-size: rem(18px);
          line-height: rem(28px);
        }
      }
    }
  }

  &__separator{
    width: 100%;
    height: 1.5px;
    margin-bottom: 28px;
    background-color: #99CAB8;
    @media (max-width: 992px) {
      margin-bottom: 24px;
    }
  }

  &__filter{
    margin-bottom: 80px;
    @media (max-width: 992px) {
      margin-bottom: 40px;
    }

    &--options{
      @media (max-width: 992px) {
        position: fixed;
        z-index: 10;
        background: #FFFFFF;
        width: 100%;
        padding: 22px 0 70px 0;
        border-radius: 20px 20px 0 0;
        bottom: -100px;
        left: 0;
        opacity: 0;
        pointer-events: none;
        transition: all 400ms;
        &.active{
          bottom: 0;
          pointer-events: all;
          opacity: 1;
        }
      }

      .optionsHeader{
        position: relative;
        text-align: center;
        padding-bottom: 48px;
        display: none;

        @media (max-width: 992px) {
          display: block;
        }

        &__title{
          font-family: 'freightsans_prosemibold';
          font-size: rem(20px);
          line-height: rem(28px);
          letter-spacing: 0.5px;
          color: $GK-Dark;
        }
        &__btnClose{
          position: absolute;
          right: 5px;
          top: 0;
          width: 13.41px;
          height: 13.41;
          img{
            width: 100%;
          }
        }
      }

      .optionsBtn{
        padding-top: 48px;
        text-align: center;
        display: none;
        @media (max-width: 992px) {
          display: block;
        }
      }

      .col-lg-3{
        margin-bottom: 24px;
        &:last-child{
          margin-bottom: 0;
        }
      }

    }



    @media (max-width: 992px) {
      .dropdown__wrapper .dropdown .options{
        overflow-y: auto;
        max-height: 160px;
      }
    }

  }

  &__listProjects{
    &--item{
      margin-bottom: 80px;
      @media (max-width: 992px) {
        margin-bottom: 40px;
      }

      .imageItem{
        overflow: hidden;
        position: relative;

        &:hover{
          .img{
            transform: scale(1.05);
          }
          .overlayItem{
            opacity: 1;
            transform: scale(1);
          }
        }

        .content{
          position: relative;
          width: 100%;
          overflow: hidden;
          pointer-events: all;
        }

        .img{
          transition: all 700ms ease;
        }

        .overlayItem{
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 0;
          background: $GK-Green;
          transition: all 700ms ease;
          transform: scale(1.05);

          @media (max-width: 992px) {
            display: none;
          }

          p{
            padding: 44px 44px 0 44px;
            color: #ffffff;
            font-family: 'freightsans_promedium';
            font-size: rem(21px);
            line-height: rem(32px);
            letter-spacing: 0.5px;

            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;

            @media (max-width: 992px) {
              font-size: rem(18px);
              line-height: rem(28px);
            }
          }
        }

      }
      .infoItem{
        padding-top: 18px;
        pointer-events: all;
        @media (max-width: 992px) {
          padding-top: 12px;
        }
        .tagItem{
          &__tag{
            background: $GK-Light-Blue;
            border-radius: 4px;
            padding: 8px 12px;
            display: inline-block;
            margin-bottom: 12px;
            text-decoration: none;
            font-family: 'freightsans_promedium';
            font-size: rem(18px);
            line-height: rem(21px);
            letter-spacing: 0.5px;
            color: #343434;
            margin-right: 12px;
            @media (max-width: 992px) {
              padding: 4px 10px;
              font-size: rem(16px);
              line-height: rem(19px);
              margin-bottom: 8px;
            }
          }
        }
        .titleItem{
          h6{
            position: relative;
            display: inline;
            &:before {
              content: "";
              position: absolute;
              height: 1px;
              top: calc(100% + 1px);
              border-top: 1.5px solid #007B4E;
              left: 1px;
              width: 0;
              -webkit-transition: all 500ms ease-in-out;
              transition: all 500ms ease-in-out;
            }
            @media (max-width: 992px) {
              font-size: rem(16px);
              line-height: rem(21px);
            }

            &.project_h6 {
              @media (max-width: 992px) {
                font-size: rem(20px);
                line-height: rem(28px);
              }
            }

            &:hover{
              color: $GK-Green;
              &:before {
                width: 100%;
              }

            }
          }
          & > a{
            text-decoration: none;
          }
        }

      }

    }

    &.featured{
      margin-bottom: 110px;
      @media (max-width: 992px) {
        margin-bottom: 0px;
      }

      .overlayItem{
        top: 44px;
        right: 44px;
      }

      .projects__listProjects--item{
        margin-bottom: 100px;
        @media (max-width: 992px) {
          margin-bottom: 40px;
        }
      }

      .projects__listProjects--item.small,
      .projects__listProjects--item.medium{
        .imageItem{
          display: flex;
          align-items: flex-end;
        }
      }

      .projects__listProjects--item.small{
        .overlayItem p{
          font-size: rem(21px);
          line-height: rem(32px);
          -webkit-line-clamp: 5;
        }
      }

      .projects__listProjects--item.medium{
        .overlayItem p{
          font-size: rem(24px);
          line-height: rem(32px);
          -webkit-line-clamp: 5;
        }
      }

      .projects__listProjects--item.big{
        .overlayItem p{
          font-size: rem(32px);
          line-height: rem(40px);
          -webkit-line-clamp: 5;
        }
      }

      .projects__listProjects--item.small,
      .projects__listProjects--item.medium,
      .projects__listProjects--item.big{
        .titleItem{
          h6{
            @media (max-width: 992px) {
              font-size: rem(20px);
              line-height: rem(28px);
            }
          }
        }
      }

    }

  }

  &__pagination{
    margin-top: 70px;
    margin-bottom: 150px;

    @media (max-width: 992px) {
      margin-top: 30px;
      margin-bottom: 100px;
    }

    &--pages{
      text-align: center;
      &.desktop{
        display: block;
        @media (max-width: 992px) {
          display: none;
        }
      }
      &.mobile{
        display: none;
        @media (max-width: 992px) {
          display: block;
        }
      }
      a{
        display: inline-block;
        text-decoration: none;
        font-family: 'freightsans_promedium';
        font-size: rem(28px);
        line-height: rem(36px);
        letter-spacing: 0.5px;
        color: #909090;
        margin-left: 24px;

        @media (max-width: 992px) {
          font-size: rem(21px);
          line-height: rem(32px);
        }

        &:first-child{
          margin: 0;
        }

        &.active {
          font-family: 'freightsans_prosemibold';
          color: $GK-Green
        }

        &.next,
        &.prev{
          border-radius: 50%;
          height: 38px;
          width: 38px;
          @media (max-width: 992px) {
            height: 32px;
            width: 32px;
          }
          &.disabled{
            opacity: .5;
            pointer-events: none;
          }
        }

        &.next{
          background: url('../images/icons/arrow-next.svg') no-repeat center center;
          background-color: $GK-Green;
          background-size: 10.5px;
          @media (max-width: 992px) {
            background-size: 8.84px;
          }
        }

        &.prev{
          background: url('../images/icons/arrow-prev.svg') no-repeat center center;
          background-color: $GK-Green;
          background-size: 10.5px;
          @media (max-width: 992px) {
            background-size: 8.84px;
          }
        }

      }
    }
  }

}

