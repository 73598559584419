.gk-color-box{
  padding: 44px 48px;
  background-color: $GK-Light-Green;

  @media (max-width: 992px) {
    padding: 30px 20px;
  }

  header{
    margin-bottom: 12px;
    h6{
      font-size: rem(24px);
    }
  }

  p{
    &:last-child{
      margin-bottom: 0 !important;
    }
  }

}
