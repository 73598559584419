.jobList{

  &__header{
    margin: 50px 0 40px 0;
    @media (max-width: 992px) {
      margin-top: 10px;
      margin-bottom: 20px;
    }
    &--title{
      @media (max-width: 992px) {
        font-size: rem(44px);
        line-height: rem(48px);
      }
    }
    & + .jobList__list{
      margin-top: 80px;
      @media (max-width: 992px) {
        margin-top: 40px;
      }
    }
  }


  &__filter{
    margin-bottom: 80px;

    @media (max-width: 992px) {
      margin-bottom: 40px;
    }

    &--line{
      width: 100%;
      height: 1.5px;
      margin-bottom: 22px;
      background-color: #99CAB8;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }

    .col-lg-3{
      @media (max-width: 992px) {
        margin-bottom: 20px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }

 &__list{
   margin-bottom: 102px;
   @media (max-width: 992px) {
    margin-bottom: 40px;
    }
   a{
     color: $GK-Dark;
     text-decoration: none;
   }
   &--item{
    background: $GK-Off-White;
    padding: 28px 44px;
    margin-bottom: 48px;
    @media (max-width: 992px) {
      padding: 20px;
      margin-bottom: 16px;
    }
    .nameItem{
      min-height: 28px;
      @media (max-width: 992px) {
        min-height: 28px;
      }
      p{
        font-family: 'freightsans_prosemibold';
        font-size: rem(18px);
        line-height: rem(28px);
        letter-spacing: 0.5px;
        margin-bottom: 12px;
        color: $GK-Dark;
        @media (max-width: 992px) {
          font-size: rem(16px);
          margin-bottom: 8px;
        }
      }
    }
    .titleItem{
      p{
        font-family: 'freightsans_prosemibold';
        font-size: rem(28px);
        line-height: rem(44px);
        letter-spacing: 0.5px;
        margin-bottom: 12px;
        color: $GK-Dark;
        @media (max-width: 992px) {
          font-size: rem(21px);
          line-height: rem(32px);
          margin-bottom: 8px;
        }
      }
    }
    .dateItem{
      min-height: 28px;
      @media (max-width: 992px) {
        min-height: 28px;
      }
      p{
        font-family: 'freightsans_promedium';
        font-size: rem(18px);
        line-height: rem(28px);
        letter-spacing: 0.5px;
        color: $GK-Dark;
        @media (max-width: 992px) {
          font-size: rem(16px);
        }
      }
    }
   }
  }

  &.part{
    margin-bottom: 80px;
    @media (max-width: 992px) {
      margin-bottom: 60px;
    }
    .jobList__title{
      margin-bottom: 40px;
      @media (max-width: 992px) {
        font-size: rem(28px);
        line-height: rem(36px);
        margin-bottom: 20px;
      }
    }
    .jobList__list{
      margin: 0;
    }
    .jobList__btn{
      padding-top: 24px;
      display: flex;
      justify-content: center;
      @media (max-width: 992px) {
        padding-top: 14px;
      }
    }
  }

}
