.energy-calculator {
   &__intro {
      background-color: $GK-Off-White;
      margin-bottom: 80px;

      @media (max-width: 992px) {
         margin-bottom: 60px;
      }

      @media (max-width: 576px) {
         margin-inline: -30px;
      }
      .intro-wrapper {
         display: flex;
         flex-direction: row;

         @media (max-width: 992px) {
            flex-direction: column-reverse;
         }

         .content {
            padding: 100px 145px 100px 120px;
            width: 50%;

            @media (max-width: 1360px) {
               padding: 40px 50px 40px 44px;
            }

            @media (max-width:992px) {
               padding: 20px 30px 32px 30px;
               width: 100%;
            }

            h1, h2, h3, h4, h5, h6 {
               &.title {
                  margin-bottom: 24px;

                  @media (max-width: 1200px) {
                     margin-bottom: 12px;
                     font-size: rem(28px);
                     line-height: rem(36px);
                  }
               }
            }

            .description{
               margin-bottom: 42px;

               @media (max-width: 1200px) {
                  font-size: rem(18px);
                  line-height: rem(28px);
                  margin-bottom: 32px;
               }
            }

            .btn.btn--push {
               @media (max-width: 1200px) {
                  padding: 10px 24px;
               }
            }
         }

         .image {
            width: 50%;

            @media (max-width: 992px) {
               width: 100%;
            }

            figure.img {
               width: 100%;

               @media (max-width:992px) {
                  padding-top: calc((220 / 375) * 100%);
               }

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
               }
            }
         }

      }
   }

   &__questionary {
      background-color: $GK-Off-White;
      margin-bottom: 80px;

      @media (max-width: 992px) {
         margin-bottom: 60px;
      }

      @media (max-width: 576px) {
         margin-inline: -30px;
      }

      .questionary-header {
         width: 100%;

         .steps {
            padding: 26px 0;
            font-size: rem(18px);
            line-height: rem(28px);
            text-align: center;
         }

         .progress-indicator {
            position: relative;
            width: 100%;
            height: 4px;
            background-color: #D7D7D7;
            z-index: 1;

            &__bar {
               position: absolute;
               top: 0;
               left: 0;
               bottom: 0;
               width: 0;
               background-color: $GK-Strek-Green;
            }
         }
      }

      .questionary-body {
         padding: 134px 25%;

         @media (max-width: 1200px) {
            padding: 134px 17%;
         }

         @media (max-width: 992px) {
            padding: 80px 30px;
         }

         @media (max-width: 576px) {
            padding: 40px 30px;
         }

         .question {
            &:not(:last-of-type) {
               margin-bottom: 60px;

               @media (max-width: 992px) {
                  margin-bottom: 24px;
               }
            }

            &__label {
               font-size: rem(20px);
               line-height: rem(28px);
               letter-spacing: 0.5px;
               margin-bottom: 4px;

               @media (max-width: 992px) {
                  font-size: rem(16px);
                  line-height: rem(24px);
                  margin-bottom: 8px;
               }
            }

            &__description {
               font-size: rem(16px);
               line-height: rem(24px);
               color: $GK-Black;
               margin-bottom: 12px;

               a {
                  position: relative;
                  color: $GK-Green;
                  padding-bottom: 2px;

                  @extend a.underline-animation;

                  &:not(.btn){
                     background: linear-gradient(currentColor 0 0)  0 100% /var(--d, 0) 1px  no-repeat;
                     -webkit-transition: all 500ms ease-in-out;
                     transition: all 500ms ease-in-out;
                     &:hover {
                       --d: 100%;
                     }

                     &:before{
                       display: none!important;
                     }
                   }
               }

               @media (max-width: 992px) {
                  font-size: rem(14px);
                  line-height: rem(20px);
               }
            }

            &__input-block {
               &--radio {
                  display: flex;

                  .input {
                     padding: 16px 0;
                     width: calc(100% - 6px);
                     text-align: center;
                     background-color: #fff;
                     border: 1px solid $GK-Strek-Green;
                     font-size: rem(18px);
                     line-height: rem(24px);
                     font-family: 'freightsans_promedium';
                     color: $GK-Dark;
                     transition: background-color 225ms ease-in-out, color 225ms ease-in-out, border 225ms ease-in-out;

                     &:first-child {
                        margin-right: 12px;

                        @media (max-width: 992px) {
                           margin-right: 7px;
                        }
                     }

                     &:hover {
                        cursor: pointer;
                        background-color: $GK-Light-Green;
                        border-color: $GK-Green;
                     }

                     &.selected {
                        color: #fff;
                        background-color: $GK-Green;
                        border-color: $GK-Green;
                     }

                     @media (max-width: 992px) {
                        width: calc(100% - 3px);
                     }
                  }
               }

               &--number, &--text, &--dropdown, &--email {
                  .input {
                     padding: 16px 20px;
                     border: 1px solid $GK-Strek-Green;
                     color: $GK-Dark;
                     font-size: 18px;
                     line-height: 24px;
                     font-family: 'freightsans_promedium', sans-serif;

                     @media (max-width: 992px) {
                        width: 50%;
                     }
                  }
               }

               &--email {
                  .input {
                     width: 100%;
                  }
               }

               &--dropdown {
                  position: relative;
                  .input {
                     background-color: #fff;
                     display: flex;
                     cursor: pointer;

                     &::after {
                        content: '';
                        width: 24px;
                        height: 24px;
                        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik00LjMzMzMzIDE3TDguMDI0ODEgMTdMMTYgOS41MDI3TDguMDI0ODEgMkw0LjMzMzMzIDJMMTIuMzA4NSA5LjUwMjdMNC4zMzMzMyAxN1oiIGZpbGw9IndoaXRlIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDIwKSByb3RhdGUoLTkwKSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=');
                        position: relative;
                        top: 0;
                        left: 0;
                        margin-left: auto;
                        background-position: center;
                        background-repeat: no-repeat;
                        filter: brightness(0) saturate(100%) invert(22%) sepia(41%) saturate(5250%) hue-rotate(152deg) brightness(93%) contrast(103%);
                        rotate: 90deg;
                        transition: rotate 225ms ease-in-out
                     }
                  }

                  .optionsList {
                     height: 0px;
                     overflow: hidden;
                     visibility: hidden;
                     position: absolute;
                     background-color: #fff;
                     border: 1px solid $GK-Strek-Green;
                     width: 100%;
                     top: calc(100% - 1px);
                     transition: height 300ms ease-in-out;
                     z-index: 1;


                     li {
                        padding: 16px 20px;

                        &:hover {
                           cursor: pointer;
                           background-color: $GK-Light-Green;
                        }
                     }
                  }

                  &.open {
                     .input {
                        &::after {
                           rotate: 270deg;
                        }
                     }
                  }
               }
            }
         }

         .question-group {
            & + .questionary-infoBox {
               margin-top: 20px;
            }
         }

         .questionary-infoBox {
            background-color: #CCE5DC;
            padding: 40px;

            @media (max-width: 992px) {
               padding: 24px;
            }

            & > * + * {
               margin-top: 24px;

               @media (max-width: 992px) {
                  margin-top: 20px;
               }
            }

            & + .questionary-infoBox {
               margin-top: 20px;
            }

            h1, h2, h3, h4, h5, h6 {
               &.title {
                  color: $GK-Dark;
                  font-size: rem(20px);
                  line-height: rem(28px);
                  font-family: 'freightsans_prosemibold', sans-serif;

                  @media (max-width: 992px) {
                     font-size: rem(16px);
                     line-height: rem(24px);
                  }
               }
            }

            p, a, li, span {
               font-size: rem(18px);
               line-height: rem(28px);
               font-family: 'freightsans_probook', sans-serif;

               @media (max-width: 992px) {
                  font-size: rem(16px);
                  line-height: rem(24px);
               }
            }

            a {
               position: relative;
               color: $GK-Green;
               padding-bottom: 2px;

               @extend a.underline-animation;

               &:not(.btn){
                  background: linear-gradient(currentColor 0 0)  0 100% /var(--d, 0) 1px  no-repeat;
                  -webkit-transition: all 500ms ease-in-out;
                  transition: all 500ms ease-in-out;
                  &:hover {
                    --d: 100%;
                  }

                  &:before{
                    display: none!important;
                  }
                }
            }

            ul {
               list-style: none;
               padding-left: 20px;

               li {
                  position: relative;

                  &::before {
                     content: '';
                     width: 8px;
                     height: 8px;
                     background-color: $GK-Green;
                     position: absolute;
                     left: -20px;
                     top: 10px;
                  }

                  h1, h2, h3, h4, h5, h6 {
                     color: $GK-Dark;
                     font-size: rem(20px);
                     line-height: rem(28px);
                     font-family: 'freightsans_prosemibold',
                     sans-serif;

                     @media (max-width: 992px) {
                        font-size: rem(16px);
                        line-height: rem(24px);
                     }
                  }
               }
            }
         }

         .questionary-btn-group {
            display: flex;
            align-items: center;
            gap: 12px;
            margin-top: 60px;

            .questionary-btn {
               color: #fff;
               background-color: $GK-Green;
               font-size: rem(18px);
               line-height: rem(24px);
               font-family: 'freightsans_promedium', sans-serif;
               padding: 16px 0 16px 0;
               width: calc(50% - 6px);
               border: 1px solid $GK-Green;
               border-radius: 40px;
               transition: color 225ms ease-in-out, background-color 225ms ease-in-out, border 225ms ease-in-out;

               &--prev {
                  padding-left: 10px;
                  &::before {
                     content: '';
                     background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik00LjMzMzMzIDE3TDguMDI0ODEgMTdMMTYgOS41MDI3TDguMDI0ODEgMkw0LjMzMzMzIDJMMTIuMzA4NSA5LjUwMjdMNC4zMzMzMyAxN1oiIGZpbGw9IndoaXRlIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDIwKSByb3RhdGUoLTkwKSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=');
                     position: relative;
                     display: inline-block;
                     top: 3px;
                     left: -10px;
                     width: 18px;
                     height: 18px;
                     background-repeat: no-repeat;
                     rotate: 180deg;
                     filter: brightness(0) saturate(100%) invert(18%) sepia(11%) saturate(3423%) hue-rotate(109deg) brightness(97%) contrast(94%);
                  }

                  &:not(:hover):not(:active):not(.disabled) {
                     color: $GK-Dark;
                     background-color: #fff;
                     border-color: $GK-Strek-Green;
                  }
               }

               &--next {
                  padding-right: 10px;

                  &::after {
                     content: '';
                     background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik00LjMzMzMzIDE3TDguMDI0ODEgMTdMMTYgOS41MDI3TDguMDI0ODEgMkw0LjMzMzMzIDJMMTIuMzA4NSA5LjUwMjdMNC4zMzMzMyAxN1oiIGZpbGw9IndoaXRlIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDIwKSByb3RhdGUoLTkwKSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=');
                     position: relative;
                     display: inline-block;
                     top: 3px;
                     right: -10px;
                     width: 18px;
                     height: 18px;
                     background-repeat: no-repeat;
                  }
               }

               &:hover, &:active, &.disabled {
                  &.questionary-btn--prev::before {
                     filter: none;
                  }
               }

               &:hover:not(.disabled) {
                  background-color: #339571;
                  border-color: #339571;
               }

               &:active:not(.disabled) {
                  background-color: $GK-Green;
                  border-color: $GK-Green;
               }

               &.disabled {
                  cursor: not-allowed;
                  opacity: .5;
               }
            }
         }

         .questionary-btn-single {
            width: 100%;
            margin-top: 60px;

            .questionary-cta {
               background-color: $GK-Green;
               color: #fff;
               font-size: rem(18px);
               line-height: rem(24px);
               font-family: 'freightsans_promedium', sans-serif;
               padding: 16px;
               width: 100%;
               border: none;
               border-radius: 40px;

               &::after {
                  content: '';
                  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik00LjMzMzMzIDE3TDguMDI0ODEgMTdMMTYgOS41MDI3TDguMDI0ODEgMkw0LjMzMzMzIDJMMTIuMzA4NSA5LjUwMjdMNC4zMzMzMyAxN1oiIGZpbGw9IndoaXRlIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDIwKSByb3RhdGUoLTkwKSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=');
                  position: relative;
                  display: inline-block;
                  top: 3px;
                  right: -10px;
                  width: 18px;
                  height: 18px;
                  background-repeat: no-repeat;
               }

               &:hover, &:active, &.disabled {
                  &.questionary-btn--prev::before {
                     filter: none;
                  }
               }

               &:hover:not(.disabled) {
                  background-color: #339571;
                  border-color: #339571;
               }

               &:active:not(.disabled) {
                  background-color: $GK-Green;
                  border-color: $GK-Green;
               }

               &.disabled {
                  cursor: not-allowed;
                  opacity: .5;
               }
            }
         }

         .questionary-return {
            display: block;
            margin-top: 28px;
            text-align: center;
            font-size: rem(18px);
            line-height: rem(28px);
            font-family: 'freightsans_probook',
            sans-serif;
            color: $GK-Green;
            text-decoration: underline;
            text-underline-offset: 1px;
            text-decoration-color: rgba($GK-Green, 0.4);

            @media (max-width: 992px) {
               margin-top: 20px;
               font-size: rem(16px);
               line-height: rem(24px);
            }
         }

         .questionary-ingress {
            text-align: center;
            font-size: rem(24px);
            line-height: rem(32px);
            color: $GK-Black;
            margin-bottom: 60px;
            font-family: 'freightsans_promedium';

            @media (max-width: 992px) {
               margin-bottom: 32px;
               font-size: rem(18px);
               line-height: rem(28px);
            }
         }

         .questionary-policy {
            font-size: rem(16px);
            line-height: rem(24px);
            color: $GK-Black;
            margin-top: 16px;
            margin-bottom: 60px;

            @media (max-width: 992px) {
               margin-top: 12px;
               margin-bottom: 40px;
               font-size: rem(14px);
               line-height: rem(24px);
            }

            p, a {
               font-size: inherit;
               line-height: inherit;
            }

            a {
               color: $GK-Green;
               text-decoration: underline;
               text-underline-offset: 1px;
               text-decoration-color: rgba($GK-Green, 0.4);
            }
         }
      }

      &--step-1 {
         .questionary-body {
            .questionary-btn-group {
               .questionary-btn {
                  &--prev {
                     display: none;
                  }

                  &--next {
                     margin-left: auto;
                  }
               }
            }
         }
      }

      &--contact-form, &--end {
         .questionary-header {
            .progress-indicator::before {
               right: 0;
            }
         }

         .questionary-body {
            width: 100%;
            .question-group {
               @media (min-width: 993px) {
                  display: flex;
                  flex-wrap: wrap;
                  gap: 0 12px;
               }

               @media (max-width: 992px) {
                  margin-bottom: 24px;
               }

               .question {
                  width: auto;
                  width: calc(50% - 6px);

                  &__input-block {
                     .input {
                        width: 100%;
                     }
                  }

                  @media (max-width: 992px) {
                     width: 100%;
                  }
               }
            }

            .question-block {
               width: 100%;
            }

            .questionary-btn-single {
               margin-top: 0;
            }
         }
      }

      &--end {
         .questionary-body {
            text-align: center;
            padding-block: 232px;

            @media (max-width: 992px) {
               padding-block: 224px;
            }

            h1, h2, h3, h4, h5, h6 {
               &.title {
                  color: $GK-Green;
                  font-family: 'freightsans_prosemibold',
                  sans-serif;
                  font-size: rem(72px);
                  line-height: rem(80px);
                  margin-bottom: 60px;

                  @media (max-width: 992px) {
                     margin-bottom: 20px;
                     font-size: rem(38px);
                     line-height: rem(48px);
                  }
               }
            }

            .text {
               font-size: rem(24px);
               line-height: rem(32px);
               color: $GK-Black;
               font-family: 'freightsans_promedium', sans-serif;

               @media (max-width: 992px) {
                  font-size: rem(18px);
                  line-height: rem(28px);
               }
            }

            .return-button {
               margin-top: 60px;
               font-size: rem(18px);
               line-height: rem(28px);
               padding: 12px 38px;
               background-color: $GK-Green;
               color: #fff;
               font-family: 'freightsans_promedium', sans-serif;
               border-radius: 40px;
               border: none;

               @media (max-width: 992px) {
                  margin-top: 20px;
                  padding: 10px 24px;
               }
            }
         }
      }
   }
}
