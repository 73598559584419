.services{
  margin-bottom: 150px;

  @media(max-width: 992px){
    margin-bottom: 60px;
  }

  header{
    margin-bottom: 41px;

    @media(max-width: 992px){
      margin-bottom: 21px;
    }

    h3{
      @media(max-width: 992px){
        font-size: rem(28px);
        line-height: rem(32px);
      }
    }
  }

  ul{
    li{
      //margin-top: -1px;

      a{
        position: relative;
        display: block;
        height: 100%;
        padding: 24px 45px 24px 16px;
        // border-top: 1.5px solid $GK-Strek-Green;
        // border-bottom: 1.5px solid $GK-Strek-Green;
        box-shadow: inset 0 1.5px 0px 0px $GK-Strek-Green, 0 1.5px 0px 0px $GK-Strek-Green;
        color: $GK-Dark;
        text-decoration: none;
        font-family: 'freightsans_promedium';
        font-size: rem(28px);
        line-height: rem(36px);
        letter-spacing: 0.5px;

        @media(max-width: 992px){
          padding: 18px 40px 18px 0px;
          font-size: rem(21px);
          line-height: rem(32px);
        }

        &.active,&:hover{
          color: $GK-Green;
          //border-bottom: 1.5px solid $GK-Green;

          &::after{
            right: 0;
          }
        }

        &::after{
          content: "";
          @include background-image ("/icons/arrow-item-result.svg",16.33,21);
          position: absolute;
          right: 15px;
          top: 50%;
          margin-top: -10.5px;
          transition: all 350ms;
          @media (max-width: 992px) {
            @include background-image ("/icons/arrow-item-result.svg",11.67,15);
            top: 30px;
            margin-top: 0;
          }
        }
      }

      &.text-bold{
        a{
          font-family: 'freightsans_probold';
        }
      }
    }
  }

  &--green-bg{
    padding: 80px 0;
    background-color: rgba($GK-Green , .1);

    @media (max-width: 992px) {
      padding: 40px 0;
    }
  }

  // .container{
  //   >div{
  //     display: inline-block;
  //   }
  // }

  .container {
    .row {
      .list-wrapper {
        @media (max-width: 992px) {
          &:not(:first-child) {
            margin-top: 60px;
          }
        }
      }
    }
  }
}

.container{
  .services{
    .container{
      @media (max-width: 991px) {
        padding: 0 14px!important;
      }
    }
  }
}
