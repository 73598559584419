.slideshow{
  margin: 80px 0;

  &__wrapper{
    height: 696px;
    @media(max-width: 992px){
      height: auto;
    }

    .slick-list, .slick-track{
      height: 100%;
    }

    .slick-list{
      width: calc(100% + 30px)!important;

      @media (max-width: 992px) {
        width: calc(100% + 15px)!important;
      }

      &.draggable{
        overflow: visible;
      }
      @media (max-width: 992px) {
        //padding: 0 10% 0 0;
        overflow: hidden;
      }
    }

    .slick-slide{
      // margin: 0 30px 0 0;
    }

    &--item{
      position: relative;
      margin-right: 30px;
      overflow: hidden;


      @media (max-width: 992px) {
        margin-right: 15px;
        margin-bottom: 60px;
      }

      a {
        text-decoration: none;
      }

      .legend-box{
        position: absolute;
        background-color: $GK-Light-Blue;
        padding: 48px;
        right: 0;
        bottom: 0;
        width: 797px;
        height: 278px;

        @media(max-width: 992px){
          position: relative;
          width: 100%;
          height: auto;
          padding: 30px 25px;
        }

        h4{
          font-size: rem(28px);
          line-height: rem(36px);
          letter-spacing: 0.5px;
          font-weight: 600;
          margin-bottom: 15px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;

          @media(max-width: 992px){
            font-size: rem(24px);
            line-height: rem(32px);
          }
        }

        p{
          font-size: rem(21px);
          line-height: rem(32px);
          letter-spacing: 0.5px;
          font-weight: 400;

          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;

          @media(max-width: 992px){
            font-size: rem(18px);
            line-height: rem(28px);
          }

        }
      }
      figure{
        height: 100%;

        @media(max-width: 992px){
          height: 276px;
          width: 100%;
        }

        img{
          max-width: 100%;
          height: 100%;
          width: 100%;
          transition: transform .5s ease;

          object-fit: cover;
          object-position: center;

          &:hover{
            transform: scale(1.1);
          }

          @media(max-width: 992px){
            object-fit: cover;
            object-position: center;
          }

        }
      }
    }

    .slick-arrow{
      background-color: $GK-Dark;
      padding: 15px;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      z-index: 99;

      @media(max-width: 992px){
        display: none !important;
      }

      &:hover, &:focus{
        background-color: $GK-Dark;
      }

      &::before{
        padding: 15px;
        content: "";
        background-image: url("./../images/icons/arrow-slider.svg");
        background-repeat: no-repeat;
        border-radius: 50px;
        width: 12px;
        height: 15px;
        background-position: center;
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 14px);
        opacity: 1;
      }

      &.slick-next{
        right: 5%;
      }

      &.slick-prev{
        left: 87%;
        @media(max-width: 1360px){
          left: 85%;
        }
        @media(max-width: 1200px){
          left: 83%;
        }
        &::before{
          transform: rotate(-180deg);
          left: calc(50% - 16px);
        }
      }

      &.slick-disabled{
        background-color: #909090;
        &::before{
          opacity: 1;
        }
      }
    }
  }

  &.no-padding{
    .container{
      padding: 0 !important;
      .slideshow__wrapper{
        .slick-arrow{
          &.slick-next{
            right: 10%
          }
          &.slick-prev{
            left: 80%
          }
        }
      }
    }
  }
}
