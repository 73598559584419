.image-grid {
    margin-bottom: 100px;
    @media (max-width: 992px) {
        margin-bottom: 60px;
    }
    h2 {
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
        grid-column-start: 1;
        grid-column-end: -1;

        @media (max-width: 992px) {
            margin-bottom: 20px;
            font-size: 1.75rem;
            line-height: 2.25rem;
            margin-bottom: 20px;
        }
        a {
            color: inherit;
            text-decoration: none!important;
            &:hover {
                color: $GK-Green;
            }
        }
    }
    &__wrapp {
        display: grid;
        align-items: center;
        gap: 48px;
        grid-template-columns: repeat(6, 1fr);
        @media (max-width: 1650px) {
            grid-template-columns: repeat(5, 1fr);
        }
        @media (max-width: 992px) {
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
        }
        @media (max-width: 640px) {
            margin: 0 calc(50% - 50vw);
            padding: 0 calc(50vw - 50% - 20px);
            width: 100vw;
            display: flex;
            align-items: center;
            flex-wrap: no-wrap;
            overflow-y: auto;
            &:before, &::after {
                content: '';
                display: block;
            }

        }

        img {
            flex: 1 1 15%;
            // aspect-ratio: 1/1;
            max-width: 100%;
            height: auto;
            object-fit: scale-down;
            margin: auto;
            width: 100%;
            @media (max-width: 640px) {
                width: 120px;
                min-width: 120px;
            }
        }
        a {
            display: block;
            width: fit-content;
            text-transform: uppercase;
            text-decoration: none!important;
            font-weight: bold;
            margin: auto;
        }
    }
}
.image-part {
    text-align: center;
    margin-bottom: 100px;
    @media (max-width: 992px) {
        margin-bottom: 60px;
    }
    img {
        width: auto;
        max-height: auto;
        margin: auto;
    }
    a {
        text-decoration: none;
        display: block;
        width: fit-content;
    }
}
