.contact-office{
  .contact-office__header{
    margin-bottom: 60px;

    @media (max-width: 992px) {
      margin-bottom: 20px;
    }

    h1{
      @media (max-width: 992px) {
        font-size: rem(44px);
        line-height: rem(48px);
      }
    }

    .contact-office__ingress{
      margin-top: 40px;

      @media (max-width: 992px) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }

  .contact-card{
    margin-bottom: 105px;

    @media (max-width: 992px) {
      margin-bottom: 28px;
    }
  }
}
