.personal-contacts{
  margin-bottom: 110px;
  h3{
    margin-bottom: 40px;
  }
  &__wrapper{
    display: flex;
    justify-content: space-between;
    &--item{
      background-color: $GK-Off-White;
      text-align: center;
      width: 314px;
      padding: 50px;
      margin-bottom: 40px;
      figure{
        img{
          width: 168px;
          height: 168px;
        }
      }
      h6{
        margin: 28px 0 12px 0;
        font-size: rem(21px);
        line-height: rem(32px);
        border-bottom: 2px solid $GK-Green;
        padding-bottom: 2px;
        display: inline-block;
      }
      span{
        display: block;
        font-size: rem(18px);
        line-height: rem(21px);
        line-height: rem(32px);
        &.business{
          margin-bottom: 18px;
        }
      }
    }
  }

  &__info{
    margin-bottom: 60px;
    @media(max-width: 992px){
      margin-bottom: 40px;
    }
    >span{
      color: #08402B;
      font-size: rem(21px);
      line-height: rem(32px);
      letter-spacing: 0.5px;
      display: block;
      font-weight: 600;

      a{
        color: #08402B;
        font-size: rem(21px);
        line-height: rem(32px);
        letter-spacing: 0.5px;
        font-weight: 400;
      }


      &.job{
        font-size: rem(24px);
        margin: 40px 0;
        @media(max-width: 992px){
          margin: 28px 0;
        }
      }
    }
  }
}

.personal-contact-page{
  padding-bottom: 50px;
  @media(max-width: 992px){
    padding-bottom: 0;
  }

  h1.typography-h2{
    @media(max-width: 992px){
      font-size: rem(44px);
      line-height: rem(48px);
      margin-bottom: 40px;
    }
  }
  .htmlArea{
    margin-bottom: 80px;
  }

  h1+figure{
    @media(max-width: 992px){
      margin-top:40px;
      margin-bottom:40px;
    }
  }

}
