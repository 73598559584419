.article__wrapper {

   .article__header {
      margin-bottom: 0px;

      .button-back {
         margin-bottom: 80px;

         @media (max-width: 992px) {
            margin-bottom: 32px;
         }

         a {
            display: inline-block;

            // Underline animation custom parameters
            &::before {
               left: 20px;
            }

            &:hover {
               &::before {
                  width: calc(100% - 20px);
               }
            }

            .p3 {
               display: flex;
               align-items: center;
               color: $GK-Green;

               &::before {
                  content: '';
                  display: inline-block;
                  width: 16px;
                  height: 16px;
                  margin-right: 4px;
                  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjY2NjMgMkg5LjcxMzE2TDMuMzMzMDEgNy45OTc4NEw5LjcxMzE2IDE0SDEyLjY2NjNMNi4yODYxOSA3Ljk5Nzg0TDEyLjY2NjMgMloiIGZpbGw9IiMwMDdCNEUiLz4KPC9zdmc+Cg==');
                  background-repeat: no-repeat;
               }
            }
         }
      }

      h1.typography-h2 {
         margin-bottom: 32px;

         @media (max-width: 992px) {
            margin-bottom: 20px;
            font-size: rem(44px);
            line-height: rem(48px);
         }
      }

      .meta {
         margin-bottom: 40px;
         color: $GK-Dark;
         font-family: 'freightsans_probook';
         font-size: rem(21px);
         line-height: rem(32px);
         letter-spacing: 0.5px;

         @media (max-width: 992px) {
            margin-bottom: 20px;
            font-size: rem(16px);
            line-height: rem(28px);
         }

         &.text--fade-left {
            opacity: 0;
            animation-delay: 0.15s;
         }

         .info {
            margin-bottom: 18px;

            @media (max-width: 992px) {
               margin-bottom: 2px;
            }
         }

         .share {
            display: flex;
            align-items: baseline;

            span {
               margin-right: 5px;
            }

            ul {
               display: flex;
               flex-flow: wrap;

               li {
                  display: flex;
                  align-items: center;

                  &::after {
                     content: '';
                     display: inline-block;
                     width: 1px;
                     height: 19px;
                     margin: 0 13px;
                     border-right: 1px solid $GK-Dark;
                  }

                  &:last-child {
                     &::after {
                        display: none;
                     }
                  }
               }
            }
         }
      }
   }

   .article__tags {
      margin-bottom: 40px;

      @media (max-width: 992px) {
         margin-bottom: 20px;
      }

      &--items {

         span,
         a {
            background: $GK-Light-Blue;
            padding: 8px 12px;
            border-radius: 4px;
            text-decoration: none;
            display: inline-block;
            margin-right: 12px;

            color: #343434;
            font-family: 'freightsans_promedium';
            font-size: rem(18px);
            line-height: rem(21px);
            letter-spacing: 0.5px;

            @media (max-width: 992px) {
               padding: 4px 10px;
               margin-right: 8px;
               font-size: rem(16px);
               line-height: rem(19px);
            }

            &:last-child {
               margin-right: 0;
            }
         }
      }
   }

   .article__content {
      .contentArticle {
         position: relative;
         width: 100%;
         margin-bottom: 40px;

         @media (max-width: 992px) {
            margin-bottom: 10px;
         }

         .featured-image {
            position: relative;
            margin-bottom: 34px;

            @media (max-width: 992px) {
               margin-bottom: 20px;
            }

            .figure--fade-left {
               animation-delay: 0.8s;

               img,
               video {
                  animation-delay: 1.1s;
               }
            }

            &--video {
               display: flex;

               video {
                   flex: 1 1 auto;
                   overflow: auto;
               }

               figure {
                  display: flex;

                  video {
                     opacity: 0;
                     width: 100%;
                     height: auto;
                  }
               }
            }

            &--youtube {
               figure {
                  .btn--video {
                     position: absolute;
                     top: calc(50% - 69px);
                     left: calc(50% - 69px);
                     z-index: 1;

                     @media (max-width: 992px) {
                        width: 118px;
                        height: 118px;
                        top: calc(50% - 56px);
                        left: calc(50% - 56px);
                     }

                  }
               }

               .iframe-wrapper {
                  display: none;
                  position: relative;
                  //padding-bottom: 56.25%; /* 16:9 */
                  padding-top: calc((689 / 1400) * 100%);
                  height: 0;

                  iframe {
                     position: absolute;
                     top: 0;
                     left: 0;
                     width: 100%;
                     height: 100%;
                  }
               }
            }

            figcaption {
               margin-top: 18px;
               font-size: rem(16px);
               line-height: rem(28px);

               &.text--fade-left {
                  opacity: 0;
                  animation-delay: 1.1s;
               }
            }
         }

         .factBox {
            margin-bottom: 80px;
            position: relative;

            @media (max-width: 992px) {
               margin-bottom: 40px;
               background: #E6F2ED;

               &::before {
                  content: "";
                  width: 100%;
                  height: 150px;
                  background: #E6F2ED;
                  position: absolute;
                  top: -130px;
                  left: 0;
                  z-index: -1;
               }
            }

            .container {
               .container {
                  padding: 0 0 60px 0;
                  background: #E6F2ED;
                  position: relative;

                  @media (max-width: 992px) {
                     padding: 0 15px 20px 15px !important;
                     background: none;
                  }

                  &::before {
                     content: "";
                    //  width: 100%;
                     width: calc(100% + 500px);
                     height: 665px;
                     background: #E6F2ED;
                     position: absolute;
                     bottom: 0;
                     left: 0;
                     z-index: -1;

                     @media (max-width: 1448px) {
                        height: calc(100% + 30vw);
                     }

                     @media (max-width: 992px) {
                        display: none;
                     }
                  }

                  .row {
                     @media (min-width: 992px) {
                        padding-top: 26px;
                     }
                  }
               }
            }

            &__wrapper {
               display: flex;
               flex-wrap: wrap;
               flex-direction: row;
               gap: 0px 16px;

               .factBox__item {
                  width: calc(50% - (16px/2));
               }
            }

            &__item {
               margin-bottom: 40px;

               @media (max-width: 992px) {
                  padding-right: 20px;
                  margin-bottom: 14px;
               }

               span {
                  display: block;
                  word-break: break-word;

                  &:first-child {
                     color: #08402B;
                     font-family: 'freightsans_probook';
                     font-size: rem(18px);
                     line-height: rem(28px);
                     letter-spacing: 0.5px;
                     margin-bottom: 2px;

                     @media (max-width: 992px) {
                        font-size: rem(16px);
                        word-break: break-word;
                        hyphens: auto;
                        margin-bottom: 0;
                     }
                  }

                  &:last-child {
                     color: #08402B;
                     font-family: 'freightsans_promedium';
                     font-size: rem(21px);
                     line-height: rem(32px);
                     letter-spacing: 0.5px;

                     @media (max-width: 992px) {
                        font-size: rem(18px);
                        line-height: rem(28px);
                        word-break: break-word;
                        hyphens: auto;
                     }
                  }
               }

               a {
                  text-decoration: none;
                  color: #08402B;
                  font-family: 'freightsans_promedium';
                  font-size: rem(21px);
                  line-height: rem(32px);
                  letter-spacing: 0.5px;
                  display: inline-block;

                  @media (max-width: 992px) {
                     font-size: rem(18px);
                     line-height: rem(28px);
                     word-break: break-word;
                     hyphens: auto;
                  }
               }

               &--left-col {
                  @media (max-width: 992px) {
                     margin-bottom: 32px;
                  }

                  span {
                     color: $GK-Dark;

                     &:first-child {
                        font-size: rem(24px);
                        line-height: rem(32px);
                        font-weight: 600;
                        margin-bottom: 12px;

                        @media (max-width: 992px) {
                           font-size: rem(18px);
                        }
                     }

                     &:last-child {
                        font-size: rem(21px);
                        line-height: rem(32px);
                        font-family: 'freightsans_probook', sans-serif;

                        @media (max-width: 992px) {
                           line-height: rem(28px);
                           font-size: rem(18px);
                        }
                     }

                     @media (max-width: 992px) {
                        font-size: rem(18px);
                     }
                  }
               }
            }

            &__PDFlink {
               text-decoration: none;
               color: #08402B;
               font-family: 'freightsans_promedium';
               font-size: rem(21px);
               line-height: rem(32px);
               letter-spacing: 0.5px;
               display: inline-block;

               @media (max-width: 992px) {
                  font-size: rem(18px);
                  line-height: rem(28px);
                  word-break: break-word;
                  hyphens: auto;
                  padding-top: 28px;

                  span {
                     display: none;
                  }
               }
            }
         }

         // off grid adjustment
         .ingress,
         .htmlArea-article-content {
            padding-left: 6.5%;

            @media (max-width: 1200px) {
               padding-left: 6%;
            }

            @media (max-width: 992px) {
               padding-left: inherit;
            }
         }

         .ingress {
            margin-bottom: 44px;

            @media (max-width: 992px) {
               margin-bottom: 20px;
            }
         }

         .htmlArea-article-content {

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
               margin-bottom: 12px;
               font-size: rem(24px);
               line-height: rem(32px);
            }

            p {
               font-size: rem(21px);
               line-height: rem(32px);
               margin-bottom: 40px;

               @media (max-width: 992px) {
                  margin-bottom: 30px;
               }
            }

            a {
               // rule added because there cannot be classes on links in Enonic
               @extend a.underline-animation;

               &:not(.btn) {
                  background: linear-gradient(currentColor 0 0) 0 100% /var(--d, 0) 1px no-repeat;
                  -webkit-transition: all 500ms ease-in-out;
                  transition: all 500ms ease-in-out;

                  &:hover {
                     --d: 100%;
                  }

                  &:before {
                     display: none !important;
                  }
               }

            }

            .imageWrapper {
               width: 100%;

               figure {
                  overflow: hidden;
                  margin-bottom: 60px;

                  @media (max-width: 992px) {
                     margin-bottom: 40px;
                  }

                  &:hover {
                     img {
                        transform: scale(1.05);
                     }
                  }

                  img {
                     width: 100%;
                     transition: all 700ms ease;
                  }

                  figcaption {
                     margin-top: 18px;
                     font-size: rem(16px);
                     line-height: rem(28px);
                  }

                  &.editor-align-left {
                     margin-right: 30px;
                  }

                  &.editor-align-right {
                     margin-left: 30px;
                  }

                  &.editor-align-left,
                  &.editor-align-right {
                     width: 50%;
                     margin-top: 10px;
                     margin-bottom: 40px;

                     @media (max-width: 992px) {
                        float: none !important;
                        width: 100% !important;
                        margin: 0;
                        margin-bottom: 30px;
                     }
                  }

                  &.editor-align-justify {
                     margin-left: -118px;

                     @media (max-width: 1448px) {
                        margin-left: -17%;
                     }

                     @media (max-width: 992px) {
                        margin-left: 0;
                     }

                     img {
                        width: 100%;
                     }

                     figcaption {
                        text-align: center;
                     }
                  }

                  &.editor-align-center {
                     margin-bottom: 40px !important;

                     @media (max-width: 992px) {
                        margin-bottom: 30px !important;
                     }

                     img {
                        margin: 0 auto;
                        display: block;
                     }

                     figcaption {
                        text-align: center;
                     }
                  }
               }
            }

            .videoWrapper {
               margin-bottom: 80px;
               margin-left: -118px;

               @media (max-width: 1448px) {
                  margin-left: -17%;
               }

               @media (max-width: 992px) {
                  margin-bottom: 60px;
                  margin-left: 0;
               }

               figure {
                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }

                  button {
                     position: absolute;
                     top: calc(50% - 69px);
                     left: calc(50% - 69px);
                     z-index: 1;
                  }
               }

               video {
                  display: none;
                  width: 100%;
               }

               &--youtube {
                  .iframe-wrapper {
                     display: none;
                     position: relative;
                     padding-top: calc((9 / 16) * 100%);
                     height: 0;

                     iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                     }
                  }
               }
            }

            ul, ol {
               margin-bottom: 44px;

               li {
                  position: relative;
                  margin-bottom: 12px;
                  font-family: 'freightsans_probook';
                  font-size: rem(21px);
                  line-height: rem(32px);
                  letter-spacing: 0.5px;

                  &:last-child {
                     margin-bottom: 0;
                     color: $GK-Black;
                  }

                  strong {
                     font-family: 'freightsans_promedium';
                  }
               }
            }

            ol {
               li {
                  margin-left: 20px;
                  padding-left: 14px;
               }
            }

            ul {
               li {
                  padding-left: 34px;

                  &::before {
                     content: "";
                     position: absolute;
                     left: 0;
                     top: 15px;
                     width: 8px;
                     height: 8px;
                     background-color: $GK-Green;
                  }
               }
            }

            .gk-quote {
               margin-bottom: 60px;

               @media (max-width: 992px) {
                  margin-bottom: 40px;
               }
            }

            .gk-color-box {
               margin-bottom: 40px;
            }
         }

         hr {
            margin-top: 80px;
            margin-bottom: 80px;
            border: none;
            border-bottom: 1.5px solid #99CAB8;

            @media (max-width: 992px) {
               margin-top: 60px;
               margin-bottom: 40px;
            }
         }

         @media(max-width: 992px) {
            .separator {
               .container {
                  padding: 0 25px !important;
               }
            }
         }

         @media(min-width: 992px) {
            .text-media-macro {
               &.privatBanner {
                  margin-left: -193px;

                  .privatBanner__wrapper {
                     .privatBanner__wrapper--text {
                        flex-direction: column;
                        align-items: flex-start;
                        margin: 0;
                        padding: 40px;

                        a.btn {
                           margin: 20px 0 0 0;
                        }
                     }
                  }
               }
            }
         }

      }
   }

   &--service {
      .article__header {
         h1.typography-h2 {
            margin-bottom: 40px;
         }
      }
   }

   &--topic,
   &--project {
      .article__content {
         .contentArticle {
            .featured-image {
            //    max-width: 1448px;
               width: 100%;
               margin-right: auto;
               margin-left: auto;
            //    padding-right: 24px;
               padding-right: calc((100vw - 1400px)/2);
               padding-left: 0px;

               @media (max-width: 1680px) {
                //   max-width: inherit;
                  padding-left: 0;
               }

               @media (max-width: 1448px) {
                  padding: 0 24px 0 0;
               }

               @media (max-width: 1199px) {
                  padding-right: calc((100vw - 912px)/2);
               }

               @media (max-width: 991px) {
                  padding-right: calc((100vw - 660px)/2);
               }

               @media (max-width: 767px) {
                  padding-right: calc((100vw - 480px)/2);
               }

               @media (max-width: 575px) {
                  padding-right: 30px;
               }

               figure {
                  @media (max-width: 1680px) {
                     display: flex;
                     width: 100%;
                     height: 730px;
                  }

                  @media (max-width: 1448px) {
                     padding-top: calc((730 / 1540) * 100%);
                     height: auto;
                  }

                  img,
                  video {
                     @media (max-width: 1680px) {
                        width: 100%;
                        object-fit: cover;
                     }

                     @media (max-width: 1448px) {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        height: 100%;
                        width: 100%;
                        transform: translate(-50%, -50%);
                        object-fit: cover;
                     }
                  }
               }

               figcaption {
                  @media (max-width: 1680px) {
                     padding-left: calc((100vw - 1400px)/2);
                  }

                  @media (max-width: 1448px) {
                     padding: 0 0 0 24px;
                  }

                  @media (max-width: 1199px) {
                     padding-left: calc((100vw - 912px)/2);
                     padding-right: 0px;
                  }

                  @media (max-width: 991px) {
                     padding-left: calc((100vw - 660px)/2);
                  }

                  @media (max-width: 767px) {
                     padding-left: calc((100vw - 480px)/2);
                  }

                  @media (max-width: 575px) {
                     padding-left: 30px;
                  }
               }

               &--youtube {
                  .iframe-wrapper {
                     @media (max-width: 1448px) {
                        //padding-top: calc((9 / 16) * 100%);
                     }
                  }
               }
            }

            >.container {
               position: relative;
            }
         }
      }
   }

   &--project {
      .article__content {
         .contentArticle {
            .featured-image {

               @media (max-width: 991px) {
                  padding-right: 0 !important;
               }

            }
         }
      }
   }

   @media (min-width: 992px) {

      .text-media-macro.privatBanner,
      .text-media {
         // width: calc((3/2)*100% + 24px);
         // margin-left: -50%;
         margin-top: 50px;
         margin-bottom: 50px;

         .container {
            padding: 0 !important;
         }

         p {
            min-width: 50%;
         }

         .privatBanner__wrapper .privatBanner__wrapper--text {
            margin-left: 10px;

            a {
               margin: 0 10px;
               min-width: max-content;
            }
         }

         figure {
            min-height: 100%;
            align-self: stretch;

            img {
               min-height: 100%;
               object-fit: cover;
            }
         }
      }
   }


}
