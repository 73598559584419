.career-part, .common-part {
    .gobi {
        margin-bottom: 100px;
        padding: 80px 0;
        color: $GK-Dark;

        * {
            color: inherit;
        }

        @media (max-width: 992px) {
            margin-bottom: 60px;
            padding: 40px 0;
        }

        &__title {
            h2 {
                font-family: 'freightbig_probold', serif;
                font-size: rem(60px);
                line-height: rem(72px);
                margin-bottom: calc(60px - 25px);
                text-align: center;

                @media (max-width: 992px) {
                    font-size: rem(32px);
                    line-height: rem(36px);
                }
            }
        }

        &__intro {
            align-items: center;
            justify-content: center;
            display: flex;

            p {
                font-size: rem(18px);
                line-height: rem(20px);
                text-align: center;
                margin-top: -15px;
                margin-bottom: 15px;
                width: 90%;
            }
        }

        &__wrapper {
            display: block;
            align-items: center;
            justify-content: center;
            gap: calc(80px - 25px*2);
            padding-inline: 3rem;
        }

        &__item {
            display: flex !important;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            text-align: center;

            &.slick-slide{
                padding: 0 12px;
            }

            .name {
                font-family: 'freightsans_prosemibold', sans-serif;
                font-size: rem(18px);
                line-height: rem(20px);
                margin-bottom: 8px;
            }

            .description {
                font-size: rem(18px);
                line-height: rem(20px);
            }
        }

        &__item-collection {
            display: flex !important;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            text-align: center;

            .name {
                font-family: 'freightsans_prosemibold', sans-serif;
                font-size: rem(18px);
                line-height: rem(20px);
                margin-bottom: 8px;
                white-space: nowrap;
            }

            .description {
                font-size: rem(18px);
                line-height: rem(20px);
            }

            .gobi-collection {
                overflow: hidden;
                overflow-x: scroll;
                scroll-behavior: smooth;
                box-sizing: border-box;
                width: 100%;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                &::-webkit-scrollbar-track-piece {
                    display: none;
                }

                &::-webkit-scrollbar-thumb {
                    display: none;
                }
            }
        }

        &--mobile {
            .gobi__wrapper {
                overflow: hidden;
            }
        }
    }

    #gobi-next, .gobi-step-next {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' fill='%23F16F64'/%3E%3Cpath d='M15.6667 26L18.6198 26L25 20.0022L18.6198 14L15.6667 14L22.0468 20.0022L15.6667 26Z' fill='white'/%3E%3C/svg%3E");
        border: none;
    }

    #gobi-previous, .gobi-step-previous {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' transform='rotate(-180 20 20)' fill='%23F16F64'/%3E%3Cpath d='M24.3333 14L21.3802 14L15 19.9978L21.3802 26L24.3333 26L17.9532 19.9978L24.3333 14Z' fill='white'/%3E%3C/svg%3E");
        border: none;
    }

    .gobi--varme #gobi-next, .gobi--varme .gobi-step-next {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' fill='white'/%3E%3Cpath d='M15.6667 26L18.6198 26L25 20.0022L18.6198 14L15.6667 14L22.0468 20.0022L15.6667 26Z' fill='%23EE5246'/%3E%3C/svg%3E");
        border: none;
    }

    .gobi--varme #gobi-previous, .gobi--varme .gobi-step-previous {
        width: 40px;
        height: 40px;
        rotate: 180deg;
        flex-shrink: 0;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' fill='white'/%3E%3Cpath d='M15.6667 26L18.6198 26L25 20.0022L18.6198 14L15.6667 14L22.0468 20.0022L15.6667 26Z' fill='%23EE5246'/%3E%3C/svg%3E");
        border: none;
    }

    #gobi-previous, .gobi-step-previous {
        &.slick-disabled {
            cursor: not-allowed;
            opacity: .5;
        }
    }

    #gobi-next .gobi-step-next {
        &.slick-disabled {
            cursor: not-allowed;
            opacity: .5;
        }
    }

    &.gobi-textful,
    &.gobi-collection-textful {
        margin: 0 auto;
        padding-bottom: 80px;

        .gobi-box {
            padding: 31px 34px;
            &.gobi-left {
                flex-direction: row-reverse;

                .gobi-container {
                    margin-right: -18px;
                    margin-left: 0px;
                }

                .gobi-box__content {
                    padding-left: 10px;
                    padding-right: 12px;
                }
            }
            &.gobi-right {
                flex-direction: row;

                .gobi-container {
                    margin-right: 0px;
                    margin-left: -18px;
                }

                .gobi-box__content {
                    padding-right: 10px;
                    padding-left: 12px;
                }
            }
        }
    }

    .gobi {
        @media (min-width: 1441px) {
            &--desktop-lg {
                display: block;
            }

            &--desktop-sm {
                display: none;
            }

            &--tablet {
                display: none;
            }

            &--mobile {
                display: none;
            }
        }

        @media (min-width: 1201px) and (max-width: 1440px) {
            &--desktop-lg {
                display: none;
            }

            &--desktop-sm {
                display: block;
            }

            &--tablet {
                display: none;
            }

            &--mobile {
                display: none;
            }
        }

        @media (min-width: 993px) and (max-width: 1200px) {
            &--desktop-lg {
                display: none;
            }

            &--desktop-sm {
                display: none;
            }

            &--tablet {
                display: block;
            }

            &--mobile {
                display: none;
            }
        }

        @media (max-width: 992px) {
            &--desktop-lg {
                display: none;
            }

            &--desktop-sm {
                display: none;
            }

            &--tablet {
                display: none;
            }

            &--mobile {
                display: block;
            }
        }
    }
}

.career-part, .common-part {
    .gobi .gobi-carousel-container {
        flex-wrap: nowrap;
        display: flex;
        align-items: center;
        position: relative;

        #gobi-previous, .gobi-step-previous, #gobi-next, .gobi-step-next {
            position: absolute;
            z-index: 10;
        }

        #gobi-previous, .gobi-step-previous {
            transform: translateX(-50%);
            top: calc(50% - 40px);
            left: 10px;
        }

        #gobi-previous {
            left: 0;

            @media (max-width: 1400px) {
                left: 30px;
            }

            @media (max-width: 992px) {
                left: 0px;
            }

            @media (max-width: 768px) {
                left: 30px;
            }
        }

        #gobi-next, .gobi-step-next {
            transform: translateX(50%);
            top: calc(50% - 40px);
            right: 10px;
        }

        #gobi-next {
            right: 0;

            @media (max-width: 1400px) {
                right: 30px;
            }

            @media (max-width: 992px) {
                right: 0px;
            }

            @media (max-width: 768px) {
                right: 30px;
            }
        }
    }
}

.common-part .gobi .gobi-carousel-container  {
    #gobi-previous, .gobi-step-previous, #gobi-next, .gobi-step-next {
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' fill='%23007B4E'/%3E%3Cpath d='M15.6667 26L18.6198 26L25 20.0022L18.6198 14L15.6667 14L22.0468 20.0022L15.6667 26Z' fill='white'/%3E%3C/svg%3E");
    }

    #gobi-previous, .gobi-step-previous {
        transform-origin: 0;
        rotate: 180deg;
    }
}

.career-part .gobi {
    &--lun {
        background-color: #FEE3DC;
    }

    &--white {
        background-color: #FFF;
    }

    &--varme {
        background-color: $GK-Red;
        color: #fff;
    }
}

.common-part .gobi {

    &--green {
        background-color: $GK-Light-Green;
    }

    &--blue {
        background-color: $GK-Light-Blue;
    }

    &--gray {
        background-color: $GK-Off-White;
    }

    &--white {
        background-color: #fff;
    }

    .gobi-carousel-container {
        .gobi-step-previous {
            left: 0;
        }
        .gobi-step-next {
            right: 0;
}

        @media (min-width: 1200px) {
            .gobi-step-previous {
                transform-origin: unset;
    }
            .gobi-step-previous, .gobi-step-next {
                transform: translateX(100%);
            }
        }
    }
}

.common-part {
    .gobi {
        color: $GK-Dark;

        * {
            color: inherit;
        }

        &__title {
            h2 {
                font-family: 'freightsans_prosemibold', serif;
                font-size: rem(60px);
                line-height: rem(72px);

                @media (max-width: 992px) {
                    font-size: rem(32px);
                    line-height: rem(36px);
                }
            }
        }

        &__item {
            .name {
                font-family: 'freightsans_prosemibold', sans-serif;
                font-size: rem(18px);
                line-height: rem(20px);
            }
            .description {
                font-size: rem(18px);
                line-height: rem(20px);
            }
        }

        &__item-collection {
            .name {
                font-family: 'freightsans_prosemibold', sans-serif;
                font-size: rem(18px);
                line-height: rem(20px);
            }
        }
    }
}
