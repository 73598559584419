.career-layout {
    .related-content {
        .related-content__header {
            h2.typography-h3 {
                font-family: 'freightbig_probold', serif;
                margin-bottom: 28px;

                @media (max-width: 992px) {
                    font-size: rem(32px);
                    line-height: rem(36px);
                    margin-bottom: 20px;
                    padding-left: 24px;
                }
            }
        }

        .related-content__content {
            @media (max-width: 992px) {
                padding-left: 24px;

                & .slick-list.draggable {
                    overflow: visible;
                }
            }
            .related-content__item {
                margin-bottom: 0px;

                @media (max-width: 991px) {
                    width: 43.7vw;
                }
                @media (max-width: 768px) {
                    width: 40.9vw;
                }
                @media (max-width: 576px) {
                    width: 58.7vw;
                }
                @media (max-width: 375px) {
                    width: 54.4vw;
                }

                .infoItem {
                    .ingressItem {
                        height: 74px;
                        p {
                            font-size: rem(16px);
                            line-height: rem(24px);
                        }

                        div {
                            bottom: 0;
                        }
                    }
                }

                .imageItem .img.aspectRatio--436x291 {
                    @media (max-width: 992px) {
                        padding-top: calc((143 / 203) * 100%);
                    }
                }
            }
        }
    }
}

.page.karriersiden {
    .career-layout .page__content{
        .related-content {
            padding-bottom: 0px;
            margin-bottom: 100px;

            @media (max-width: 992px) {
                margin-bottom: 60px;
            }
        }
    }
}

.page.karriersiden-article {
    .career-layout .page__content .related-content {
        padding-bottom: 0px;
        margin-bottom: 150px;

        @media (max-width: 992px) {
            margin-bottom: 100px;
        }

        &__header {
            h2 {
                margin-bottom: 40px;

                @media (max-width: 992px) {
                    margin-bottom: 20px;
                }
            }
        }
  }
}
