.link-fact-box{
  ul, ol {
    margin-bottom: 12px !important
  }
  ul:last-child, ol:last-child {
    margin-bottom: 0 !important
  }

  a{
    position: relative;
    color: $GK-Green;
    padding-bottom: 2px;

    @extend a.underline-animation;

    &:not(.btn){
      background: linear-gradient(currentColor 0 0)  0 100% /var(--d, 0) 1px  no-repeat;
      -webkit-transition: all 500ms ease-in-out;
      transition: all 500ms ease-in-out;
      &:hover {
        --d: 100%;
      }

      &:before{
        display: none!important;
      }
    }

    // Right arrow variation
    &.chevron{
      border-bottom: none;

      &::before{
        content: "";
        position: absolute;
        height: 1px;
        top: calc(100% + 2px);
        border-top: 1.5px solid $GK-Green;
        left: 1px;
        width: 0px;
        -webkit-transition: all 500ms ease-in-out;
        transition: all 500ms ease-in-out;
      }

      &:hover{
        &::before{
          width: calc(100% - 53px);
        }
      }

      &::after{
        content: '';
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzNCAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjkxNjggMjIuMjVMMTUuNTAwOSAyMi4yNUwyMS4wODM1IDE3LjAwMTlMMTUuNTAwOSAxMS43NUwxMi45MTY4IDExLjc1TDE4LjQ5OTUgMTcuMDAxOUwxMi45MTY4IDIyLjI1WiIgZmlsbD0iIzAwN0I0RSIvPgo8Y2lyY2xlIGN4PSIxNyIgY3k9IjE3IiByPSIxNi4yNSIgc3Ryb2tlPSIjMDA3QjRFIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K');
        display: inline-block;
        width: 34px;
        height: 34px;
        margin-bottom: -10px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}
