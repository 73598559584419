// ------------------------------------------------------------------ //
// Font: Freight Sans Pro                                             //
// ------------------------------------------------------------------ //
// Light (300): 				font-family: 'freightsans_prolight';          //
// Light Italic (300): 	font-family: 'freightsans_prolight_italic';   //
// Book (400):					font-family: 'freightsans_probook';           //
// Book Italic (400):		font-family: 'freightsans_probook_italic';    //
// Medium (500):				font-family: 'freightsans_promedium';         //
// Medium Italic (500):	font-family: 'freightsans_promedium_italic';  //
// Semibold (600):			font-family: 'freightsans_prosemibold';       //
// Bold (700):					font-family: 'freightsans_probold';           //
// ------------------------------------------------------------------ //

html, body {
	font-size: 16px;
  // hyphens: auto;
  // -webkit-hyphens: auto;
  // -moz-hyphens: auto;
  // -ms-hyphens: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: 'freightsans_probook';
}

h1,h2,h3,h4,h5,h6,.typography-h1,.typography-h2,.typography-h3,.typography-h4,.typography-h5,.typography-h6 {
  font-family: 'freightsans_prosemibold';
  color: $GK-Dark;
  hyphens: none;
  font-weight: normal;

  @media (max-width: 992px) {
    word-break: break-word;
    hyphens: auto;
    -webkit-hyphens: auto;
  }
}

h1, .typography-h1 {font-size: rem(104px); line-height: rem(108px);}
h2, .typography-h2 {font-size: rem(72px); line-height: rem(80px);}
h3, .typography-h3 {font-size: rem(44px); line-height: rem(48px);}
h4, .typography-h4 {font-size: rem(38px); line-height: rem(48px);}
h5, .typography-h5 {font-size: rem(32px); line-height: rem(40px);}
h6, .typography-h6 {font-size: rem(24px); line-height: rem(32px);}

[data-class="typography-h1"] {font-size: rem(104px) !important; line-height: rem(108px) !important;}
[data-class="typography-h2"] {font-size: rem(72px) !important; line-height: rem(80px) !important;}
[data-class="typography-h3"] {font-size: rem(44px) !important; line-height: rem(48px) !important;}
[data-class="typography-h4"] {font-size: rem(38px) !important; line-height: rem(48px) !important;}
[data-class="typography-h5"] {font-size: rem(32px) !important; line-height: rem(40px) !important;}
[data-class="typography-h6"] {font-size: rem(24px) !important; line-height: rem(32px) !important;}

.ingress {
  font-family: 'freightsans_prosemibold';
  color: $GK-Dark;
  font-size: rem(28px);
  line-height: rem(40px);
  letter-spacing: 0.5px;

  @media (max-width: 992px) {
    font-size: rem(18px);
    line-height: rem(28px);
  }
}

p{
  color: $GK-Black;
}

.p1 {font-size: rem(21px); line-height: rem(32px);}
.p2 {font-size: rem(18px); line-height: rem(28px);}
.p3 {font-size: rem(16px); line-height: rem(28px);}
.p4 {font-size: rem(14px); line-height: rem(24px);}

// Text hyper link

a, p{
  &.underline-animation{
    position: relative;
    color: $GK-Green;

    &:not(.btn, .button) {
        padding-bottom: 2px;
    }

    &::before{
      content: "";
      position: absolute;
      height: 1px;
      top: calc(100% + 2px);
      border-top: 1.5px solid $GK-Green;
      left: 1px;
      width: 0px;
      -webkit-transition: all 500ms ease-in-out;
      transition: all 500ms ease-in-out;
    }

    &:hover{
      color: $GK-Green;
      text-decoration: none;

      &::before{
        width: 100%;
      }
    }

    // Right arrow variation
    &.chevron{
      border-bottom: none;

      &::before{
        content: "";
        position: absolute;
        height: 1px;
        top: calc(100% + 2px);
        border-top: 1.5px solid $GK-Green;
        left: 1px;
        width: 0px;
        -webkit-transition: all 500ms ease-in-out;
        transition: all 500ms ease-in-out;
      }

      &:hover{
        &::before{
          width: calc(100% - 53px);
        }
      }

      &::after{
        content: '';
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzNCAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjkxNjggMjIuMjVMMTUuNTAwOSAyMi4yNUwyMS4wODM1IDE3LjAwMTlMMTUuNTAwOSAxMS43NUwxMi45MTY4IDExLjc1TDE4LjQ5OTUgMTcuMDAxOUwxMi45MTY4IDIyLjI1WiIgZmlsbD0iIzAwN0I0RSIvPgo8Y2lyY2xlIGN4PSIxNyIgY3k9IjE3IiByPSIxNi4yNSIgc3Ryb2tlPSIjMDA3QjRFIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K');
        display: inline-block;
        width: 34px;
        height: 34px;
        margin-bottom: -10px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}

p {
  &.underline-animation{
    display: inline-block;
    color: #202020;

    &:hover {
      color: #202020;
    }
  }
}

// Bulletpoints in text
ul, ol {
  li {
    position: relative;
    font-family: 'freightsans_probook';
    font-size: rem(21px);
    line-height: rem(32px);
    letter-spacing: 0.5px;

    &:last-child{
      margin-bottom: 0;
      color: $GK-Black;
    }

    strong{
      font-family: 'freightsans_promedium';
    }
  }
}

/*
ol {
  li {
     margin-left: 20px;
     padding-left: 14px;
  }
}
*/

// Bulletpoints in text
ul {
  list-style: none;

  &.square-bullets{
    margin-bottom: 44px;

    li {
      padding-left: 34px;
      margin-bottom: 12px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 15px;
        width: 8px;
        height: 8px;
        background-color: $GK-Green;
      }
    }
  }
}

