.subjectAreas{
  margin-bottom: 102px;
  @media (max-width: 992px) {
    margin-bottom: 40px;
  }
  &__subTitle{
    font-family: 'freightsans_promedium';
    font-size: rem(18px);
    line-height: rem(28px);
    color: $GK-Dark;
    letter-spacing: 0.5px;
    margin-bottom: 2px;
    @media (max-width: 992px) {
      font-size: rem(16px);
      margin-bottom: 0;
    }
  }
  &__title{
    margin-bottom: 40px;
    @media (max-width: 992px) {
      font-size: rem(28px);
      line-height: rem(36px);
      margin-bottom: 20px;
    }
  }
  &__items{
    .item__wrapper{
      margin-bottom: 48px;

      @media (max-width: 992px) {
        margin-bottom: 20px;
      }

      a{
        text-decoration: none;
      }

      .subjectAreas__items--item{
        height: 100%;
        padding: 48px 20px 40px;
        background: #F7F6F4;
        border: 1.5px solid #F7F6F4;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        @media (max-width: 992px) {
          padding: 20px 16px;
          flex-direction: row;
          justify-content: flex-start;
          text-align: left;
        }

        .icon{
          width: 100%;

          @media (max-width: 992px) {
            width: 38px;
            margin-right: 24px;
          }

          img{
            width: 100%;
            max-width: 64px;
            @media (max-width: 992px) {
              max-width: 38px;
            }
          }
        }

        .title{
          margin-top: 17px;

          @media (max-width: 992px) {
            margin-top: 0;
          }
          p{
            font-family: 'freightsans_promedium';
            font-size: rem(28px);
            line-height: rem(36px);
            letter-spacing: 0.5px;
            color: $GK-Dark;
            text-decoration: none;
            word-break: break-all;

            @media (max-width: 992px) {
              font-size: rem(18px);
              line-height: rem(28px);
            }

            span{
              position: relative;

              &:after{
                content: "";
                position: absolute;
                height: 1px;
                top: calc(100% + 2px);
                border-top: 1.5px solid $GK-Green;
                left: 1px;
                width: 0px;
                -webkit-transition: all 500ms ease-in-out;
                transition: all 500ms ease-in-out;
              }
            }
          }
        }

        &:hover{
          .title{
            p span{
              color: $GK-Green;
              &:after{
                width: 100%;
               }
            }
          }
        }

        &.onlyTitle{
          border: 1.5px solid #007B4E;
          background: transparent;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 768px) {
            width: fit-content;
            width: -moz-fit-content;
            height: auto;
            margin: 28px auto;
            background-color: $GK-Green;
            color: white;
            padding: 12px 38px;
            font-family: 'freightsans_promedium';
            font-size: rem(18px);
            line-height: rem(28px);
            letter-spacing: 0.5px;
            border-radius: 100px;

            &:hover{
              background-color: #339571;
            }
          }

          .title{
            text-align: center;
            width: auto;
            padding-left: 0;
            margin-top: 0;
            p{
              @media (max-width: 768px) {
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
}
