body{
  &.parallax{
		background: linear-gradient(180deg, rgba(0, 123, 78, 0.1) 0%, rgba(8, 94, 62, 0) 100%);
		background-size: 100% 1087px;
    background-repeat: no-repeat;

    padding-top: 245px;

    @media (max-width: 992px) {
      padding-top: 160px;
    }

    .main-header.parallax{
      position: fixed;
      top: 0;
      width: 100%;

      .subHeader{
        transition: all 450ms;
        opacity: 1;
        &.hide{
          opacity: 0;
          pointer-events: none;
        }
      }

    }

  }

  &.gradient-bg{
    background: linear-gradient(180deg, rgba(0, 123, 78, 0.1) 0%, rgba(8, 94, 62, 0) 100%);
		background-size: 100% 1087px;
    background-repeat: no-repeat;
  }
}

.parallaxMedia{
  margin-bottom: 200px;
  @media (max-width: 992px) {
    margin-bottom: 60px;
  }
  &__title{
    position: relative;
    z-index: 1;
    margin-bottom: calc(-1.9% - 30px);
    padding-right: calc(15% - 30px);
    transform: translateY(0);
    opacity: 1;
    transition: all 1300ms;

    @media (max-width: 1200px) {
      margin-bottom: calc(-1.9% - 20px);
    }

    @media (max-width: 992px) {
      margin-bottom: calc(-1.9% - 10px);
    }

    @media (max-width: 768px) {
      margin-bottom: 20px;
      padding-right: 0;
    }

    &:after{
      content: "";
      height: 100%;
      width: 110%;
      background: #edf5f2;
      position: absolute;
      left: -50px;
      top: 0;
      -webkit-mask-image: -webkit-gradient(linear, left top,
        left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
      opacity: 0;
      transition: all 1200ms;
    }

    &.opacity{
      &:after{
        opacity: 1;
      }
    }

    img{
      width: 100%;
      max-width: 1209px;
      margin-left: -13px;
      @media (max-width: 992px) {
        margin-left: -7px;
      }
      @media (max-width: 768px) {
        margin-left: -3px;
      }
    }

    &.translate{
      transform: translateY(-160px);
      opacity: .1;
    }

    // h1{
    //   font-family: 'freightsans_promedium';
    //   font-size: rem(104px);
    //   line-height: rem(120px);
    //   text-transform: uppercase;
    //   letter-spacing: 0.5px;
    //   span{
    //     display: block;
    //     padding-right: calc(15% - 30px);
    //   }
    // }
  }

  &__media{
    width: 1156px;
    margin: 0 auto;
    max-width: 100%;

    @media (max-width: 1200px) {
      width: 750px;
    }

    @media (max-width: 992px) {
      width: 550px;
    }

    @media (max-width: 768px) {
      width: 480px;
    }

    @media (max-width: 575px) {

    }





    &--image,
    &--video{
      width: 100%;
      height: 670px;
      overflow: hidden;
      transition: all 1200ms;
      position: relative;
      @media (max-width: 1200px) {
        height: 500px;
      }
      @media (max-width: 992px) {
        height: 360px;
      }
      @media (max-width: 768px) {
        height: 315px;
      }
      img,
      video{
        width: 100%;
        height: auto;
        @media (max-width: 575px) {
          width: auto;
          height: 120%;
        }
      }
    }
  }

  &__ourProjects{
    margin: 0 auto;
    width: 100%;
    max-width: 1448px;
    position: relative;

    @media (max-width: 1200px) {
      max-width: 960px;
    }

    @media (max-width: 992px) {
      max-width: 720px;
    }

    @media (max-width: 768px) {
      max-width: 540px;
    }

    &--box{
      background: $GK-Off-White;
      padding: 55px 72px;
      position: absolute;
      bottom: 100px;
      right: 144px;
      width: 100%;
      max-width: 555px;
      opacity: 0;
      pointer-events: none;
      transition: all 1300ms;
      @media (max-width: 1200px) {
        bottom: 50px;
        right: 72px;
      }

      @media (max-width: 992px) {
        padding: 30px 35px;
        width: 60%;
      }

      @media (max-width: 768px) {
        width: 80%;
        right: 50%;
        bottom: 35px;
        transform: translateX(50%);
      }

      h3{
        margin-bottom: 32px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        @media (max-width: 992px) {
          margin-bottom: 20px;
          font-size: rem(30px);
          line-height: rem(28px);
        }
      }
      p{
        margin-bottom: 40px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        @media (max-width: 992px) {
          font-size: rem(18px);
          line-height: rem(32px);
          margin-bottom: 20px;
          -webkit-line-clamp: 2;
        }
      }
    }
  }

  .container--media{
    transition: all 1300ms;
    padding: 0!important;
    margin: 0 auto;
    max-width: 1680px;

  }

  &.full{
    .parallaxMedia__ourProjects{
      &--box{
        pointer-events: all;
        opacity: 1;
      }
    }
  }


}


.aboutUsMain{
  margin-top: 200px;
  margin-bottom: 200px;
  @media (max-width: 992px) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  h4{
    font-family: 'freightsans_promedium';
    letter-spacing: 0.5px;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    max-width: 1014px;
    @media (max-width: 992px) {
      font-size: rem(18px);
      line-height: rem(28px);
    }
  }
}
