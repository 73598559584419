.footer{
    background: $GK-Dark;
    padding: 53px 0 35px 0;
    color: #fff;
    @media (max-width: 992px) {
        padding: 32px 0 32px 0;
    }
    &__top{
        margin-bottom: 81px;
        @media (max-width: 992px) {
            margin-bottom: 2px;
        }
        .title{
            display: block;
            margin-bottom: 21px;
            font-family: 'freightsans_prosemibold';
            font-size: rem(21px);
            line-height: rem(32px);
            @media (max-width: 992px) {
                margin-bottom: 18px;
                font-size: rem(18px);
                line-height: rem(29px);
            }
        }
        .item{
            display: block;
            margin-bottom: 8px;
            @media (max-width: 992px) {
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 40px;
                }
            }
            a{
                color: #ffffff;
                text-decoration: none;
                display: inline-block;
                font-family: 'freightsans_probook';
                font-size: rem(21px);
                line-height: rem(32px);
                letter-spacing: 0.5px;
                border-bottom: 1.5px solid #007B4E;
                @media (max-width: 992px) {
                    font-size: rem(16px);
                    line-height: rem(29px);
                }
            }
        }
        .goTop{
            float: right;
            width: 55px;
            height: auto;

            img{
                margin-bottom: 6px;
                animation: arrowsAnim 3s linear infinite;

                &:last-child{
                    margin-bottom: 0;
                }

                &:nth-child(0){
                    animation-delay: 0.8s;
                }

                &:nth-child(1){
                    animation-delay: 0.4s;
                }

                &:nth-child(2){
                    animation-delay: 0.2s;
                }

                @keyframes arrowsAnim{
                    0%{
                        opacity: 0.1;
                    }

                    20%{
                        opacity: 1;
                    }

                    100%{
                        opacity: 0.1;
                    }
                }
            }
        }
    }
    &__bot{
        margin-top: 105px;

        @media (max-width: 992px) {
            margin-top: 8px;
        }
        p{
            font-family: 'freightsans_probook';
            font-size: rem(21px);
            line-height: rem(26px);
            letter-spacing: 0.5px;
            color: #ffffff;
            @media (max-width: 992px) {
                font-size: rem(16px);
                line-height: rem(28px);
            }
            a{
                color: #ffffff;
                border-bottom: 1.5px solid #007B4E;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    &__navigate{
        @media (max-width: 575px) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 24px;
        }

        .title{
            @media (max-width: 575px) {
                width: 100%;
            }
        }

        .item{
            @media (max-width: 575px) {
                width: calc(50% - 12px);
            }
        }
    }

    &__newsletter{
        @media (max-width: 992px) {
            order: -1;
            margin-bottom: 40px;
        }

        .title{
            @media (max-width: 992px) {
                font-size: rem(21px);
                line-height: 32px;
            }
        }

        p{
            color: #ffffff;
            font-size: rem(18px);
            line-height: 1.5;
            margin-bottom: 0;
        }

        .gk-form{
            .input-group{
                margin: 0;

                label{
                    margin-bottom: 0;
                }
            }

            .input-wrapper{
                display: flex;
                flex-direction: row;
                margin: 34px 0 30px;

                @media (max-width: 992px) {
                    margin: 32px 0 18px;
                }

                input[type="email"]{
                    padding: 0px 14px !important;
                    font-size: rem(16px);
                    background-color: transparent;
                    color: #ffffff;
                    border: 1.5px solid $GK-Green;

                    &::placeholder {
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #ffffff;
                        opacity: 1;
                        /* Firefox */
                    }

                    &::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: #ffffff;
                    }
                }

                .btn{
                    margin: 0;
                    padding: 10px 24px;
                    border-radius: 0;
                    font-size: rem(16px);
                    background-color: $GK-Green;
                }
            }

            .custom-checkbox{
                .label-text{
                    p, a{
                        font-size: rem(14px);
                        line-height: 1.5;
                        color: #ffffff;
                        letter-spacing: 0.5px;
                        font-family: 'freightsans_promedium';
                    }

                    a{
                        text-decoration: underline;
                    }
                }

                .checkmark{
                    top: 1px;
                    background-color: #E5F2EDB2;
                }
            }
        }
    }

    &__social{
        display: flex;
        flex-direction: row;
        gap: 32px;
        margin-bottom: 44px;

        @media (max-width: 992px) {
            gap: 24px;
            margin-bottom: 32px;
        }

        li{
            a{
                display: block;

                img{
                    display: block;
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
}
