#cookiebanner {
    box-sizing: border-box;
    background-color: #08402b;
    width: 100%;
    padding: 56px 8%;
    position: fixed;
    z-index: 2147483645;
    bottom: 0px;
}

@media screen and (max-width:720px) {
    #cookiebanner {
        padding: 24px 30px;
    }
}

@media screen and (max-width:992px) {
    #c-content   {
        width: 100%;
    }
}

#cookiebanner .c-header {
    color: #fff;
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
    font-weight: 400;
}

#cookiebanner p.c-message {
    font-size: 21px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #fff;
    margin-bottom: 40px;
    font-weight: 300;
}

@media screen and (max-width:992px) {
    #cookiebanner .c-header {
        font-size: 21px;
        line-height: 28px;
    }

    #cookiebanner p.c-message {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 21px;
    }
}

#c-input {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 44px;
}

@media screen and (max-width: 1200px) {
    #c-input {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 30px;
    }
}

#c-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 44px;
}

@media screen and (max-width:720px) {
    #c-button-container {
        gap: 14px;
    }
}

@media screen and (max-width:576px) {
    #c-button-container {
        flex-direction: column;
        gap: 18px;

        .c-button {
            width: 100%;
        }
    }
}

#cookiebanner a {
    color: #fff;
    text-decoration: none;
}

#cookiebanner a.c-button {
    display: inline-block;
    padding: 12px 38px;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.5px;
    background-color: #007B4E;
    color: #fff;
    text-decoration: none;
    border-radius: 40px;
    transition: background 200ms ease-in-out;
}

#cookiebanner a.c-button:hover {
    background-color: #339571;
}

@media screen and (max-width:720px) {
    #cookiebanner a.c-button {
        padding: 10px 24px;
    }
}

#cookiebanner a.c-button.c-button--customize {
    padding-right: 58px;
    position: relative;
}

#cookiebanner .c-button--customize:after {
    content: '';
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik00LjMzMzMzIDE3TDguMDI0ODEgMTdMMTYgOS41MDI3TDguMDI0ODEgMkw0LjMzMzMzIDJMMTIuMzA4NSA5LjUwMjdMNC4zMzMzMyAxN1oiIGZpbGw9IndoaXRlIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDIwKSByb3RhdGUoLTkwKSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=');
    display: inline-block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    position: absolute;
    right: 30px;
    top: 16px;
}

.c-readmore {
    display: inline;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: 0.5px;
    border-bottom: 1.5px solid #007B4E;
    text-decoration: none;
    transition: color 200ms ease-in-out;
}

#cookiebanner a.c-readmore:hover {
    color: #007B4E;
}

@media screen and (max-width:992px) {
    .c-readmore {
        font-size: 18px;
        line-height: 28px;
    }
}

.hidden {
    display: none;
}

#cookie-parent {
  position: relative;
  z-index: 99999999999;
}

.modal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

#c-modal {
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    background-color: #08402b;
    width: 75vw;
    border-radius: 10px;
    overflow: hidden;
    margin: 24px auto;

    &:not(.hidden) {
        display: flex;
    }

    @media (max-width: 992px) {
        width: 95vw;
    }

    @media (max-width: 576px) {
        margin: 16px auto;
    }
}

#c-modal-navigation ul li a {
    color: #fff;
    text-decoration: none;
}

#c-modal-navigation {
    border-bottom: 1px solid #fff;

    @media (max-width: 576px) {
        border: none;
    }
}

#c-modal-navigation ul {
    font-family: 'freightsans_promedium', sans-serif;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    font-size: 21px;
    line-height: 28px;

    @media (max-width: 1200px) {
        font-size: 18px;
        line-height: 28px;
    }

    @media (max-width: 576px) {
        flex-direction: column;
    }

    li {
        width: 33.33%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 3px solid transparent;

        &.active {
            border-bottom: 3px solid #fff;
        }

        @media (max-width: 576px) {
            border-bottom: #fff solid 1px;
            width: 100%;

            &.active {
                border-bottom: #fff solid 1px;
                background-color: #007B4E;
            }
        }

        a {
            display: inline-block;
            padding: 24px 0;
            width: 100%;
            text-align: center;
        }
    }
}

.cookie-card {
    padding: 32px;
    border-bottom: solid 1px #fff;

    @media (max-width: 576px) {
        padding: 20px;
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        font-size: 21px;
        line-height: 28px;

        @media (max-width: 992px) {
            font-size: 18px;
            line-height: 28px;
        }

        button {
            word-wrap: break-word;
            text-align: left;
            font-size: inherit;
        }
    }

    &-description {
        font-size: 18px;
        line-height: 28px;
        color: #fff;
        padding-left: 12px;

        @media (max-width: 992px) {
            font-size: 16px;
            line-height: 28px;
        }
    }

    &-read-more {
        font-family: 'freightsans_pro_medium', sans-serif;
        color: #fff;
        background-color: inherit;
        border: none;
        box-shadow: none;
        position: relative;

        &::before {
            content: '^';
            position: absolute;
            top: -2px;
            left: -38px;
            transform: rotate(180deg);
            width: 32px;
            height: 32px;
            line-height: 40px;
            text-align: center;

            @media (max-width: 576px) {
                left: -27px;
            }
        }
    }

    &-read-more.open {
        &::before {
            transform: rotate(0deg);
        }
    }

    p {
        margin-top: 12px;
        padding-left: 12px;
        color: #fff;
    }

    &-input {
        form {
            margin: 0;
            position: relative;
            width: 56px;
            height: 32px;

            @media (max-width: 992px) {
                scale: .75;
            }

            input {
                position: absolute;
                width: 2px;
                height: 2px;
                top: 4px;
                left: 4px;
                z-index: 0;

                &:checked+label {
                    background-color: #007B4E;
                }

                &:checked+label::before {
                    left: 4px;
                }

                &#CybotCookiebotDialogBodyLevelButtonNecessary:checked+label {
                    background-color: #99CAB8;
                    cursor: not-allowed;
                }

                &#CybotCookiebotDialogBodyLevelButtonNecessary:checked+label::before {
                    background-color: #E4E2DB;
                }
            }

            label {
                cursor: pointer;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 56px;
                height: 32px;
                background-color: #99CAB8;
                border-radius: 40px;
                z-index: 1;

                &::before {
                    content: '';
                    display: block;
                    width: 24px;
                    height: 24px;
                    background-color: #fff;
                    border-radius: 40px;
                    position: relative;
                    top: 4px;
                    left: 28px;
                    transition: left 200ms ease-in-out;
                }
            }
        }
    }
}

.c-modal-tabs {
    padding: 0 20px;

    @media (max-width: 576px) {
        padding: 0 14px;
    }
}

#c-modal-footer ul {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 20px;
    padding: 20px;

    li {
        width: calc((33.33%) - (20px / 2));

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-color: #007B4E;
            padding: 12px 0;
            border-radius: 40px;
            font-size: 18px;
            line-height: 28px;
            color: #fff;

            @media (max-width: 1200px) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    @media (max-width: 576px) {
        flex-direction: column;
        gap: 14px;

        li {
            width: 100%;
        }
    }
}

#c-modal-content-consent,
#c-modal-content-about {
    padding: 32px;

    @media (max-width: 576px) {
        padding: 20px;
    }

    p {
        color: #fff;
    }

    .c-modal-content-consent-header {
        margin-bottom: 12px;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.5px;

        @media (max-width: 992px) {
            font-size: 21px;
            line-height: 28px;
        }
    }

    .c-modal-content-consent-message {
        font-size: 21px;
        line-height: 28px;
        letter-spacing: 0.5px;
        padding-left: 12px;
    }

    .c-modal-content-about-message {
        font-size: 21px;
        line-height: 28px;
        letter-spacing: 0.5px;
    }

    .c-modal-content-consent-message,
    .c-modal-content-about-message {
        @media (max-width: 992px) {
            font-size: 18px;
        }
    }
}

.cookie-card * table {
    margin-top: 24px;
    font-size: 18px;
    line-height: 28px;
    color: #fff;

    tr {
        &:not(:last-child) {
            td, th {
                padding-bottom: 12px;
            }
        }

        td:not(:last-child) {
            padding-right: 12px;
        }

        td:nth-child(-n+2) {
            word-break: break-all;
            width: calc(33.33% / 2);

            @media (max-width: 992px) {
                width: calc(20% / 2);
            }
        }

        td:nth-child(3) {
            width: 40%;
        }

        td:nth-child(n+4) {
            width: calc(26% / 2);
            text-align: center;
        }

        th:nth-child(n+4) {
            text-align: center;
        }

        th:nth-child(-n+3) {
            text-align: left;
        }

        td a {
            color: #fff;
        }
    }
}

#c-modal-content-about {
    dt, dd {
        color: #fff;
        font-size: 21px;
        line-height: 28px;

        @media (max-width: 992px) {
            font-size: 18px;
        }
    }
}
