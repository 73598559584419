.career-part {
    .job-listings {
        padding: 100px 0;

        @media (max-width: 768px) {
            padding: 60px 0;
        }

        color: $GK-Dark;

        * {
            color: inherit;
        }

        &__title {
            margin-bottom: 28px;

            @media (max-width: 992px) {
                margin-bottom: 20px;
            }

            h2 {
                font-size: rem(40px);
                line-height: rem(48px);
                font-family: 'freightbig_probold', serif;

                @media (max-width: 992px) {
                    font-size: rem(32px);
                    line-height: rem(36px);
                }
            }
        }

        &__content {
            display: flex;
            flex-wrap: wrap;
            gap: 20px 48px;
            margin-bottom: 40px;

            @media (max-width: 992px) {
                gap: 12px 0px;
                margin-bottom: 0;
            }

            @media (max-width: 768px) {
                margin-bottom: 0px;
            }
        }

        &__item {
            padding: 32px 40px;
            width: calc(33.33% - (64px/2));
            background: $GK-Off-White;

            &:hover {
                color: $GK-Black;
                text-decoration: none;
            }

            @media (max-width: 992px) {
                width: 100%;
                padding: 24px 20px;
            }

            font-family: 'freightsans_prosemibold',
            sans-serif;

            * {
                font-family: inherit;
            }

            p:first-child {
                font-size: rem(16px);
                line-height: rem(20px);
                margin-bottom: 14px;

                span {
                    margin-left: 24px;
                    font-family: 'freightsans_probook', sans-serif;

                    &::before {
                        content: '|';
                        position: relative;
                        left: -12px;
                    }
                }
            }

            p:not(:first-child) {
                font-size: rem(21px);
                line-height: rem(28px);

                @media (max-width: 992px) {
                    font-size: rem(18px);
                    line-height: rem(24px);
                }
            }
        }

        &__button {
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
                font-family: 'freightsans_promedium', sans-serif;
                padding: 12px 38px;
                font-size: rem(18px);
                line-height: rem(28px);
                border: 2px solid $GK-Dark;
                border-radius: 40px;
                transition: background-color 175ms linear, color 175ms ease;

                &:hover {
                    text-decoration: none;
                    color: inherit;
                }
            }

            @media (max-width: 992px) {
                display: none;
            }
        }
    }
}

.page.karriersiden .career-layout .job-listings {
    @media (min-width: 769px) and (max-width: 1200px) {
        padding: 160px 0 100px 0;
    }
}

.page.karriersiden-joblistings .job-listings {
    padding-top: 40px;
    @media (max-width: 992px) {
        padding-top: 20px;
    }

    .dropdown__wrapper {
        margin-bottom: 40px;
    }

    &__content {
        margin-bottom: 0px;
    }
}
