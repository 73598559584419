$GK-Dark: #08402B; // dark-green
$GK-Green: #007B4E;

$GK-Light-Green: #E6F2ED;
$GK-Strek-Green: #99CAB8;
$GK-Light-Blue: #EAF2F7;

$GK-Off-White: #F7F6F4; // GK-industri
$GK-Gray: #909090;
$GK-Light-Gray: #F0EDEB; // GK-jord
$GK-Black: #202020;

$GK-Red: #EE5246;
$GK-Peach: #FCB8A7;

// old colors
$Peach: rgba(251,171,151,0.85);
$Brown: #674838;
$Blue: #004C9D;
$Red: #EE5246;
$Light-Blue: #97C0D6;
$Gray: #E4E2DB;
