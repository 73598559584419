// Figure animation (add this class to a <figure> with a <img> inside it)
.figure--fade-left{
  position: relative;
  top: 0;
  background-color: transparent;
  animation: figure-move-left ease 1.5s;
  animation-fill-mode: forwards;

  img , video , button{
    opacity: 0;
    animation: simple-fade-in 1.2s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
  }
}

@keyframes figure-move-left{
  0%{
    right:-150px;
    background-color: transparent;
  }
  50%{
    background-color: $GK-Off-White;
  }
  100%{
    right:0;
  }
}

@keyframes simple-fade-in{
  from{
    opacity:0
  }
  to{
    opacity:1
  }
}

// Filled fade-in ( add this class to a <figure> with a <img> or <video> inside it )
.figure--filed-fade-in{
  background-color: transparent;
  animation: filled-fade-in 1.2s;
  animation-fill-mode: forwards;

  img , video{
    opacity: 0;
    animation: simple-fade-in 2s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards !important;
  }
}

@keyframes filled-fade-in{
  0%{
    background-color: transparent;
    opacity: 0;
  }
  15%{
    background-color: $GK-Off-White;
  }
  100%{
    opacity: 1;
  }
}

// ---------------------------------

// Text animation (it can be applied to any div)

.text--fade-left{
  position: relative;
  animation: text-fade-left ease 1.5s;
  animation-fill-mode: forwards;
  &.delay-1{
    animation-delay: 0.15s;
  }
}

@keyframes text-fade-left{
  from{
    right:-150px;
    opacity: 0;
  }
  to{
    right:0;
    opacity: 1;
  }
}
// ---------------------------------
