.full-banner{
  background-image: url("./../images/full-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin: -186px -25px 0 -25px;
  padding: 186px 0 0 0;
  height: 757px;

  @media(max-width: 992px){
    height: 500px;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -132px;
    padding-top: 132px;
    padding-left: 30px;
    padding-right: 30px;
  }

  display: flex;
  align-items: center;
  flex-direction: column;

  h1{
    margin-top: 88px;
    text-align: center;
    margin-bottom: 32px;
    color: #FFFFFF;
    font-size: rem(72px);
    line-height: rem(80px);
    letter-spacing: 0.5px;
    font-weight: 600;
    @media(max-width: 992px){
      font-size: rem(38px);
      line-height: rem(48px);
      margin-top: 40px;
    }
  }

  p{
    font-size: rem(21px);
    line-height: rem(32px);
    letter-spacing: 0.5px;
    font-weight: 400;
    color: #FFFFFF;
    max-width: 530px;
    text-align: center;
    @media(max-width: 992px){
      font-size: rem(16px);
      line-height: rem(28px);
    }
  }

  .arrow-down{
    position: absolute;
    bottom: 32px;
  }
}
