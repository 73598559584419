.privatBanner{
  margin-bottom: 150px;
  position: relative;

  @media(max-width: 992px){
    margin-top: 149px;
    /* background-color: $GK-Light-Blue; */
    margin-bottom: 60px;
  }

  &__wrapper{
    /* background-color: $GK-Light-Blue; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 992px){
      flex-direction: column;
      background-color: transparent;
      padding-bottom: 40px;
      align-items: flex-start;
    }

    figure{
      //justify-self: flex-start;
      //margin-right: 68px;
      flex-basis: 33.33333%;
      img{
        max-width: 100%;
      }

      @media(max-width: 992px){
        margin-right: 0;
        margin-top: -89px;
        margin-bottom: 20px;
        flex-basis: 100%;
        width: 100%;
        img{
          width: 100%;
        }
      }
    }
    .privatBanner__wrapper--text{

      display: flex;
      flex-basis: calc(100% - 33.33333%);
      align-items: center;
      margin-left: 68px;

      @media(max-width: 992px){
        margin-left: 0;
        flex-direction: column;
        align-items: flex-start;
      }

      a{
        height: 52px;
        margin: 0 65px;
        min-width: 224px;
        &.btn{
          &.btn--chevron{
            &:after{
              top: 15px;
            }
          }
        }

        @media(max-width: 992px){
          font-size: rem(18px);
          margin: 0;
          min-width: 166px;
          &.btn{
            &.btn--chevron{
              padding: 10px 24px;
              line-height: rem(28px);
              &:after{
                display: none;
              }
            }
          }
        }

      }
      p{
        font-family: 'freightsans_promedium';
        font-size: rem(32px);
        line-height: rem(40px);
        font-weight: 600;
        color: $GK-Dark;

        @media(max-width: 1200px){
          font-size: rem(22px);
          line-height: rem(28px);
        }

        @media(max-width: 992px){
          margin-bottom: 32px;
          font-size: rem(28px);
          line-height: rem(36px);
        }
      }

    }
  }

}

.text-media-banner{

  .privatBanner__wrapper{
    background-color: $GK-Light-Green;
  }

  &--light-blue{
    .privatBanner__wrapper{
      background-color: $GK-Light-Blue;
    }

  }

  &--off-white{
    .privatBanner__wrapper{
      background-color: $GK-Off-White;
    }

  }

  &--light-gray{
    .privatBanner__wrapper{
      background-color: $GK-Light-Gray;
    }

  }

  @media(max-width: 991px){
    background-color: $GK-Light-Green;

    &--light-blue{
      background-color: $GK-Light-Blue;
    }

    &--off-white{
      background-color: $GK-Off-White;
    }

    &--light-gray{
      background-color: $GK-Light-Gray;
    }
  }

}

.text-media-macro a.btn, .text-media-macro a.btn--push, .text-media-macro a.btn--chevron, .text-media-macro a.btn.btn--push.btn--chevron,
.text-media-macro a.btn:hover, .text-media-macro a.btn--push:hover, .text-media-macro a.btn--chevron:hover, .text-media-macro a.btn.btn--push.btn--chevron:hover {
  color: white;
}

.privatBanner, .text-media{
  a{
    color: white;
  }
  a:hover::before, a.underline-animation:hover::before, .article__wrapper .article__content .contentArticle .htmlArea-article-content a:hover::before, .contact-form .contact-form__wrapper .contact-form__form .input-group--gdpr label a:hover::before, .office-info .office-info__wrapper .office-info__text .info-item p a:hover::before, p.underline-animation:hover::before {
    width: 0 !important;
  }
}

.privatBanner{
  @media (min-width: 992px) {
    p {
      margin-bottom: 0 !important;
    }
  }
}
